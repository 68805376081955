import type { ReactNode } from 'react'
import { Inner } from '../atoms'

export type ContactUsProps = {
	title: ReactNode
	content: ReactNode

	button: ReactNode
}
export const ContactUs = (props: ContactUsProps) => {
	const { title, content, button } = props
	return (
		<section className="bg-[url('/contact_us_bg.webp')] bg-center bg-cover">
			<Inner>
				<div className="min-h-[500px] flex flex-col items-center justify-center py-120 md:py-30">
					<h4 className="text-center max-w-[255px] md:max-w-full text-h4 md:text-h3 text-white/60 mb-24">{title}</h4>
					<div className="text-white font-medium text-h4 md:text-[48px] md:leading-tight mb-40 text-center">{content}</div>
					{button}
				</div>
			</Inner>
		</section>
	)
}
