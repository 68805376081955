import type { ReactNode } from 'react'
import { Icon } from '../atoms'
import { Inner } from '../atoms'
import { BlockConnection } from '../atoms'
import { TagList } from '../atoms'
import { cn } from '../utils/cn'

export type QuoteProps = {
	image: ReactNode
	tags?: ReactNode[]
	title: ReactNode
	text: ReactNode
	authorName: ReactNode
	authorRole?: ReactNode
	link?: string
	icon?: ReactNode
	separatorPosition?: 'top' | 'bottom' | false
	context?: 'default' | 'article'
}

export const Quote = ({
	image,
	title,
	text,
	authorName,
	icon,
	link,
	authorRole,
	separatorPosition = 'bottom',
	context = 'default',
	tags,
}: QuoteProps) => {
	return (
		<section
			className={cn('bg-dark-1  relative', {
				'py-80 md:py-120 mb-160': context === 'default',
				'bg-transparent my-60 md:my-80': context === 'article',
			})}
		>
			<Inner
				className={cn('max-w-[1092px]', {
					'max-w-[1092px]': context === 'default',
					'max-w-[1210px]': context === 'article',
				})}
			>
				<div
					className={cn('flex flex-col items-center lg:flex-row gap-24 lg:gap-56', {
						'bg-dark-1 py-60 md:py-80 px-30 sm:px-40 md:px-90 rounded': context === 'article',
					})}
				>
					<div
						className={cn(
							' flex-grow-0 flex-shrink-0 rounded-full overflow-hidden [&>img]:block [&>img]:w-full [&>img]:h-full [&>img]:object-cover',
							{
								'w-[160px] lg:w-[310px] h-[160px] lg:h-[310px]': context === 'default',
								'w-[160px] lg:w-[240px] h-[160px] lg:h-[240px]': context === 'article',
							},
						)}
					>
						{image}
					</div>
					<div className="flex flex-col">
						<blockquote
							className={cn('', {
								'mb-24': context === 'default',
								'mb-14': context === 'article',
							})}
						>
							{tags && tags?.length > 0 ? <TagList tags={tags} mode="dark" className="mt-0" /> : null}
							<h3 className="text-h3 sm:text-h2 text-white mb-24">{title}</h3>
							<div className="text-odstavec-dark">{text}</div>
						</blockquote>
						<footer className="flex items-center gap-24 text-white ">
							{icon && link && (
								<a href={link} target="_blank" rel="noreferrer nofollow noopener">
									{icon}
								</a>
							)}
							<div className="flex flex-col font-semibold">
								<span className="text-2xl">{authorName}</span>
								{authorRole && <cite className="not-italic">{authorRole}</cite>}
							</div>
						</footer>
					</div>
				</div>
			</Inner>
			{context === 'default' && separatorPosition !== false && <BlockConnection position={separatorPosition} fillColor="#1b2128" />}
		</section>
	)
}
