import { CheckboxField, FormLayout, InputField, MultiSelectField, RadioEnumField } from '@app/lib/form'
import { Component, Field, If } from '@contember/interface'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { AlertCircle } from 'lucide-react'

export const RedirectForm = Component(() => (
	<FormLayout>
		<div className="lg:grid lg:grid-cols-2 gap-10">
			<div>
				<InputField field="sourceLink.url" label="Source URL" required />
				<RadioEnumField
					field="type"
					label="Type"
					options={{ temporary: 'Temporary', permanent: 'Permanent' }}
					defaultValue="temporary"
					orientation="horizontal"
				/>
				<If condition="[type = permanent]">
					<div className="bg-red-50 text-red-600 py-1.5 px-3 text-sm mt-2 mb-4 rounded-md flex gap-2 w-fit items-center">
						<AlertCircle size={20} />
						Redirect is permanent. The old URL effectively disappears.<br/> This could be problematic if you ever need to bring the original URL back
					</div>
				</If>
				<CheckboxField field="applyToAll" defaultValue={true} label="Redirect on all language variants" />
				<If condition="[applyToAll = false]">
					<div className="mt-6">
						<MultiSelectField field="locales" label="Language variant">
							<Field field="code" />
						</MultiSelectField>
					</div>
				</If>
			</div>
			<div>
				<SelectLinkField field="targetLink" label="Target URL" localeHidden={true} />
			</div>
		</div>
	</FormLayout>
))
