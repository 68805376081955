import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'
import { uic } from '../utils/uic'

export const Accordion = AccordionPrimitive.Root

export const AccordionItem = uic(AccordionPrimitive.Item, {
	baseClass: 'border-b',
	displayName: 'AccordionItem',
})

export const AccordionHeader = uic(AccordionPrimitive.Header, {
	baseClass: 'flex',
	displayName: 'AccordionHeader',
})

export const AccordionTrigger = uic(AccordionPrimitive.Trigger, {
	baseClass: 'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
	displayName: 'AccordionTrigger',
	afterChildren: <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />,
})

export const AccordionContent = uic(AccordionPrimitive.Content, {
	baseClass: 'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
	displayName: 'AccordionContent',

})

