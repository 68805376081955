import type { ReactNode } from 'react'
import { Headline, Inner } from '../atoms'

export type DockWithImageProps = {
	headline: {
		children: ReactNode
		level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
	}
	content: ReactNode
	buttons: ReactNode[]
	image: ReactNode
}

export const DockWithImage = ({ headline, content, buttons, image }: DockWithImageProps) => {
	return (
		<section className="my-140 md:my-160">
			<Inner>
				<div className="bg-darkBlue rounded-lg overflow-hidden flex flex-col-reverse lg:flex-row">
					<div className="w-full lg:w-1/2 flex-shrink-0 flex-grow-0 flex flex-col">
						<div className="p-32 md:p-48 min-h-1/2 max-w-[545px]">
							<Headline className="text-h3 md:text-h1 text-white" level={headline.level}>
								{headline.children}
							</Headline>
						</div>
						<div className="h-1/2 p-32 md:p-48 bg-dark-1 flex flex-col justify-between">
							<div className="text-odstavec-dark pb-32">{content}</div>
							<div className="flex flex-col sm:flex-row gap-12">{buttons}</div>
						</div>
					</div>
					<div className="w-full lg:w-1/2 flex-shrink-0 flex-grow-0 [&>img]:w-full [&>img]:h-full [&>img]:object-cover">{image}</div>
				</div>
			</Inner>
		</section>
	)
}
