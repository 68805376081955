import { Inner } from '../atoms'
import { PartnersNavigationItem, type PartnersNavigationItemProps } from '../loop'
import { NextLinkComponent } from '../types'

export type PartnersNavigationProps = {
	items: Array<PartnersNavigationItemProps>
	linkComponent: NextLinkComponent
}

export const PartnersNavigation = ({ items, linkComponent }: PartnersNavigationProps) => {
	return (
		<section className="mt-160 mb-160 md:mb-240">
			<Inner>
				<div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32">
					{items.map((item, index) => linkComponent({ href: item.link, children: <PartnersNavigationItem key={index} {...item} /> }))}
				</div>
			</Inner>
		</section>
	)
}
