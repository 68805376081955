import {
	EntityAccessor,
	EntityListAccessor,
	FieldMarker,
	HasManyRelationMarker,
	HasOneRelationMarker,
} from '@contember/interface'

export const entityToJson = (source: EntityAccessor): Record<string, any> => {
	const result: Record<string, any> = {}

	const processFieldMarker = (marker: FieldMarker) => {
		result[marker.fieldName] = source.getField(marker.fieldName).value
	}

	const processHasOneRelationMarker = (marker: HasOneRelationMarker) => {
		if (marker.parameters.field in result) {
			throw new Error(`Duplicate field ${marker.parameters.field}`)
		}
		const subEntity = source.getEntity({ field: marker.parameters })
		const fieldName = marker.parameters.reducedBy && marker.parameters.field === 'locales' ? 'localesByLocale' : marker.parameters.field
		result[fieldName] = entityToJson(subEntity)
	}

	const processHasManyRelationMarker = (marker: HasManyRelationMarker) => {
		if (marker.parameters.field in result) {
			throw new Error(`Duplicate field ${marker.parameters.field}`)
		}
		const list = source.getEntityList(marker.parameters)
		result[marker.parameters.field] = entityListToJson(list)
	}

	for (const [, marker] of source.getMarker().fields.markers) {
		if (marker instanceof FieldMarker) {
			processFieldMarker(marker)
		} else if (marker instanceof HasOneRelationMarker) {
			processHasOneRelationMarker(marker)
		} else {
			processHasManyRelationMarker(marker)
		}
	}

	return result
}

export const entityListToJson = (source: EntityListAccessor) => {
	const result: any[] = []
	for (const entity of source) {
		const entityValue = entityToJson(entity)
		if (entityValue !== null) {
			result.push(entityValue)
		}
	}
	return result
}
