import { NavigationForm } from '@app/components/forms/navigation-form'
import { NavigationNewForm } from '@app/components/forms/navigation-new-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { Slots } from '@app/lib/layout'
import { Divider } from '@app/lib/ui/divider'
import { EntitySubTree } from '@contember/interface'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Navigation settings</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity={'Navigation(unique = One)'} setOnCreate="(unique = One)">
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<NavigationNewForm />
						{/* <NavigationForm /> */}
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
