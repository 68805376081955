import { ImageField, InputField, RadioEnumField, SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasOne, StaticRender } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { PrimaryLocale } from '../primary-locale'
import { ProductPreview } from '../previews/product-preview'
import { blockVariant, itemsSize } from '@app/labels'

export const ProductTilesForm = Component(() => (
	<>
		<BlockHeadline />
		<RadioEnumField field="itemsSize" orientation="horizontal" label="Items size" options={itemsSize} defaultValue="large" />
		<RadioEnumField field="variant" orientation="horizontal" label="Variant" options={blockVariant} defaultValue="dark" />
		<br />
		<SortableMultiSelectField
			field="products"
			label="Products"
			description="If all products/solutions/technologies and extra items are empty, all products will be shown"
			sortableBy="displayOrder"
			connectAt="product"
		>
			<StaticRender>
				<Field field="icon.url" />
			</StaticRender>
			<PrimaryLocale>
				<StaticRender>
					<Field field="leadText" />
				</StaticRender>
				<ProductPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		{/* <br />TODO: when custom tiles are enabled, delete this
		<SortableMultiSelectField field="solutions" label="Solutions" sortableBy="displayOrder" connectAt="solution">
			<PrimaryLocale>
				<SolutionsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<br />
		<SortableMultiSelectField field="technologies" label="Technologies" connectAt="technology" sortableBy="displayOrder">
			<PrimaryLocale>
				<TechnologiesPreview />
			</PrimaryLocale>
			<StaticRender>
				<HasOne field="image">
					<Field field="url" />
					<Field field="alt" />
				</HasOne>
			</StaticRender>
		</SortableMultiSelectField>
		<br />
		Extra custom items without link:
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Extra items">
			<ImageField
				label="Icon"
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<InputField field="title" label="Title" />
			<RichText field="text" label="Text" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater> */}
	</>
))
