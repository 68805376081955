import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

export type NavigationTileProps = {
	index: 1 | 2
	title: ReactNode
	teaser: ReactNode
	buttonLabel: string
	link: string
}

export const NavigationTile = ({ index, title, teaser, buttonLabel }: Omit<NavigationTileProps, 'link'>) => {
	return (
		<article
			className={cn(
				'hover:shadow-wrapper',
				'flex flex-col justify-between items-start min-h-0 md:min-h-[450px] p-20 md:p-48 bg-black relative overflow-hidden',
				'group',
			)}
		>
			<div className="absolute inset-0">
				{index === 1 ? (
					<img src="/product_tile_bg_1.webp" className={'object-cover absolute w-full h-full'} alt="" width="1312" height="900" loading="lazy" />
				) : (
					<img src="/product_tile_bg_2.webp" className={'object-cover absolute w-full h-full'} alt="" width="1312" height="900" loading="lazy" />
				)}
				<span className={cn('absolute inset-0 bg-black bg-opacity-50 opacity-0 transition-all ease-out duration-300', 'group-hover:opacity-40')} />
			</div>
			<header className="relative z-10 mb-80 md:mb-0">
				<h2 className="text-h3 md:text-h2 text-white">{title}</h2>
			</header>
			<footer className="relative z-10">
				<div className="text-odstavec-dark mt-12 max-w-[430px]">{teaser}</div>
				<div className="mt-24">
					<button type="button" className="btn btn--tercial">
						{buttonLabel}
					</button>
				</div>
			</footer>
		</article>
	)
}
