import { InputField, SelectEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { blockConnectAt } from '@app/labels'
import { ImageField } from '../image-field'

export const PeopleListForm = Component(() => (
	<>
		<SelectEnumField field="blockConnectAt" label="Block connected at" options={blockConnectAt} defaultValue="none" />
		<BlockHeadline />
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<ImageField label="Photo" baseField="image"/>
			<InputField field="title" label="Name" />
			<InputField field="text" label="Position" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
