import { FormLayout, InputField, SelectEnumField } from '@app/lib/form'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { Component } from '@contember/interface'
import { socialLinkIcon } from '@app/labels'
import { LocaleSideDimension } from '../locale-side-dimension'

export const SocialLinkForm = Component(() => (
	<FormLayout>
		<SelectEnumField field="icon" label="Icon" options={socialLinkIcon} required />
		<SelectLinkField field="link" label="Link" />
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
		</LocaleSideDimension>
	</FormLayout>
))
