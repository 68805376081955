import { FormLayout, InputField, ImageField, RadioEnumField, TextareaField } from '@app/lib/form'
import { Component, If } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { RichText } from '../rich-text'
import { BlockEditor } from '../block-editor'
import { technologyType } from '@app/labels'
import { SlugField } from '@app/lib/plugins/url'
import { SeoForm } from '@app/lib/plugins/seo'
import { AlertCircle } from 'lucide-react'

export const SolutionForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
		<If condition="[publishedAt = null && archivedAt = null && link.url != null]">
				<div className="bg-blue-50 text-blue-500 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Draft
				</div>
			</If>
			<If condition="[publishedAt != null]">
				<div className="bg-lime-50 text-green-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Published
				</div>
			</If>
			<If condition="[archivedAt != null]">
				<div className="bg-amber-100 text-amber-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Archived
				</div>
			</If>
			<InputField field="publishedAt" label="Published at" />
			<InputField field="archivedAt" label="Archived at" />
		</LocaleSideDimension>
		<RadioEnumField field="type" label="Type" options={technologyType} defaultValue="primary" orientation="horizontal" />
		<div className="grid grid-cols-2 gap-4">
			<ImageField
				label="Gradient Icon"
				baseField="icon"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<ImageField
				label="Tile icon"
				baseField="tileIcon"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
		</div>
		<LocaleSideDimension>
			<TextareaField field="name" label="Name" required />
			<SlugField label="Link" field="link.url" derivedFrom="name" persistedHardPrefix="/" persistedSoftPrefix="services/" />
			<InputField field="leadText" label="Quote" />
			<RichText field="description" label="Description" />
		</LocaleSideDimension>

		<h4 className="text-3xl font-medium pt-16">Detail - block content</h4>
		<LocaleSideDimension>
			<BlockEditor />
			<SeoForm field="seo" />
		</LocaleSideDimension>
	</FormLayout>
))
