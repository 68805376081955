import type { ReactNode } from 'react'
import { Inner } from '../atoms'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader } from '../atoms'
import { CompanyBoardMemberItem, type CompanyBoardMemberItemProps } from '../loop'
import { BlockConnection } from '../atoms'
import { cn } from '../utils/cn'

export type CompanyBoardProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
	}
	members: Array<CompanyBoardMemberItemProps>
	separatorPosition?: 'top' | 'bottom' | false
}

export const CompanyBoard = ({ headline, members, separatorPosition = 'bottom' }: CompanyBoardProps) => {
	return (
		<section className="bg-dark-2 pt-80 pb-120 md:py-120 relative">
			<Inner className="max-w-[1212px]">
				<BlockSectionHeader
					headline={headline.children}
					level={headline.level}
					headlineClassName={cn('text-white', headline.className)}
					wrapperClassName="md:mb-100"
				/>
				<div className="grid justify-center grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-20 gap-y-64">
					{members.map((member, index) => (
						<CompanyBoardMemberItem key={index} {...member} />
					))}
				</div>
				{separatorPosition !== false && <BlockConnection fillColor="#222b36" position={separatorPosition} />}
			</Inner>
		</section>
	)
}
