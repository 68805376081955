import { format, parseISO } from 'date-fns'

export const friendlyDateFormat = (date: string) => {
	const dateObj = parseISO(date)
	return format(dateObj, 'dd.MM.yyyy')
}

export const timeTagFormat = (date: string) => {
	const dateObj = parseISO(date)
	return format(dateObj, 'yyyy-MM-dd')
}
