import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

export type ProductNavigationTileProps = {
	icon: ReactNode
	title: ReactNode
	teaser?: ReactNode
	link: string
}

const WRAPPER_COMMON_CLASSES = 'bg-pozadiBoxu relative py-32 px-42 h-full flex flex-col rounded'
const WRAPPER_BEFORE_LIGHT_CLASSES =
	"before:content-[''] before:absolute before:left-0 before:top-0 before:bottom-0 before:w-10 before:opacity-40 before:bg-gradient-to-b before:from-[#C8EAD3] before:to-cta-default before:transition-all before:duration-300"

export const ProductNavigationTile = ({
	icon,
	title,
	teaser,
	variant,
	mode,
	context,
	link,
	buttonLabel,
}: ProductNavigationTileProps & {
	variant: 'large' | 'small'
	mode?: 'light' | 'dark'
	context: 'default' | 'article'
	buttonLabel: string
}) => {
	return (
		<article
			className={cn(WRAPPER_COMMON_CLASSES, WRAPPER_BEFORE_LIGHT_CLASSES, 'group-hover:before:opacity-70', {
				'flex-row items-center py-24 px-34 gap-20': variant === 'small',
				'bg-dark-2 before:from-cta-default/10': mode === 'dark',
				'px-24': variant === 'small' && context === 'article',
			})}
		>
			<div
				className={cn('', {
					'max-h-[40px] [&_img]:h-[40px] [&_img]:w-fit': variant === 'small',
					'max-h-[70px] [&_img]:h-[70px] mb-32 [&_img]:w-fit': variant === 'large',
				})}
			>
				{icon}
			</div>
			<header className="">
				<h2
					className={cn('text-nadpisy', {
						'text-h4': variant === 'small',
						'text-h3 mb-12': variant === 'large',
						'text-white': mode === 'dark',
					})}
				>
					{title}
				</h2>
			</header>
			{variant === 'large' && (
				<>
					{teaser && (
						<div
							className={cn('text-odstavec mt-12 mb-16', {
								'text-odstavec-dark': mode === 'dark',
							})}
						>
							{teaser}
						</div>
					)}
					{link && (
						<footer className="mt-auto mb-0">
							<button type="button" className="btn btn--tercial group-hover:text-cta-hover group-hover:after:bg-cta-hover">
								{buttonLabel}
							</button>
						</footer>
					)}
				</>
			)}
		</article>
	)
}
