import type { ReactNode } from 'react'
import { TagList } from '../atoms'
import { NextLinkComponent } from '../types'
import { friendlyDateFormat, timeTagFormat } from '../utils/date'

export type BlogItemProps = {
	link: string
	linkComponent: NextLinkComponent
	image: ReactNode
	tags: ReactNode[]
	title: ReactNode
	teaser: ReactNode
	author: string
	pubDate: string
	authorLabel?: string
}

export const BlogItem = ({ image, tags, authorLabel, title, teaser, author, pubDate, linkComponent, link }: BlogItemProps) => {
	return linkComponent({
		href: link,
		children: (
			<article className="group">
				<header>
					<div className="aspect-[1.74/1] bg-[url('/article_placeholder_bg.png')] bg-cover rounded [&_img]:aspect-[1.74/1] overflow-hidden [&_img]:h-full [&_img]:object-cover [&_img]:transition-all [&_img]:duration-300 [&_img]:group-hover:scale-[1.03]">
						{image}
					</div>
					<TagList tags={tags} mode="dark" />
				</header>
				<h4 className="text-h4 group-hover:underline">{title}</h4>
				<div className="text-odstavec mt-12 line-clamp-4 break-words">{teaser}</div>
				<footer className="text-odstavec flex flex-wrap gap-20 border-t-2 border-outlines-light pt-16 mt-16">
					<span>
						{authorLabel && `${authorLabel}: `}
						{author}
					</span>
					{pubDate && <time dateTime={timeTagFormat(pubDate)}>{friendlyDateFormat(pubDate)}</time>}
				</footer>
			</article>
		),
	})
}
