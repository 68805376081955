import type { ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader, Inner } from '../atoms'
import { SchemeInfoItem, type SchemeInfoItemProps } from '../loop'
import { cn } from '../utils/cn'

export type SchemeProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
		button?: ReactNode
	}
	info?: Array<SchemeInfoItemProps>
	scheme: {
		title?: string
		image: ReactNode
	}
}

export const Scheme = ({ headline, info, scheme }: SchemeProps) => {
	return (
		<section className="bg-pozadiBoxu relative pt-120 md:pt-[12rem] pb-60 md:pb-[12rem]">
			<Inner>
				<BlockSectionHeader
					headline={headline.children}
					level={headline.level}
					headlineClassName={cn(headline.className, 'max-w-[800px]')}
					button={headline.button}
				/>
				{info?.length ? (
					<div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32">
						{info.map((item, index) => (
							<SchemeInfoItem key={index} {...item} />
						))}
					</div>
				) : null}
				<div className="mt-60 md:mt-80">
					{scheme?.title && <h6 className="font-semibold">{scheme.title}</h6>}
					<div className="bg-white border border-outlines-light rounded mt-16 p-24 flex justify-center items-center">{scheme.image}</div>
				</div>
			</Inner>
		</section>
	)
}
