import { SortableMultiSelectField } from '@app/lib/form'
import { DefaultRepeater } from '@app/lib/repeater'
import { Component, Field, HasMany, HasOne, StaticRender } from '@contember/interface'
import { CaseStudyPreview } from '../previews/case-study-preview'
import { PrimaryLocale } from '../primary-locale'
import { RichText } from '../rich-text'
import { BlockButton } from './blockButton'
import { BlockHeadline } from './blockHeadline'
import { ImageField } from '../image-field'

export const CaseStudiesAndLogosForm = Component(() => (
	<>
		<BlockHeadline />
		<SortableMultiSelectField field="caseStudies" label="Case studies" connectAt="caseStudy" sortableBy="displayOrder">
			<PrimaryLocale>
				<CaseStudyPreview />
			</PrimaryLocale>

			<StaticRender>
				<PrimaryLocale>
					<Field field="link.url" />
					<Field field="title" />
					<Field field="leadText" />
					<Field field="revenue" />
				</PrimaryLocale>

				<HasOne field="logo">
					<Field field="url" />
					<Field field="alt" />
				</HasOne>
				<HasMany field="tags">
					<PrimaryLocale>
						<Field field="name" />
					</PrimaryLocale>
				</HasMany>
				<HasMany field="products">
					<HasOne field="product">
						<PrimaryLocale>
							<Field field="name" />
						</PrimaryLocale>
					</HasOne>
				</HasMany>
				<HasMany field="solutions">
					<HasOne field="solution">
						<PrimaryLocale>
							<Field field="name" />
						</PrimaryLocale>
					</HasOne>
				</HasMany>
			</StaticRender>
		</SortableMultiSelectField>
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Logos">
			<ImageField label="Logo" baseField="image" />
		</DefaultRepeater>
		<RichText field="primaryText" label="More clients text" />
		<RichText field="secondaryText" label="Text above button" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
