import { FormLayout, InputField, ImageField } from '@app/lib/form'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'

export const ReviewForm = Component(() => (
	<FormLayout>
		<div className="grid grid-cols-2 gap-4">
			<ImageField
				label="Avatar"
				baseField="avatar"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<ImageField
				label="Logo"
				baseField="logo"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
		</div>
		<LocaleSideDimension>
			<InputField field="name" label="Name" required />
			<InputField field="position" label="Position" required />
			<BlockEditorField field="comment.data" referencesField="comment.references" label="Comment" />
		</LocaleSideDimension>
	</FormLayout>
))
