import type { ReactNode } from 'react'

type InNumbersItemProps = {
	icon: ReactNode
	title: ReactNode
	teaser: ReactNode
}

export const InNumbersItem = ({ icon, title, teaser }: InNumbersItemProps) => {
	return (
		<div className="bg-dark-2 p-20 md:p-32 text-left rounded">
			<div className="mb-24 max-h-40 [&_img]:max-h-40 [&_img]:w-fit">{icon}</div>
			<h6 className="text-h3 md:text-h2 leading-[1.1] text-white">{title}</h6>
			<div className="text-p14 md:text-base text-odstavec-dark mt-4">{teaser}</div>
		</div>
	)
}
