import { InputField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'

export const OurTeamsForm = Component(() => (
	<>
		<InputField field="primaryText" label="Total number" />
		<InputField field="secondaryText" label="Subtitle" />
		<RichText field="tertiaryText" label="Description" />
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Teams">
			<InputField field="title" label="Name" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
