import { InputField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { BlockButton } from './blockButton'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { ImageField } from '../image-field'

export const DockWithImageForm = Component(() => (
	<>
		<ImageField label="Image" baseField="image" />
		<InputField field="primaryText" label="Headline" />
		<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
		<HasOne field="secondaryButton">
			<BlockButton />
		</HasOne>
	</>
))
