import {
	DataGrid,
	DataGridColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridTable,
	DataGridTextColumn,
	DataGridTiles,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { ImageField, InputField, TextareaField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@app/lib/ui/dialog'
import { UploadedImageView } from '@app/lib/upload'
import { EntityAccessor, Field, StaticRender, SugarableHasOneRelation, useEntity } from '@contember/interface'
import {
	FileUrlDataExtractorProps,
	GenericFileMetadataExtractorProps,
	ImageFileDataExtractorProps,
} from '@contember/react-uploader'
import { useState } from 'react'
import { UseEntity } from './use-entity'
import { PersistButton } from '@app/lib/binding'

const imageFields: FileUrlDataExtractorProps & GenericFileMetadataExtractorProps & ImageFileDataExtractorProps = {
	urlField: 'url',
	widthField: 'width',
	heightField: 'height',
	fileNameField: 'meta.fileName',
	fileSizeField: 'meta.fileSize',
	fileTypeField: 'meta.fileType',
	lastModifiedField: 'meta.lastModified',
}

type SelectImageProps = {
	baseField: SugarableHasOneRelation | string
}

export const SelectImage = ({ baseField }: SelectImageProps) => {
	const entity = useEntity()

	return (
		<SelectImageInner
			connect={it => {
				entity.connectEntityAtField(baseField, it)
			}}
			closeOnSelect
		/>
	)
}

export const SelectImageInner = ({ connect, closeOnSelect }: { connect: (entity: EntityAccessor) => void; closeOnSelect?: boolean }) => {
	const [open, setOpen] = useState(false)
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button size="sm" variant="outline" onClick={e => e.stopPropagation()}>
					Select image
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[90vw] max-h-[95vh] overflow-auto">
				<DataGrid entities="Image">
					<DataGridToolbar />
					<DataGridLoader>
						<DataGridTiles className="md:grid-cols-[repeat(auto-fill,minmax(10rem,1fr))]">
							<UseEntity
								render={it => (
									<div
										className="relative border rounded shadow hover:shadow-md hover:border-yellow-500"
										onClick={() => {
											it && connect(it)
											closeOnSelect && setOpen(false)
										}}
									>
										<UploadedImageView {...imageFields} edit={<div className={'flex flex-col lg:flex-row gap-4'}>
											<InputField field="author" label="Author" />
											<InputField field="origin" label="Source" />
											<TextareaField field="description" label="Description" />
											<PersistButton />
										</div>} />
										<StaticRender>
											<Field field="author" />
											<Field field="origin" />
											<Field field="description" />
										</StaticRender>
									</div>
								)}
							/>
						</DataGridTiles>
						<DataGridTable>
							<DataGridColumn headerClassName="w-0">
								<UseEntity
									render={it => (
										<Button
											onClick={() => {
												it && connect(it)
												closeOnSelect && setOpen(false)
											}}
										>
											Select
										</Button>
									)}
								/>
							</DataGridColumn>
							<DataGridTextColumn field="url" header="URL">
								<Field field="url" />
								<StaticRender>
									<ImageField {...imageFields} />
									<Field field="author" />
									<Field field="origin" />
									<Field field="description" />
								</StaticRender>
							</DataGridTextColumn>
						</DataGridTable>
					</DataGridLoader>
					<DataGridPagination />
				</DataGrid>
			</DialogContent>
		</Dialog>
	)
}
