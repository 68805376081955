import { InputField } from '@app/lib/form'
import { DefaultRepeater } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'

export const WebinarTeaserForm = Component(() => (
	<>
		<RichText field="primaryText" label="Headline" />

		<DefaultRepeater field="items" sortableBy="displayOrder">
			<InputField field="title" label="Title" />
			<RichText field="text" label="Lead text" />
		</DefaultRepeater>
	</>
))
