import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { RichText } from '../rich-text'
import { BlockButton } from './blockButton'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'

export const ContactBlockForm = Component(() => (
	<>
		<RichText field="primaryText" label="Title" />
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<RichText field="title" label="Title" />
			<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
			<HasOne field="button">
				<BlockButton />
			</HasOne>
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
