import { Component, EntityListSubTree, Field, useEntityListSubTree, useEnvironment, useField } from '@contember/react-binding'
import { LocaleSideDimension } from './locale-side-dimension'
import { Button } from '@app/lib/ui/button'
import { ExternalLinkIcon } from 'lucide-react'

type PageType = 'article' | 'page' | 'product' | 'technology' | 'solution' | 'partner' | 'caseStudy'

type PreviewButtonInnerProps = {
	id: string
	pageType: PageType
	domains: Array<{
		domain: string
		localeCode: string
	}>
}

const PreviewButtonInner = (props: PreviewButtonInnerProps) => {
	const { id, pageType, domains } = props

	const env = useEnvironment()
	const locale = env.getVariable('currentLocaleCode')

	const domain = domains.find(d => d.localeCode === locale)?.domain

	if (!domain) {
		return null
	}

	return (
		<a href={`${domain}/preview/${pageType}/${id}`} target="_blank">
			<Button variant="outline" className="flex gap-2">
				Preview ({locale?.toString()})
				<ExternalLinkIcon className="w-4 h-4" />
			</Button>
		</a>
	)
}

type PreviewButtonProps = {
	pageType: PageType
}
export const PreviewButton = Component<PreviewButtonProps>(
	props => {
		const { pageType } = props
		const domainsLocale = Array.from(useEntityListSubTree('domains')).map(e => {
			const domain = e.getField<string>('domain').value!
			const localeCode = e.getField<string>('locale.code').value!
			return { domain, localeCode }
		})

		const id = useField<string>('id').value!

		return (
			<LocaleSideDimension className="mt-0">
				<PreviewButtonInner id={id} pageType={pageType} domains={domainsLocale} />
			</LocaleSideDimension>
		)
	},
	() => {
		return (
			<>
				<Field field="id" />
				<EntityListSubTree entities="DomainLocale" alias="domains">
					<Field field="domain" />
					<Field field="locale.code" />
				</EntityListSubTree>
			</>
		)
	},
)
