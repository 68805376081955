import { SortableMultiSelectField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { ConfigurationsPreview } from '../previews/configurations-preview'
import { PrimaryLocale } from '../primary-locale'
import { RichText } from '../rich-text'

export const ConfigurationsForm = Component(() => (
	<>
		<BlockHeadline />
		<RichText field="primaryText" label="Description" />
		<SortableMultiSelectField field="configurations" sortableBy="displayOrder" connectAt="configuration" label="Configurations">
			<PrimaryLocale>
				<ConfigurationsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
	</>
))
