import { ContentFragmentType } from '@website/api'
import React, { createElement, Fragment, type ReactNode } from 'react'
import { Headline, TypoWrapper } from '../atoms'
import { CompanyInNumbers, type CompanyInNumbersProps, DiscoverMoreProducts, DiscoverMoreProductsProps, Quote, type QuoteProps } from '../block'
import {
	ArticleSignUpBlock,
	ArticleSignUpBlockProps,
	CrossSellCompany,
	type CrossSellCompanyProps,
	WebinarInfo,
	type WebinarInfoProps,
} from '../componenets'
import { FileDownload, FileDownloadProps } from '../componenets/article/FileDownload'
import { LinkComponent, NextLinkComponent } from '../types'
import { RichTextRenderer } from './renderer'
import { isLeaf, type LeafRenderer, type RichTextElement } from './types'

export const renderLeaf: LeafRenderer = (leaf: any) => {
	let content = leaf.text ? (
		<>
			{leaf.text.split('\n').map((part: string, index: number) => (
				<React.Fragment key={index}>
					{!!index && <br />}
					{part}
				</React.Fragment>
			))}
		</>
	) : undefined

	if (leaf.isBold) {
		content = <strong>{content}</strong>
	}
	if (leaf.isItalic) {
		content = <em>{content}</em>
	}
	if (leaf.isUnderline) {
		content = <u>{content}</u>
	}
	if (leaf.isStrikethrough) {
		content = <s>{content}</s>
	}
	if (leaf.isCode) {
		content = <code>{content}</code>
	}

	return content
}

export type RenderElement = ({
	element,
	renderLeaf,
	linkComponent,
	nextLinkComponent,
	getTranslation,
	references,
}: {
	element: RichTextElement
	renderLeaf: LeafRenderer
	linkComponent: LinkComponent
	nextLinkComponent: NextLinkComponent
	getTranslation: (key: string) => string
	references?: ContentFragmentType['references']
}) => ReactNode

export const renderElement: RenderElement = ({ element, renderLeaf, linkComponent, nextLinkComponent, getTranslation, references }) => {
	if (isLeaf(element)) {
		return <>{renderLeaf(element)}</>
	}

	const children = element.children.map((child, index) => {
		if (isLeaf(child)) {
			return <Fragment key={index}>{renderLeaf(child)}</Fragment>
		}

		return <Fragment key={index}>{renderElement({ element: child, renderLeaf, linkComponent, getTranslation, nextLinkComponent })}</Fragment>
	})

	const reference: ContentFragmentType['references'][number] | undefined = element.referenceId
		? references?.find(ref => ref.id === element.referenceId)
		: undefined
	const { block } = reference ?? {}

	switch (element.type) {
		case 'paragraph':
		case 'p':
			return <p>{children}</p>
		case 'heading': {
			const elementType = `h${element.level ?? 3}`
			return createElement(elementType, {}, children)
		}
		case 'h1':
		case 'h2':
		case 'h3':
		case 'h4':
		case 'h5':
		case 'h6':
			return createElement(element.type, {}, children)
		case 'a':
			return (
				<a href={element.url} target={'_blank'} rel="noreferrer">
					{children}
				</a>
			)
		case 'anchor':
			return <a href={element.href}>{children}</a>
		case 'unorderedList':
			return <ul className="renderUl">{children}</ul>
		case 'orderedList':
			return <ol className="renderOl">{children}</ol>
		case 'listItem':
			return <li>{children}</li>
		case 'image': {
			if (!block) {
				return null
			}

			const image = block.image

			if (!image) {
				return null
			}
			return <img src={image.url} alt={image.alt ?? ''} />
		}
		case 'companyInNumbers': {
			if (!block) {
				return null
			}

			const companyInNumbers: CompanyInNumbersProps = {
				headline: (
					<>
						<Headline level={block.headline?.size ?? 'h2'}>{block.headline?.text}</Headline>
						{block.headline?.logo && <img src={block.headline.logo.url} alt={block.headline.logo.alt ?? 'logo'} />}
					</>
				),
				teaser: (
					<RichTextRenderer
						value={block.primaryText}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
				gradientBg: block.bgType === 'gradient',
				separatorPosition: block.bgType === 'gradient' ? 'top' : 'bottom',
				items: block.items.map(item => ({
					icon: item.image?.url && <img src={item.image.url} loading="eager" />,
					title: (
						<RichTextRenderer
							value={item.title}
							getTranslation={getTranslation}
							nextLinkComponent={nextLinkComponent}
							linkComponent={linkComponent}
						/>
					),
					teaser: (
						<RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={nextLinkComponent} linkComponent={linkComponent} />
					),
				})),
				cta: block.primaryButton?.link && (
					<div className={'relative z-10 mt-40 md:mt-64'}>
						{linkComponent({
							link: block.primaryButton.link,
							children: (
								<RichTextRenderer
									getTranslation={getTranslation}
									value={block.primaryButton.text}
									nextLinkComponent={nextLinkComponent}
									linkComponent={linkComponent}
								/>
							),
						})}
					</div>
				),
			}

			return <CompanyInNumbers {...companyInNumbers} />
		}
		case 'fileDownload': {
			const fileDownload: FileDownloadProps = {
				file: reference?.file,
			}

			return <FileDownload {...fileDownload} />
		}
		case 'webinarTeaser': {
			if (!block) {
				return null
			}
			const webinarTeaser: WebinarInfoProps = {
				title: (
					<RichTextRenderer
						value={block.primaryText}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
				items: block.items.map(item => ({
					title: item.title ?? '',
					description: (
						<RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={nextLinkComponent} linkComponent={linkComponent} />
					),
				})),
			}
			return <WebinarInfo key={block.id} {...webinarTeaser} />
		}
		case 'quote': {
			if (!block) {
				return null
			}

			const quote: QuoteProps = {
				title: (
					<RichTextRenderer
						value={block.primaryText}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
				image: block.image?.url && <img src={block.image.url} alt={block.image.alt ?? 'image'} />,
				authorName: (
					<RichTextRenderer
						value={block.secondaryText}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
				authorRole: (
					<RichTextRenderer
						value={block.tertiaryText}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
				text: block.content && (
					<RichTextRenderer
						value={block.content.data}
						getTranslation={getTranslation}
						nextLinkComponent={nextLinkComponent}
						linkComponent={linkComponent}
					/>
				),
			}

			return <Quote {...quote} />
		}
		case 'articleSignUp': {
			if (!block) {
				return null
			}

			const articleSignUp: ArticleSignUpBlockProps = {
				title: block.primaryText ?? '',
				type: block.signUpArticleType ?? 'download',
				getTranslation: getTranslation,
			}
			return <ArticleSignUpBlock {...articleSignUp} />
		}
		/* is not relevant outside article
		case 'crossSellCategory': {
			if (!block) {
				return null
			}

			const crossSellCategory: CrossSellCategoryProps = {
				linkComponent: nextLinkComponent,
				title: block.primaryText ?? '',
				cta: {
					label: 'TODO: V contemberu',
					link: 'TODO: V contemberu',
				},
			}
			return <CrossSellCategory {...crossSellCategory} />
		}
		*/
		case 'crossSellCompany': {
			if (!block) {
				return null
			}

			const crossSellCompany: CrossSellCompanyProps = {
				linkComponent: linkComponent,
				title: block.primaryText ?? '',
				cta: [
					...(block.primaryButton
						? [
								{
									label: block.primaryButton.text,
									link: block.primaryButton.link ?? undefined,
								},
							]
						: []),

					...(block.secondaryButton
						? [
								{
									label: block.secondaryButton.text,
									link: block.secondaryButton.link ?? undefined,
								},
							]
						: []),
				],
			}

			return <CrossSellCompany {...crossSellCompany} />
		}
		case 'productTiles': {
			if (!block) {
				return null
			}

			const productTiles: DiscoverMoreProductsProps = {
				headline: {
					children: (
						<>
							<Headline level={block.headline?.size ?? 'h2'}>{block.headline?.text}</Headline>
						</>
					),
				},
				seeMoreButtonLabel: getTranslation('button.seeMore'),
				products: block.products.map(({ product }) => ({
					title: product?.localesByLocale?.name,
					icon: product?.icon && <img src={product.icon.url} alt={product.icon.alt ?? 'icon'} />,
					teaser: product?.localesByLocale?.leadText,
					link: product?.localesByLocale?.link?.url ?? '#',
				})),
				linkComponent: nextLinkComponent,
			}

			return <DiscoverMoreProducts {...productTiles} />
		}

		case 'customTiles': {
			if (!block) {
				return null
			}

			const customTiles: DiscoverMoreProductsProps = {
				headline: {
					children: (
						<>
							{block.headline?.text && (
								<Headline level={block.headline?.size ?? 'h2'}>
									<RichTextRenderer
										value={block.headline?.text}
										linkComponent={linkComponent}
										nextLinkComponent={nextLinkComponent}
										getTranslation={getTranslation}
									/>
								</Headline>
							)}
						</>
					),
				},
				seeMoreButtonLabel: getTranslation('button.seeMore'),
				products:
					block.customizedList?.items
						.sort((a, b) => a.displayOrder - b.displayOrder)
						.map(item => {
							if ('product' in item && item.product) {
								return {
									link: item.product.pageDisabled ? '' : item.product.localesByLocale?.link?.url ?? '',
									icon:
										block.variant === 'dark' ? (
											item.product.icon?.url ? (
												<img src={item.product.icon?.url} alt={item.product.icon?.alt ?? 'product'} />
											) : (
												item.product.tileIcon?.url && <img src={item.product.tileIcon?.url} alt={item.product.tileIcon?.alt ?? 'product'} />
											)
										) : item.product.tileIcon?.url ? (
											<img src={item.product.tileIcon?.url} alt={item.product.tileIcon?.alt ?? 'product'} />
										) : item.product.icon?.url ? (
											<img src={item.product.icon?.url} alt={item.product.icon?.alt ?? 'product'} />
										) : null,
									title: item.product.localesByLocale?.name,
									teaser: (
										<RichTextRenderer
											value={item.product.localesByLocale?.leadText}
											getTranslation={getTranslation}
											nextLinkComponent={nextLinkComponent}
											linkComponent={linkComponent}
										/>
									),
								}
							}
							if ('solution' in item && item.solution) {
								return {
									link: item.solution.pageDisabled ? '' : item.solution.localesByLocale?.link?.url ?? '',
									icon:
										block.variant === 'dark' ? (
											item.solution.icon?.url ? (
												<img src={item.solution.icon?.url} alt={item.solution.icon?.alt ?? 'solution'} />
											) : (
												item.solution.tileIcon?.url && <img src={item.solution.tileIcon?.url} alt={item.solution.tileIcon?.alt ?? 'solution'} />
											)
										) : item.solution.tileIcon?.url ? (
											<img src={item.solution.tileIcon?.url} alt={item.solution.tileIcon?.alt ?? 'solution'} />
										) : item.solution.icon?.url ? (
											<img src={item.solution.icon?.url} alt={item.solution.icon?.alt ?? 'solution'} />
										) : null,
									title: item.solution.localesByLocale?.name,
									teaser: item.solution.localesByLocale?.leadText,
								}
							}
							if ('technology' in item && item.technology) {
								return {
									link: item.technology.pageDisabled ? '' : item.technology.localesByLocale?.link?.url ?? '',
									icon: item.technology.image?.url ? <img src={item.technology.image?.url} alt={item.technology.image?.alt ?? 'technology'} /> : null,
									title: item.technology.localesByLocale?.title,
									teaser: item.technology.localesByLocale?.leadText,
								}
							}
							if ('item' in item && item.item) {
								return {
									link: item.item.link?.externalTarget ?? item.item.link?.internalTarget?.url ?? '',
									icon: item.item.image?.url ? <img src={item.item.image.url} alt={item.item.image.alt ?? 'item'} /> : null,
									title: item.item.title,
									teaser: (
										<RichTextRenderer
											value={item.item.text}
											getTranslation={getTranslation}
											nextLinkComponent={nextLinkComponent}
											linkComponent={linkComponent}
										/>
									),
								}
							}
							return null
						})
						.filter((item): item is NonNullable<typeof item> => item !== null) ?? [],
				linkComponent: nextLinkComponent,
			}

			return <DiscoverMoreProducts {...customTiles} />
		}

		default:
			return <div>{children}</div>
	}
}
