import { FormLayout, InputField, ImageField, RadioEnumField, MultiSelectField, CheckboxField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'
import { LocaleSideDimension } from '../locale-side-dimension'
import { ContentEditor } from '../content-editor'
import { technologyType } from '@app/labels'
import { TagsPreview } from '../previews/tags-preview'
import { PrimaryLocale } from '../primary-locale'
import { TagForm } from './tag-form'

export const TechnologyForm = Component(() => (
	<FormLayout>
		<CheckboxField field="pageDisabled" label="Technology does not have a detail page" defaultValue={false} />
		<ImageField
			label="Logo"
			baseField="image"
			urlField="url"
			widthField="width"
			heightField="height"
			fileNameField="meta.fileName"
			fileTypeField="meta.fileType"
			fileSizeField="meta.fileSize"
			lastModifiedField="meta.lastModified"
		/>
		<RadioEnumField field="type" label="Type" options={technologyType} defaultValue="primary" orientation="horizontal" />
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
			<SlugField label="Link" field="link.url" derivedFrom="title" persistedHardPrefix="/" persistedSoftPrefix="services/" />
			<RichText field="description" label="Description (visible on tile)" />
		</LocaleSideDimension>
		<MultiSelectField field="tags" label="Tags" createNewForm={<TagForm />}>
			<PrimaryLocale>
				<TagsPreview />
			</PrimaryLocale>
		</MultiSelectField>
		<RadioEnumField field="type" label="Type" options={technologyType} defaultValue="primary" orientation="horizontal" />
		<LocaleSideDimension>
			<RichText field="leadText" label="Quote" />
			<ContentEditor
				label="Content"
				field="content.data"
				referencesField="content.references"
				enableArticleLocking={true}
				enableFileDownload={false}
			/>
			<SeoForm field="seo" />
		</LocaleSideDimension>
	</FormLayout>
))
