import type { ReactNode } from 'react'
import { InfoIcon } from '../atoms/InfoIcon'

export type SchemeInfoItemProps = {
	title: string
	content: ReactNode
}

export const SchemeInfoItem = ({ title, content }: SchemeInfoItemProps) => {
	return (
		<article className="bg-white border border-outlines-light rounded p-24">
			<h4 className="flex items-center gap-16 text-h4 mb-12">
				<InfoIcon />
				{title}
			</h4>
			<div className="text-odstavec">{content}</div>
		</article>
	)
}
