import { cn } from '../utils/cn'

type BlockConnectionProps = {
	fillColor: string
	position?: 'top' | 'bottom'
}

export const BlockConnection = ({ fillColor, position = 'top' }: BlockConnectionProps) => {
	return (
		<span
			className={cn('absolute -top-37 left-0 z-10 h-38 w-full overflow-hidden', {
				'top-auto -bottom-37': position === 'bottom',
			})}
		>
			<span
				className={cn('w-1/3 absolute top-0 h-38 left-0', {
					'left-auto right-0': position === 'bottom',
				})}
				style={{ backgroundColor: fillColor }}
			/>
			<svg
				width="1439"
				height="38"
				viewBox="0 0 1439 38"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={cn('absolute left-1/2 -translate-x-1/2 z-[2]', {
					'transform rotate-180': position === 'bottom',
				})}
			>
				<path
					d="M737.426 33H1439V37.5H-1V0H679.574C687.53 0 695.161 3.1607 700.787 8.78679L716.213 24.2132C721.839 29.8393 729.47 33 737.426 33Z"
					fill={fillColor}
				/>
			</svg>
			<span
				className={cn('w-1/3 absolute top-0 h-38 right-0 border-b-5', {
					'right-auto left-0 border-b-0 border-t-5': position === 'bottom',
				})}
				style={{ borderColor: fillColor }}
			/>
		</span>
	)
}
