import { SelectEnumField, SortableMultiSelectField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { RichText } from '../rich-text'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { blockConnectAt } from '@app/labels'
import { TagsPreview } from '../previews/tags-preview'
import { PrimaryLocale } from '../primary-locale'
import { BlockButton } from './blockButton'
import { TagForm } from '../forms/tag-form'
import { ImageField } from '../image-field'

export const QuoteForm = Component(() => (
	<>
		<SelectEnumField field="blockConnectAt" label="Block connected at" options={blockConnectAt} />
		<ImageField label="Author photo" baseField="image" />
		<SortableMultiSelectField field="tags" label="Tags" connectAt="tag" sortableBy="displayOrder" createNewForm={<TagForm />}>
			<PrimaryLocale>
				<TagsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<RichText field="primaryText" label="Headline" />
		<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
		<RichText field="secondaryText" label="Author" />
		<RichText field="tertiaryText" label="Position" />
		<h3>Link + icon</h3>
		<ImageField label="Icon" baseField="icon" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
