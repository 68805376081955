import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

export type ClientLogoBarProps = {
	logos: ReactNode[]
	text?: ReactNode
}

export const ClientLogoBar = ({ logos, text }: ClientLogoBarProps) => {
	return (
		<div className="border border-outlines-light py-20 md:py-40 px-20 md:px-60 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-30 md:gap-x-80 gap-y-20 md:gap-y-40 rounded">
			{logos.map((logo, index) => (
				<div key={index} className={cn('flex [&_img]:max-h-[70px] w-auto items-center justify-center [&_img]:flex-[0]', {})}>
					{logo}
				</div>
			))}
			{text && <div className="text-odstavec-default text-[20px] font-[600] w-full text-center flex justify-center items-center">{text}</div>}
		</div>
	)
}
