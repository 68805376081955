import { RadioEnumField, SortableMultiSelectField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { PrimaryLocale } from '../primary-locale'
import { itemsSize } from '@app/labels'
import { SolutionsPreview } from '../previews/solutions-preview'

export const SolutionTilesForm = Component(() => (
	<>
		<BlockHeadline />
		<RadioEnumField
			field="itemsSize"
			orientation="horizontal"
			label="Items size"
			options={itemsSize}
			defaultValue="large"
		/>
		<SortableMultiSelectField
			field="solutions"
			label="Solutions"
			description="If empty all solutions will be shown"
			sortableBy="displayOrder"
			connectAt="solution"
		>
			<PrimaryLocale>
				<SolutionsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
	</>
))
