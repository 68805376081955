import { SignUpArticleReferenceType } from '@app/labels'
import { dict } from '@app/lib/dict'
import { CheckboxField, FileField as DefaultFileField, InputField, RadioEnumField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { UploaderDropzoneAreaUI } from '@app/lib/upload'
import { Component, HasOne } from '@contember/interface'
import { UploadIcon } from 'lucide-react'
import { SelectFile } from '../select-file'

type FileFieldProps = {
	baseField: string
	label?: string
}

export const FileField = Component(({ baseField, label }: FileFieldProps) => (
	<DefaultFileField
		baseField={baseField}
		label={label}
		urlField="url"
		fileNameField="meta.fileName"
		fileTypeField="meta.fileType"
		fileSizeField="meta.fileSize"
		lastModifiedField="meta.lastModified"
		dropzonePlaceholder={
			<UploaderDropzoneAreaUI className="w-60">
				<UploadIcon className={'w-12 h-12 text-gray-400'} />
				<div className={'font-semibold text-sm'}>{dict.uploader.dropFiles}</div>
				<div className={'text-xs'}>{dict.uploader.or}</div>
				<div className={'flex gap-2 items-center text-xs'}>
					<Button size={'sm'} variant={'outline'}>
						{dict.uploader.browseFiles}
					</Button>
					<div onClick={e => e.stopPropagation()}>
						<SelectFile baseField={baseField} />
					</div>
				</div>
			</UploaderDropzoneAreaUI>
		}
	/>
))

export const ArticleSignUp = Component(() => (
	<>
		<InputField field="primaryText" label="Title" required />
		<RadioEnumField field="signUpArticleType" label="Type" orientation="horizontal" options={SignUpArticleReferenceType} required />
		<FileField baseField="file" />
		<InputField field="secondaryText" label="ID of the Ecomail list" />
		<CheckboxField field="gatedContent.useName" label="Use individual name" description="Add a mandatory field for user to type in own name"/>
		<CheckboxField field="gatedContent.useCompanyName" label="Use company name" description="Add a non-mandatory field for user to type in company name" />
	</>
))
