import { SideDimensions } from '@app/lib/dimensions'
import { cn } from '@app/lib/utils'
import { Component, type Environment } from '@contember/interface'
import type React from 'react'
import { type ReactNode, useEffect } from 'react'

type LocaleSideDimensionProps = {
	className?: string
	children: React.ReactNode
	minWidth?: boolean | string
}

export const LocaleSideDimension = Component<LocaleSideDimensionProps>(
	({ children, className, minWidth }) => {
		useEffect(() => {
			const items = Array.from(document.querySelectorAll('.locale-side-dimension'))
			for (const item of items) {
				for (const label of Array.from(item.querySelectorAll('label'))) {
					label.setAttribute('data-locale', item.getAttribute('data-locale-code') ?? '')
				}
			}
		}, [])

		return (
			<SideDimensions className={className} dimension="locale" as="currentLocaleCode" field="locales(locale.code=$currentLocaleCode)">
				{minWidth ? (
					<div className={cn(typeof minWidth === 'string' ? minWidth : minWidth ? 'min-w-[750px]' : null)}>
						<InnerLocaleSideDimension>{children}</InnerLocaleSideDimension>
					</div>
				) : (
					<InnerLocaleSideDimension>{children}</InnerLocaleSideDimension>
				)}
			</SideDimensions>
		)
	},
	({ children }) => (
		<SideDimensions dimension="locale" as="currentLocaleCode" field="locales(locale.code=$currentLocaleCode)">
			{children}
		</SideDimensions>
	),
)

type InnerLocaleSideDimensionProps = {
	children: React.ReactNode
}

const InnerLocaleSideDimension = Component<InnerLocaleSideDimensionProps>(({ children }, environment) => {
	const value = environment.getVariableOrElse<undefined, ReactNode & Environment.Value>('currentLocaleCode', undefined) ?? ''

	return (
		<div className="locale-side-dimension" data-locale-code={value}>
			{children}
		</div>
	)
})
