import type { ReactNode } from 'react'
import { Inner } from '../atoms'
import { BlockConnection } from '../atoms'
import { Icon } from '../atoms'
import { cn } from '../utils/cn'

export type OurTeamsProps = {
	number: ReactNode
	title: ReactNode
	teaser: ReactNode
	teams: Array<string>
}

export const OurTeams = ({ number, title, teaser, teams }: OurTeamsProps) => {
	return (
		<section className="bg-dark-1 pt-80 md:pt-120 lg:pt-300 pb-120 relative">
			<BlockConnection fillColor="#1b2128" />
			<Inner className="relative">
				<div className="flex flex-col justify-center items-center">
					<div className="mb-40 text-[109px] lg:text-[378px] font-bold leading-none text-transparent bg-clip-text bg-gradient-to-b from-cta-default/100 to-cta-default/10">
						{number}
					</div>
					<div className="text-h1 text-cta-default mb-64 lg:mb-40">{title}</div>
					<div className="max-w-full overflow-auto lg:overflow-visible">
						<div className="flex justify-center w-[900px] flex-wrap gap-12 lg:block lg:w-auto">
							{teams.length && teams.map((team, index) => <Team key={index} name={team} index={index} />)}
						</div>
					</div>
					<div className="max-w-[685px] text-h3 text-center text-white mt-32 lg:mt-0">{teaser}</div>
				</div>
			</Inner>
		</section>
	)
}

type TeamProps = {
	name: string
	index: number
}

const Team = ({ name, index }: TeamProps) => {
	return (
		<div
			className={cn(
				'flex whitespace-nowrap gap-20 items-center static lg:absolute z-10 bg-dark-2 rounded p-16 text-odstavec-dark font-semibold',
				{
					'left-[3.5%] top-[-80px]': index === 0,
					'left-[5.7%] top-[100px]': index === 1,
					'left-[17%] top-[195px]': index === 2,
					'left-[26.8%] top-[-130px]': index === 3,
					'left-[50.5%] top-[-30px]': index === 4,
					'right-[26.5%] top-[-140px]': index === 5,
					'right-[11.6%] top-[30px]': index === 6,
					'right-[11.6%] top-[250px]': index === 7,
					'right-[3.8%] top-[-45px]': index === 8,
					'right-[3.2%] top-[160px]': index === 9,
					'right-[5%] top-[200px]': index === 10,
				},
			)}
		>
			<Icon name="team" />
			{name}
		</div>
	)
}
