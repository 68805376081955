import { Component, HasOne } from '@contember/interface'
import type React from 'react'
import { LocaleBadge } from './atoms/locale-badge'

type PrimaryLocaleProps = {
	children: React.ReactNode
}

export const PrimaryLocale = Component<PrimaryLocaleProps>(({ children }, env) => {
	const locales = env.getDimensionOrElse('locale', [])

	if (locales.length === 0) {
		return null
	}

	return (
		<HasOne field={`locales(locale.code=${locales[0]})`}>
			<div className="flex items-center gap-2">
				<div>{children}</div>
				<LocaleBadge>{locales[0]}</LocaleBadge>
			</div>
		</HasOne>
	)
})
