import type { ReactNode } from 'react'
import { Icon } from '../atoms'
import { Inner } from '../atoms'
import { cn } from '../utils/cn'

export type ServiceIncludesProps = {
	bgColor?: 'white' | 'green'
	title: ReactNode
	leadText?: ReactNode
	items: Array<{
		title: ReactNode
		content: ReactNode
	}>
}

export const ServiceIncludes = ({ title, leadText, items, bgColor = 'white' }: ServiceIncludesProps) => {
	return (
		<section
			className={cn('', {
				'bg-pozadiBoxu py-150 md:py-160': bgColor === 'green',
				'my-150 md:my-160': bgColor === 'white',
			})}
		>
			<Inner>
				<div className="rounded-lg bg-dark-2 px-20 pt-80 pb-16 md:p-48">
					<Icon name="target" />
					<h2 className="text-h2 md:text-h1 text-white mt-24 md:mt-32">{title}</h2>
					{leadText && (
						<div className="max-w-[655px] mt-24 md:mt-32 pt-24 md:pt-32 border-t-2 border-outlines-dark/10 text-odstavec-dark">{leadText}</div>
					)}

					<ol className="bg-dark-1 p-16 pb-32 md:p-48 mt-80 [counter-reset:counter]">
						{items.map((item, index) => (
							<li key={index} className="py-32 first:pt-0 last:pb-0 border-t-2 first:border-t-0 border-outlines-dark/10 [counter-increment:counter]">
								<article className="flex flex-col lg:flex-row justify-between relative gap-[5rem]">
									<div className="text-[64px] mt-[1.2rem] text-cta-default min-w-[90px]">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
									<header className="flex-grow-0 flex-shrink-0 w-full md:w-1/2 lg:w-1/3 xl:w-[425px] pr-30 pt-24 lg:pt-0 ml-0 mr-auto mb-8 md:mb-0">
										<h4 className="text-h4 md:text-h3 text-white">{item.title}</h4>
									</header>
									<div className="text-odstavec-dark leading-22 w-full md:w-1/2 lg:w-[34%] flex-grow-0 flex-shrink-0">{item.content}</div>
								</article>
							</li>
						))}
					</ol>
				</div>
			</Inner>
		</section>
	)
}
