import type { ReactNode } from 'react'

export type ConfigurationItemProps = {
	icon: ReactNode
	title: ReactNode
	teaser: ReactNode
	content: ReactNode
	price: ReactNode
	cta: ReactNode
}

export const ConfigurationItem = ({ icon, title, teaser, content, price, cta }: ConfigurationItemProps) => {
	return (
		<article className="bg-dark-2 rounded flex flex-col justify-between overflow-hidden">
			<div className="p-24 sm:p-32">
				<div className="max-h-64 [&>img]:h-64 mb-64">{icon}</div>
				<h3 className="text-h4 sm:text-h3 md:text-h4 xl:text-h3 text-white mb-32">{title}</h3>
				<div className="text-odstavec-dark">{teaser}</div>
				<hr className="hr my-32" />
				<div className="text-odstavec-dark typo">
					{content}
				</div>
			</div>
			<footer className="p-24 sm:p-32 bg-dark-3 flex flex-col gap-24 xl:gap-33">
				<div className="text-h4 sm:text-h3 md:text-h4 xl:text-h3 text-white">{price}</div>
				{cta}
			</footer>
		</article>
	)
}
