import { blockHeadlineLevel, blockHeadlineSize } from '@app/labels'
import { SelectEnumField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { ImageField } from '../image-field'
import { RichText } from '../rich-text'
import { BlockButton } from './blockButton'

type BlockHeadlineProps = {
	headline?: string
	canHaveLogo?: boolean
	canHaveButton?: boolean
}

export const BlockHeadline = Component(({ headline, canHaveLogo, canHaveButton }: BlockHeadlineProps) => (
	<>
		<div className="border rounded border-gray-100 p-4 mb-4">
			<HasOne field="headline">
				<h4 className="mb-4 font-medium">{headline ?? 'Headline'}</h4>
				<div>
					{canHaveLogo && (
						<ImageField label="Logo in headline" baseField="logo" />
					)}
					<RichText field="text" label="Text" />
					<div className="grid grid-cols-2 gap-10">
						<SelectEnumField field="size" label="Size" options={blockHeadlineSize} />
						<SelectEnumField field="level" label="Headline level" options={blockHeadlineLevel} />
					</div>
					{canHaveButton && (
						<HasOne field="button">
							<BlockButton />
						</HasOne>
					)}
				</div>
			</HasOne>
		</div>
	</>
))
