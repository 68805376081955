import type { ReactNode } from 'react'

export type HeadlineLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export const Headline = ({
	children,
	className,
	level = 'h2',
}: { level?: HeadlineLevel; children: ReactNode; className?: string }) => {
	switch (level) {
		case 'h1':
			return <h1 className={className}>{children}</h1>
		case 'h2':
			return <h2 className={className}>{children}</h2>
		case 'h3':
			return <h3 className={className}>{children}</h3>
		case 'h4':
			return <h4 className={className}>{children}</h4>
		case 'h5':
			return <h5 className={className}>{children}</h5>
		case 'h6':
			return <h6 className={className}>{children}</h6>
	}
}
