import type { ReactNode } from 'react'
import { Inner } from '../atoms'
import { BlockConnection } from '../atoms'
import { ProductAdvantageItem, type ProductAdvantageItemProps } from '../loop'
import { BlockSectionHeader } from '../atoms'
import clsx from 'clsx'
import { cn } from '../utils/cn'

export type ProductAdvantagesProps = {
	headline: {
		children: ReactNode
		level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
		className?: string
	}
	advantages: Array<ProductAdvantageItemProps>
	mode?: 'light' | 'dark'
}

export const ProductAdvantages = ({ headline, mode = 'light', advantages }: ProductAdvantagesProps) => {
	return (
		<section
			className={cn([''], {
				'py-80 md:py-[11rem] bg-dark-1 relative': mode === 'dark',
				'mt-80 mb-160 md:my-[12rem]': mode === 'light',
			})}
		>
			<Inner>
				<BlockSectionHeader
					headline={headline.children}
					level={headline.level}
					wrapperClassName="mb-40 md:mb-80"
					headlineClassName={cn(['text-h1 max-w-[950px]', headline.className], {
						'text-h2 md:text-h1 text-white': mode === 'dark',
						'text-h2 md:text-h1 text-nadpisy': mode === 'light',
					})}
				/>
				<div
					className={clsx('grid grid-cols-1 md:grid-cols-3 gap-32 rounded py-48 md:py-0', {
						'bg-pozadiBoxu': mode === 'light',
						'bg-dark-2': mode === 'dark',
					})}
				>
					{advantages.map((advantage, index) => (
						<ProductAdvantageItem key={index} {...advantage} mode={mode} />
					))}
				</div>
			</Inner>
			{mode === 'dark' && <BlockConnection fillColor="#1b2128" />}
		</section>
	)
}
