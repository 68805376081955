import { LinkFragmentType } from '@website/api'
import { Inner } from '../../atoms'
import { LinkComponent, NextLinkComponent } from '../../types'
import { cn } from '../../utils/cn'

export type CrossSellCompanyProps = {
	title: string
	linkComponent: LinkComponent
	cta: Array<{
		label: string
		link: LinkFragmentType | undefined
	}>
}

export const CrossSellCompany = ({ title, cta, linkComponent }: CrossSellCompanyProps) => {
	return (
		<section className="my-60 md:my-80">
			<Inner>
				<div className="py-35 px-20 md:px-40 rounded flex gap-24 flex-col items-center justify-center bg-[url('/contact_us_stripe_bg.webp')] bg-cover bg-no-repeat">
					<h6 className="text-h4 md:text-h3 text-white text-center">{title}</h6>
					<ul className="flex flex-col sm:flex-row items-center gap-12">
						{cta.map(
							(ctaItem, index) =>
								ctaItem.label && (
									<li key={index}>
										{linkComponent({
											link: ctaItem.link,
											children: ctaItem.label,
											className: cn('', {
												'btn btn--primary': index === 0,
												'btn btn--primary-outline-dark': index === 1,
											}),
										})}
									</li>
								),
						)}
					</ul>
				</div>
			</Inner>
		</section>
	)
}
