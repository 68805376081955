import { DataGridLocaleTextColumn } from '@app/components/atoms/data-grid-locale-text-column'
import { CopyEntity, CopyPopover } from '@app/components/copier'
import { DeleteEntityButton, ToggleButton } from '@app/components/deleteEntityButton'
import { PageForm } from '@app/components/forms/page-form'
import { PreviewButton } from '@app/components/previewButton'
import { pageType } from '@app/labels'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridEnumColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { AnchorButton, Button } from '@app/lib/ui/button'
import { EntitySubTree, Link, RedirectOnPersist } from '@contember/interface'
import { TrashIcon } from 'lucide-react'
import { useState } from 'react'

export const List = () => {
	return (
		<>
			<Binding>
				<Slots.Title>Page list</Slots.Title>
				<Slots.Back>
					<BackButton />
				</Slots.Back>
				<>
					<Slots.Actions>
						<Link to="page/create">
							<Button>Create page</Button>
						</Link>
					</Slots.Actions>
					<DataGrid entities="Page">
						<DataGridToolbar>
							<DataGridQueryFilter />
						</DataGridToolbar>
						<DataGridLoader>
							<DataGridTable>
								<DataGridColumn>
									<div className="flex gap-4">
										<Link to="page/edit(id: $entity.id)">
											<Button variant="outline">Edit</Button>
										</Link>
									</div>
								</DataGridColumn>
								<DataGridLocaleTextColumn field="title" header="Title" />
								<DataGridLocaleTextColumn field="link.url" header="URL" />
								<DataGridEnumColumn field="type" header="Type" options={pageType} />
								<DataGridColumn>
									<div className="flex justify-end items-center gap-4">
										<DeleteEntityDialog
											trigger={
												<Button variant="destructive" size="sm">
													<TrashIcon className="w-3 h-3" />
												</Button>
											}
										/>
									</div>
								</DataGridColumn>
							</DataGridTable>
						</DataGridLoader>
						<DataGridPagination />
					</DataGrid>
				</>
			</Binding>
		</>
	)
}

export const Create = () => {
	return (
		<>
			<Binding>
				<Slots.Title>Page create</Slots.Title>
				<Slots.Back>
					<BackButton />
				</Slots.Back>
				<EntitySubTree entity="Page" isCreating alias="target">
					<RedirectOnPersist to="page/edit(id: $entity.id)" />
					<Slots.Actions>
						<CopyPopover />
						<PersistButton />
					</Slots.Actions>
					<PageForm />
				</EntitySubTree>
				<CopyEntity entityName="Page">
					<PageForm />
				</CopyEntity>
			</Binding>
		</>
	)
}

export const Edit = () => {
	const [showDeleteButton, setShowDeleteButton] = useState(false)

	return (
		<>
			<Binding>
				<Slots.Title>Page edit</Slots.Title>
				<Slots.Back>
					<BackButton />
				</Slots.Back>
				<EntitySubTree entity="Page(id=$id)" isCreating={false}>
					<Slots.Actions>
						<div className="flex items-start gap-4">
							<CopyPopover>
								<Link to="page/create(copy: $entity.id)">
									<AnchorButton variant="secondary">Duplicate</AnchorButton>
								</Link>
							</CopyPopover>
							<PersistButton />
							<div className="flex flex-col items-end">
								<PreviewButton pageType="page" />
								<ToggleButton isOpen={showDeleteButton} onClick={() => setShowDeleteButton(!showDeleteButton)} />
								{showDeleteButton && <DeleteEntityButton />}
							</div>
						</div>
					</Slots.Actions>
					<PageForm />
				</EntitySubTree>
			</Binding>
		</>
	)
}
