import { EntityAccessor, FieldMarker, HasManyRelationMarker, HasOneRelationMarker, PRIMARY_KEY_NAME } from '@contember/interface'

export class EntityCopier {
	constructor(private handlers: CopyHandler[] = []) {
	}

	copy(source: EntityAccessor, target: EntityAccessor) {
		for (const handler of this.handlers) {
			if (handler.copy?.({ copier: this, source, target })) {
				return
			}
		}
		for (const [, marker] of source.getMarker().fields.markers) {
			if (marker instanceof FieldMarker) {
				this.copyColumn(source, target, marker)
			} else if (marker instanceof HasOneRelationMarker) {
				if (marker.parameters.field === 'embed') {
					// debugger
				}
				this.copyHasOneRelation(source, target, marker)
			} else if (marker instanceof HasManyRelationMarker) {
				this.copyHasManyRelation(source, target, marker)
			}
		}
	}

	public copyHasOneRelation(source: EntityAccessor, target: EntityAccessor, marker: HasOneRelationMarker) {
		for (const handler of this.handlers) {
			if (handler.copyHasOneRelation?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const subEntity = source.getEntity({ field: marker.parameters })
		if (marker.parameters.expectedMutation === 'connectOrDisconnect') {
			if (!subEntity.existsOnServer && subEntity.hasUnpersistedChanges) {
				throw 'cannot copy'
			}
			target.connectEntityAtField(marker.parameters.field, subEntity)
		} else if (
			marker.parameters.expectedMutation === 'anyMutation' ||
			marker.parameters.expectedMutation === 'createOrDelete'
		) {
			if (!subEntity.existsOnServer && !subEntity.hasUnpersistedChanges) {
				return
			}
			const subTarget = target.getEntity({ field: marker.parameters })
			this.copy(subEntity, subTarget)
		}
	}

	public copyHasManyRelation(source: EntityAccessor, target: EntityAccessor, marker: HasManyRelationMarker) {
		for (const handler of this.handlers) {
			if (handler.copyHasManyRelation?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const list = source.getEntityList(marker.parameters)
		const targetList = target.getEntityList(marker.parameters)
		if (marker.parameters.expectedMutation === 'connectOrDisconnect') {
			for (const subEntity of list) {
				if (!subEntity.existsOnServer && subEntity.hasUnpersistedChanges) {
					throw 'cannot copy'
				}
				targetList.connectEntity(subEntity)
			}
		} else if (
			marker.parameters.expectedMutation === 'anyMutation' ||
			marker.parameters.expectedMutation === 'createOrDelete'
		) {
			targetList.disconnectAll()
			for (const subEntity of list) {
				targetList.createNewEntity(target => {
					this.copy(subEntity, target())
				})
			}
		}
	}

	public copyColumn(source: EntityAccessor, target: EntityAccessor, marker: FieldMarker) {
		if (marker.fieldName === PRIMARY_KEY_NAME) {
			return
		}
		for (const handler of this.handlers) {
			if (handler.copyColumn?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const sourceValue = source.getField(marker.fieldName).value
		target.getField(marker.fieldName).updateValue(sourceValue)
	}
}


export interface CopyEntityArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
}

export interface CopyHasOneRelationArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: HasOneRelationMarker
}

export interface CopyHasManyRelationArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: HasManyRelationMarker
}

export interface CopyColumnArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: FieldMarker
}

export interface CopyHandler {
	copy?(args: CopyEntityArgs): boolean

	copyHasOneRelation?(args: CopyHasOneRelationArgs): boolean

	copyHasManyRelation?(args: CopyHasManyRelationArgs): boolean

	copyColumn?(args: CopyColumnArgs): boolean
}
