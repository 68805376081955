import { ArticlesPreview } from '@app/components/previews/articles-preview'
import { PrimaryLocale } from '@app/components/primary-locale'
import { Binding } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridDateColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Article unlocks list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<DataGrid entities="ArticleUnlock">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridDateColumn field="createdAt" header="Submitted at" />
									<DataGridTextColumn header="Email" field="email" />
									<DataGridHasOneColumn header="Article" field="article">
										<PrimaryLocale>
											<ArticlesPreview />
										</PrimaryLocale>
									</DataGridHasOneColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}
