import { RedirectForm } from '@app/components/forms/redirect-form'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridHasManyColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { RedirectOnPersist, Link } from '@contember/interface'
import { EntitySubTree, Field } from '@contember/react-binding'
import { TrashIcon } from 'lucide-react'

export const List = () => {
	return (
		<Binding>
			<div className="flex flex-col gap-12">
				<Slots.Title>Redirects</Slots.Title>
				<Slots.Back>
					<BackButton />
				</Slots.Back>
				<Binding>
					<Slots.Actions>
						<Link to="redirect/create">
							<Button>Create redirect</Button>
						</Link>
					</Slots.Actions>
					<DataGrid entities="Redirect">
						<DataGridToolbar>
							<DataGridQueryFilter />
						</DataGridToolbar>
						<DataGridLoader>
							<DataGridTable>
								<DataGridColumn>
									<div className="flex gap-4">
										<Link to="redirect/edit(id: $entity.id)">
											<Button variant="outline">Edit</Button>
										</Link>
									</div>
								</DataGridColumn>
								<DataGridHasOneColumn field="sourceLink" header="Source">
									<Field field="url" />
								</DataGridHasOneColumn>
								<DataGridHasOneColumn field="targetLink" header="Target">
									<Field field="externalTarget" />
									<Field field="internalTarget.url" />
								</DataGridHasOneColumn>
								<DataGridHasManyColumn field="locales" header="Languages">
									<Field field="code" />
								</DataGridHasManyColumn>
								<DataGridColumn>
									<div className="flex justify-end items-center gap-4">
										<DeleteEntityDialog
											trigger={
												<Button variant="destructive" size="sm">
													<TrashIcon className="w-3 h-3" />
												</Button>
											}
										/>
									</div>
								</DataGridColumn>
							</DataGridTable>
						</DataGridLoader>
						<DataGridPagination />
					</DataGrid>
				</Binding>
			</div>
		</Binding>
	)
}

export const Create = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Redirect create</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Redirect" isCreating>
						<RedirectOnPersist to="redirect/edit(id: $entity.id)" />
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<RedirectForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}

export const Edit = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Redirect edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Redirect(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<RedirectForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
