import {
	DataGrid,
	DataGridColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridTable,
	DataGridTextColumn,
	DataGridTiles,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { FileField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@app/lib/ui/dialog'
import { UploadedAnyView } from '@app/lib/upload'
import { EntityAccessor, Field, StaticRender, SugarableHasOneRelation, useEntity } from '@contember/interface'
import { FileUrlDataExtractorProps, GenericFileMetadataExtractorProps } from '@contember/react-uploader'
import { useState } from 'react'
import { UseEntity } from './use-entity'

const fileFields: FileUrlDataExtractorProps & GenericFileMetadataExtractorProps = {
	urlField: 'url',
	fileNameField: 'meta.fileName',
	fileSizeField: 'meta.fileSize',
	fileTypeField: 'meta.fileType',
	lastModifiedField: 'meta.lastModified',
}

type SelectFileProps = {
	baseField: SugarableHasOneRelation | string
}

export const SelectFile = ({ baseField }: SelectFileProps) => {
	const entity = useEntity()

	return (
		<SelectFileInner
			connect={it => {
				entity.connectEntityAtField(baseField, it)
			}}
			closeOnSelect
		/>
	)
}

export const SelectFileInner = ({ connect, closeOnSelect }: { connect: (entity: EntityAccessor) => void; closeOnSelect?: boolean }) => {
	const [open, setOpen] = useState(false)
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button size="sm" variant="outline" onClick={e => e.stopPropagation()}>
					Select file
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[90vw] max-h-[95vh] overflow-auto">
				<DataGrid entities="File">
					<DataGridToolbar />
					<DataGridLoader>
						<DataGridTiles className="md:grid-cols-[repeat(auto-fill,minmax(10rem,1fr))]">
							<UseEntity
								render={it => (
									<div
										className="relative border rounded shadow hover:shadow-md hover:border-yellow-500"
										onClick={() => {
											it && connect(it)
											closeOnSelect && setOpen(false)
										}}
									>
										<UploadedAnyView {...fileFields} />
									</div>
								)}
							/>
						</DataGridTiles>
						<DataGridTable>
							<DataGridColumn headerClassName="w-0">
								<UseEntity
									render={it => (
										<Button
											onClick={() => {
												it && connect(it)
												closeOnSelect && setOpen(false)
											}}
										>
											Select
										</Button>
									)}
								/>
							</DataGridColumn>
							<DataGridTextColumn field="url" header="URL">
								<Field field="url" />
								<StaticRender>
									<FileField {...fileFields} />
								</StaticRender>
							</DataGridTextColumn>
						</DataGridTable>
					</DataGridLoader>
					<DataGridPagination />
				</DataGrid>
			</DialogContent>
		</Dialog>
	)
}
