import { Component, EntitySubTree, useEntity, useEnvironment, useGetEntitySubTree } from '@contember/interface'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useReferentiallyStableCallback } from '@contember/react-utils'
import { CopyHandler as EntityCopierHandler, CopyHasOneRelationArgs, EntityCopier } from '../utils/entity-copier'
import { ContentCopyHandler } from '../utils/content-copy-handler'
import { Button } from '@app/lib/ui/button'
import { SelectInput } from '@app/lib/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@app/lib/ui/popover'
import { CopyIcon } from 'lucide-react'

export const CopyPopover = ({ children }: { children?: ReactNode }) => {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button variant='secondary'>
					<CopyIcon size={16} />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='space-y-4'>
				<Copier dimension="locale" />
				{children}
			</PopoverContent>
		</Popover>
	)
}
export const Copier = ({ dimension, copyHandlers = [] }: {
	copyHandlers?: EntityCopierHandler[]
	dimension: string
}) => {
	const [sourceLocale, setSourceLocale] = useState<string | null>(null)
	const [targetLocale, setTargetLocale] = useState<string>('*')

	const entity = useEntity()
	const locales = useEnvironment().getDimension(dimension)

	const doCopy = useReferentiallyStableCallback(() => {
		if (!sourceLocale || targetLocale === sourceLocale) {
			return
		}

		const targetLocales = targetLocale === '*' ? locales.filter(it => it !== sourceLocale) : [targetLocale]
		const sourceEntity = entity.getEntity(`locales(locale.code = "${sourceLocale}")`)

		for (const locale of targetLocales) {
			const targetEntity = entity.getEntity(`locales(locale.code = "${locale}")`)
			const copier = new EntityCopier([
				new ContentCopyHandler('Content', 'data', 'references'),
				{
					copyHasOneRelation(args: CopyHasOneRelationArgs): boolean {
						return (args.marker.parameters.field === 'url' && args.source.name === 'PageLocale')
							|| args.marker.parameters.field === 'locale'
					},
				},
				...copyHandlers,
			])
			copier.copy(sourceEntity, targetEntity)
		}
	})

	return <>
		<div className="flex justify-center items-center gap-1">
			<h4 className="mb-2">From:</h4>
			<div>
				<SelectInput onChange={e => setSourceLocale(e.target.value || null)}>
					<option value="">Select language</option>
					{locales.map(it => <option key={it} value={it}>{it}</option>)}
				</SelectInput>
			</div>
			<h4 className="mb-2">To:</h4>
			<div>
				<SelectInput onChange={e => setTargetLocale(e.target.value)}>
					{['*', ...locales].map(it => <option key={it} value={it}>{it}</option>)}
				</SelectInput>
			</div>
			<Button onClick={() => doCopy()}>
				Copy
			</Button>
		</div>
	</>
}


export const CopyEntity = Component<{ entityName: string, children: ReactNode }>(({ entityName }, env) => {
	const id = env.getParameterOrElse('copy', null)
	const getSubTree = useGetEntitySubTree()
	const isFirstRender = useRef(true)
	useEffect(() => {
		if (!id || !isFirstRender.current) {
			return
		}
		isFirstRender.current = false
		const source = getSubTree({ entity: { entityName, where: { id } } })
		const target = getSubTree({ entity: entityName, isCreating: true })
		const copier = new EntityCopier([
			new ContentCopyHandler('Content', 'data', 'references'),
			{
				copyHasOneRelation(args: CopyHasOneRelationArgs): boolean {
					const subEntity = args.source.getEntity({ field: args.marker.parameters })
					return (args.marker.parameters.field === 'link' && subEntity.name === 'Url')
				},
			},
		])
		copier.copy(source, target)
	}, [id, getSubTree, entityName]);
	return null
}, ({ entityName, children }, env) => {
	const id = env.getParameterOrElse('copy', null)
	if (!id) {
		return null
	}
	return (
		<EntitySubTree entity={{
			entityName,
			where: { id }
		}}>
			{children}
		</EntitySubTree>
	)
})
