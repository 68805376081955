import type { ReactNode } from 'react'
import { BlockConnection } from '../atoms'
import { BlockSectionHeader } from '../atoms'
import { ClientLogoBar } from '../atoms'
import { Inner } from '../atoms'
import type { HeadlineLevel } from '../atoms'
import { cn } from '../utils/cn'

export type OurClientsProps = {
	separatorPosition?: 'top' | 'bottom' | false
	bgColor?: 'white' | 'green'
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
		wrapperClassName?: string
	}
	logos: ReactNode[]
	footer?: {
		title?: ReactNode
		cta?: ReactNode
	}
	text?: ReactNode
}

export const OurClients = ({ bgColor, headline, logos, footer, separatorPosition = 'top', text }: OurClientsProps) => {
	return (
		<section
			className={cn('relative', {
				'bg-pozadiBoxu py-120': bgColor === 'green',
				'my-160': bgColor === 'white',
			})}
		>
			{separatorPosition !== false && bgColor === 'green' && <BlockConnection position={separatorPosition} fillColor="#F2F7F7" />}
			<Inner>
				<BlockSectionHeader
					wrapperClassName={cn('mb-40 md:mb-40', headline.wrapperClassName)}
					headlineClassName={cn(['text-h3 md:text-h3 max-w-none text-center', headline.className])}
					headline={headline.children}
					level={headline.level}
				/>

				<ClientLogoBar logos={logos} text={text} />
				{footer && (
					<div className="text-center">
						{footer?.title && <h6 className="text-h4 text-center mt-32 mb-24">{footer.title}</h6>}
						{footer.cta}
					</div>
				)}
			</Inner>
		</section>
	)
}
