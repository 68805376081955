import type { ReactNode } from "react";
import { BlockConnection, BlockSectionHeader, Inner } from "../atoms";
import {
  ProductNavigationTile,
  type ProductNavigationTileProps,
} from "../loop";
import { NextLinkComponent } from "../types";
import { cn } from "../utils/cn";

export type DiscoverMoreProductsProps = {
  headline: {
    children: ReactNode;
    level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    className?: string;
    wrapperClassName?: string;
  };
  products: Array<ProductNavigationTileProps>;
  variant?: "large" | "small";
  mode?: "light" | "dark";
  context?: "default" | "article";
  seeMoreButtonLabel: string;
  linkComponent: NextLinkComponent;
};

export const DiscoverMoreProducts = ({
  headline,
  mode = "light",
  variant = "large",
  products,
  context = "default",
  seeMoreButtonLabel,
  linkComponent: LinkComponent,
}: DiscoverMoreProductsProps) => {
  return (
    <section
      className={cn([""], {
        "bg-dark-1 relative": mode === "dark",
        "py-80 md:py-120 ": context === "default" && mode === "dark",
        "mt-80 mb-160 md:my-160": context === "default" && mode === "light",
        "my-80 md:my-80": context === "article" && mode === "light",
        "my-80 md:my-80 py-80": context === "article" && mode === "dark",
      })}
    >
      <Inner
        className={cn("", {
          "max-w-[1210px]": context === "article",
        })}
      >
        <BlockSectionHeader
          wrapperClassName={cn(
            "",
            {
              "mb-40 md:mb-80": context === "default",
              "mb-48": context === "article",
            },
            headline.wrapperClassName
          )}
          headlineClassName={cn(
            ["text-h2 md:text-h1"],
            {
              "text-h2 md:text-h1 text-white": mode === "dark",
              "text-h2 md:text-h1 text-nadpisy": mode === "light",
              "text-h3 md:text-h2 text-center max-w-none":
                context === "article",
            },
            headline.className
          )}
          headline={headline.children}
          level={headline.level}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32">
          {products.map((product, index) =>
            product.link ? (
              <LinkComponent href={product.link} key={index}>
                <ProductNavigationTile
                  {...product}
                  variant={variant}
                  mode={mode}
                  context={context}
                  buttonLabel={seeMoreButtonLabel}
                />
              </LinkComponent>
            ) : (
              <ProductNavigationTile
                key={index}
                {...product}
                variant={variant}
                mode={mode}
                context={context}
                buttonLabel={seeMoreButtonLabel}
              />
            )
          )}
        </div>
      </Inner>
      {mode === "dark" && <BlockConnection fillColor="#1b2128" />}
    </section>
  );
};
