import { InputField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'

export const NetworkAnimationForm = Component(() => (
	<>
		<BlockHeadline />
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Items">
			<InputField field="title" label="Number" />
			<InputField field="text" label="Suffix" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
		<RichText field="primaryText" label="Lead text" />
	</>
))
