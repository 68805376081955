import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { Card, CardContent } from '@app/lib/ui/card'

export interface HeaderSettingsFormProps {
	over?: string & {}
}

export const HeaderSettingsForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField field="supportLabel" label="Support label" required />
			<InputField field="supportNumber" label="Support number" required />
			<br />
			<Card>
				<CardContent>
					<InputField field="loginLabel" label="Login label" />
					<SelectLinkField field="loginLink" label="Login link" />
				</CardContent>
			</Card>
		</LocaleSideDimension>
	</FormLayout>
))
