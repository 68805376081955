import { Binding, IdentityLoader } from '@app/lib/binding'
import { DimensionsSwitcher } from '@app/lib/dimensions'
import { LayoutComponent, Slots } from '@app/lib/layout'
import { Divider } from '@app/lib/ui/divider'
import { Component, Field, Link } from '@contember/interface'
import type { PropsWithChildren } from 'react'
import { Logo } from './logo'
import { Navigation } from './navigation'

export const Layout = Component(({ children }: PropsWithChildren) => (
	<IdentityLoader>
		<LayoutComponent>
			<Slots.Logo>
				<Link to="index">
					<Logo />
				</Link>
			</Slots.Logo>
			<Slots.Navigation>
				<Binding>
					<DimensionsSwitcher options="Locale" slugField="code" dimension="locale" isMulti={true}>
						<Field field="label" />
					</DimensionsSwitcher>
				</Binding>
				<Divider className="my-4" />
				<Navigation />
			</Slots.Navigation>
			<div className="flex flex-col gap-12 overflow-y-auto">{children}</div>
			<Slots.Footer>
				<p>
					<small>
						Created with
						<a className="content-link" href="https://www.contember.com/">
							AI-assisted Contember Studio
						</a>
					</small>
				</p>
			</Slots.Footer>
		</LayoutComponent>
	</IdentityLoader>
))
