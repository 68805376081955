import type { ReactNode } from 'react'
import { BlockSectionHeader, Inner } from '../atoms'
import { TechnologyItem, type TechnologyItemProps } from '../loop'
import { NextLinkComponent } from '../types'
import { cn } from '../utils/cn'

export type TechnologiesProps = {
	headline: {
		children: ReactNode
		level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
		className?: string
	}
	technologies: Array<TechnologyItemProps>
	variant?: 'default' | 'small'
	linkComponent: NextLinkComponent
}

export const Technologies = ({ headline, technologies, linkComponent, variant = 'default' }: TechnologiesProps) => {
	return (
		<section className="bg-pozadiBoxu py-120 md:py-120">
			<Inner>
				<BlockSectionHeader
					headline={headline.children}
					headlineClassName={cn('text-h3 md:text-h2 max-w-none', headline.className)}
					wrapperClassName={cn('', {
						'mb-32 md:mb-32': variant === 'small',
					})}
					level={headline.level}
				/>
				<div
					className={cn('grid  gap-24 md:gap-32', {
						'grid-cols-1 md:grid-cols-3': variant === 'default',
						'grid-cols-2 sm:grid-cols-4 lg:grid-cols-6': variant === 'small',
					})}
				>
					{technologies.map((technology, index) =>
						technology.link ? (
							linkComponent({ href: technology.link, children: <TechnologyItem key={index} variant={variant} {...technology} /> })
						) : (
							<TechnologyItem key={index} variant={variant} {...technology} />
						),
					)}
				</div>
			</Inner>
		</section>
	)
}
