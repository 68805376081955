import type { ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader, Inner } from '../atoms'
import { ProductAdvantageItem, type ProductAdvantageItemProps } from '../loop'
import { cn } from '../utils/cn'
import React from 'react'

export type AdvantagesListProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
	}
	teaser: ReactNode
	items: Array<{
		headline: ReactNode
		advantages: Array<ProductAdvantageItemProps & { content?: ReactNode }>
	}>
}

export const AdvantagesList = ({ headline, teaser, items }: AdvantagesListProps) => {
	return (
		<section className="mb-80 mt-160 md:my-240">
			<Inner>
				<BlockSectionHeader headline={headline.children} level={headline.level} headlineClassName={cn('max-w-[770px]', headline.className)} />
				{teaser && <div className="text-odstavec max-w-[770px] mt-40">{teaser}</div>}
				<ol className="[counter-reset:counter]">
					{items.map((item, index) => (
						<li key={index} className="mt-60 mb:mt-80 [counter-increment:counter]">
							<h2 className="flex flex-col lg:flex-row items-start lg:items-center gap-24 lg:gap-32 text-h4 md:text-h3 mb-24 md:mb-48 before:content-[counter(counter,decimal-leading-zero)] before:text-h1 lg:before:text-[96px] before:leading-none before:text-cta-default before:font-medium max-w-[700px] leading-[1.3]">
								{item.headline}
							</h2>
							{item.advantages.some(it => !!it?.content) ? (
								<div className="grid grid-cols-1 md:grid-cols-3 rounded bg-pozadiBoxu py-32 md:py-0">
									{item.advantages.map((advantage, index) => (
										<>
											<ProductAdvantageItem key={index} {...advantage} mode="light" className="[&>article]:before:hidden" />
											<div
												key={`advantage_content_${index}`}
												className="col-span-2 text-odstavec flex justify-end flex-col pt-32 pb-0 md:pb-46 px-32 mb:pl-16 mb:pr-0"
											>
												{advantage.content}
											</div>
										</>
									))}
								</div>
							) : (
								<div
									className={cn('grid gap-32 rounded bg-pozadiBoxu py-32 md:py-0', {
										'w-full md:w-1/3 grid-cols-1': item.advantages.length === 1,
										'w-full md:w-2/3 grid-cols-1 md:grid-cols-2': item.advantages.length === 2,
										'grid-cols-1 md:grid-cols-3': item.advantages.length > 2,
									})}
								>
									{item.advantages.map((advantage, index) => (
										<ProductAdvantageItem key={index} {...advantage} mode="light" />
									))}
								</div>
							)}
						</li>
					))}
				</ol>
			</Inner>
		</section>
	)
}
