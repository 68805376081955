'use client'
import type React from 'react'
import { useRef, useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { gsap } from 'gsap'

interface AnimatedNumberProps {
	finalValue: number
	duration?: number
}

export const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ finalValue, duration = 2 }) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1,
	})

	const numberRef = useRef<HTMLSpanElement>(null)
	const [number, setNumber] = useState(0)

	useEffect(() => {
		if (inView) {
			gsap.to(numberRef.current, {
				innerHTML: finalValue,
				duration: duration,
				ease: 'power1.out',
				snap: { innerHTML: 1 },
				onUpdate: () => {
					setNumber(Number(numberRef.current?.innerHTML))
				},
			})
		}
	}, [inView, finalValue, duration])

	return (
		<div ref={ref} className="inline-flex">
			<span ref={numberRef}>{number}</span>
		</div>
	)
}
