import type { PropsWithChildren, ReactNode } from 'react'
import { Icon, Inner } from '../../atoms'

export type ArticleSignUpBlockProps = {
	type: 'download' | 'webinar'
	title: string
	metaInfo?: ReactNode
	getTranslation: (key: string) => string
}

//TODO: implement sign up and send file after sign up
export const ArticleSignUpBlock = ({ children, type, title, metaInfo, getTranslation }: PropsWithChildren<ArticleSignUpBlockProps>) => {
	return (
		<section className="my-60 md:my-80">
			<Inner className="max-w-[980px]">
				<div className="bg-pozadiBoxu py-40 md:py-80 px-20 md:px-40 rounded">
					<div className="max-w-[590px] mx-auto">
						<h6 className="text-h4 text-odstavec mb-16">
							{type === 'download' ? getTranslation('articleSignUp.download.title') : getTranslation('articleSignUp.webinar.title')}
						</h6>
						<div className="border-2 border-outlines-light rounded p-24 sm:p-30 flex items-center gap-15 sm:gap-30">
							{type === 'download' ? <Icon name="signUpDownload" /> : <Icon name="signUpWebinar" />}
							<div>
								<h3 className="text-h4 sm:text-h3">{title}</h3>
								{metaInfo && <div className="text-odstavec">{metaInfo}</div>}
							</div>
						</div>
						{children}
					</div>
				</div>
			</Inner>
		</section>
	)
}
