import { LocaleForm } from '@app/components/forms/locale-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { Slots } from '@app/lib/layout'
import { DefaultRepeater } from '@app/lib/repeater'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Locales</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<Slots.Actions>
						<PersistButton />
					</Slots.Actions>
					<DefaultRepeater entities="Locale" orderBy="code">
						<LocaleForm />
					</DefaultRepeater>
				</div>
			</Binding>
		</>
	)
}
