import type { ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader, ClientLogoBar, type ClientLogoBarProps, Inner } from '../atoms'
import { CaseStudy, type CaseStudyProps } from '../loop'
import { cn } from '../utils/cn'
import { NextLinkComponent } from '../types'

export type TestimonialsWithCaseStudiesProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
	}
	text?: ReactNode
	caseStudies: Array<Omit<CaseStudyProps, 'usedProducts' | 'usedSolutions'>>
	logoBar?: ClientLogoBarProps
	footer?: {
		title?: ReactNode
		cta?: ReactNode
	}
	usedProducts: string
	usedSolutions: string
	linkComponent: NextLinkComponent
}

export const TestimonialsWithCaseStudies = ({
	headline,
	caseStudies,
	text,
	logoBar,
	footer,
	linkComponent,
	usedProducts,
	usedSolutions,
}: TestimonialsWithCaseStudiesProps) => {
	return (
		<section className="mt-120 md:mt-160 mb-160 md:mb-240">
			<Inner>
				<BlockSectionHeader
					headline={headline.children}
					level={headline.level}
					headlineClassName={cn('md:mx-auto text-center md:mx-0 md:text-left', headline.className)}
				/>
				<div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32 mb-24 md:mb-32">
					{caseStudies.map((caseStudy, index) =>
						linkComponent({
							href: caseStudy.link,
							children: <CaseStudy key={index} {...caseStudy} usedProducts={usedProducts} usedSolutions={usedSolutions} />,
						}),
					)}
				</div>
				{logoBar && <ClientLogoBar logos={logoBar.logos} text={text} />}
				{footer && (
					<div className="text-center">
						<div className="text-h4 text-center mt-32 mb-24">{footer.title}</div>
						{footer?.cta}
					</div>
				)}
			</Inner>
		</section>
	)
}
