import { itemsSize } from '@app/labels'
import { RadioEnumField, SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasOne, StaticRender } from '@contember/interface'
import { TechnologiesPreview } from '../previews/technologies-preview'
import { PrimaryLocale } from '../primary-locale'
import { BlockHeadline } from './blockHeadline'

export const TechnologyTilesForm = Component(() => (
	<>
		<BlockHeadline />
		<RadioEnumField field="itemsSize" orientation="horizontal" label="Items size" options={itemsSize} defaultValue="large" />
		<SortableMultiSelectField
			field="technologies"
			label="Technologies"
			connectAt="technology"
			description="If empty all technologies will be shown"
			sortableBy="displayOrder"
		>
			<PrimaryLocale>
				<TechnologiesPreview />
			</PrimaryLocale>
			<StaticRender>
				<HasOne field="image">
					<Field field="url" />
					<Field field="alt" />
				</HasOne>
			</StaticRender>
		</SortableMultiSelectField>
	</>
))
