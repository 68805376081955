import { DeleteEntityDialog } from "@app/lib/binding";
import { DataGrid, DataGridToolbar, DataGridQueryFilter, DataGridLoader, DataGridTable, DataGridColumn, DataGridHasManyColumn, DataGridPagination } from "@app/lib/datagrid";
import { Button } from "@app/lib/ui/button";
import { Component, Link } from "@contember/interface";
import { TrashIcon } from "lucide-react";
import { DataGridLocaleTextColumn } from "./atoms/data-grid-locale-text-column";
import { TagsPreview } from "./previews/tags-preview";
import { PrimaryLocale } from "./primary-locale";

export const ArticleDataGrid = Component((_props, env) => {
    const locales = env.getDimensionOrElse('locale', [])
    const conditions = locales.length === 0 ? '' : `[${locales.map(locale => `locales.locale.code='${locale}'`).join('||')}]`

    return (
        <DataGrid entities={`Article${conditions}`} initialSorting={{ publishedAt: 'desc' }}>
            <DataGridToolbar>
                <DataGridQueryFilter />
            </DataGridToolbar>
            <DataGridLoader>
                <DataGridTable>
                    <DataGridColumn>
                        <div className="flex gap-4">
                            <Link to="article/edit(id: $entity.id)">
                                <Button variant="outline">Edit</Button>
                            </Link>
                        </div>
                    </DataGridColumn>
                    <DataGridLocaleTextColumn field="title" header="Title" />
                    <DataGridHasManyColumn field="tags" header="Tags">
                        <PrimaryLocale>
                            <TagsPreview />
                        </PrimaryLocale>
                    </DataGridHasManyColumn>
                    <DataGridColumn>
                        <div className="flex justify-end items-center gap-4">
                            <DeleteEntityDialog
                                trigger={
                                    <Button variant="destructive" size="sm">
                                        <TrashIcon className="w-3 h-3" />
                                    </Button>
                                }
                            />
                        </div>
                    </DataGridColumn>
                </DataGridTable>
            </DataGridLoader>
            <DataGridPagination />
        </DataGrid>
    )
})