import type { ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader, Inner } from '../atoms'
import { ConfigurationItem, type ConfigurationItemProps } from '../loop'
import { cn } from '../utils/cn'

export type ConfigurationOverviewProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
	}
	description?: ReactNode
	configurations: Array<ConfigurationItemProps>
}

export const ConfigurationOverview = ({ headline, configurations, description }: ConfigurationOverviewProps) => {
	return (
		<section className="bg-dark-1 py-80 md:py-[20rem]">
			<Inner>
				<BlockSectionHeader
					headline={headline.children}
					headlineClassName={cn('mx-auto text-center md:mx-0 md:text-left text-white', headline.className)}
					level={headline.level}
				/>
				<div className="text-white text-md pb-40 lg:max-w-[83%]">{description}</div>
				<div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32">
					{configurations.map((configuration, index) => (
						<ConfigurationItem key={`conf_item_${index}`} {...configuration} />
					))}
				</div>
			</Inner>
		</section>
	)
}
