import { InputField } from '@app/lib/form'
import { Component, Field, StaticRender, useField } from '@contember/interface'
import { useEffect } from 'react'

export const YoutubeEmbed = Component(() => {
	return (
		<>
			<StaticRender>
				<Field field="embed.type" defaultValue="youtube" isNonbearing />
			</StaticRender>
			<ParseUrl />
			<InputField field="embed.code" label="Video code / URL" />
			<YoutubePreview />
		</>
	)
})

const ParseUrl = () => {
	const fieldGetAccessor = useField<string>('embed.code').getAccessor
	useEffect(() => {
		return fieldGetAccessor().addEventListener({ type: 'beforeUpdate' }, it => {
			if (it.value?.includes('://')) {
				const url = new URL(it.value)
				if (url.host.endsWith('youtube.com')) {
					const id = url.searchParams.get('v')
					if (id) {
						it.updateValue(id)
					}
				} else if (url.host.endsWith('youtu.be')) {
					it.updateValue(url.pathname.slice(1))
				} else {
					it.updateValue(null)
				}
			}
		})
	}, [fieldGetAccessor])
	return null
}

const YoutubePreview = () => {
	const field = useField<string>('embed.code')
	if (!field.value) {
		return null
	}
	return (
		<iframe
			width="560"
			height="315"
			src={`https://www.youtube-nocookie.com/embed/${field.value}`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	)
}
