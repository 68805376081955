import type { ReactNode } from 'react'
import { Icon, Inner } from '../../atoms'
import { cn } from '../../utils/cn'

export type WebinarInfoProps = {
	title: ReactNode
	items: Array<{
		title: string
		description: ReactNode
	}>
}

export const WebinarInfo = ({ title, items }: WebinarInfoProps) => {
	return (
		<section className="my-60 md:my-80">
			<Inner className="max-w-[1210px]">
				<div className="bg-pozadiBoxu p-40 md:p-80 rounded">
					<div className="flex items-center border-b border-outlines-light pb-43 md:pb-40 gap-25">
						<Icon name="webinarInfo" />
						<h3 className="text-h4 sm:text-h3 max-w-[300px]">{title}</h3>
					</div>

					<ol className={cn('grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-40 mt-32 md:mt-40')} style={{ counterReset: 'counter' }}>
						{items.map((item, index) => (
							<li
								key={index}
								className={cn('flex items-start gap-32', 'before:content-[counter(counter, decimal-leading-zero)] before:text-h3 before:leading-none before:text-cta-default before:font-medium')}
								style={{ counterIncrement: 'counter' }}
							>
								<div>
									<h6 className="text-h4 mb-8">{item.title}</h6>
									<p className="text-body">{item.description}</p>
								</div>
							</li>
						))}
					</ol>
				</div>
			</Inner>
		</section>
	)
}
