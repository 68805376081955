'use client'
import { gsap } from 'gsap'
import { type ReactNode, useEffect, useRef } from 'react'
import type { HeadlineLevel } from '../atoms'
import { AnimatedNumber, BlockConnection, Inner } from '../atoms'

export type GlobalNetworkProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
	}
	leadText: ReactNode
	items: Array<{
		title: string
		text: ReactNode
	}>
}

export const GlobalNetwork = (props: GlobalNetworkProps) => {
	const { headline, leadText, items } = props

	const circleRefs = useRef<Array<SVGCircleElement | null>>([])

	useEffect(() => {
		setTimeout(() => {
			circleRefs.current.forEach(circle => {
				const animate = () => {
					const randomDelay = Math.random() * 10 + 1

					gsap
						.timeline({
							onComplete: () => {
								gsap.delayedCall(randomDelay, animate) // Call the animation again with a random delay
							},
						})
						.to(circle, {
							scale: 10,
							duration: 1,
							transformOrigin: '50% 50%',
						})
						.to(circle, {
							scale: 1,
							duration: 1,
							transformOrigin: '50% 50%',
						})
				}
				setTimeout(
					() => {
						animate()
					},
					Math.random() * 10 + 1,
				)
			})

			return () => {
				circleRefs.current.forEach(circle => {
					gsap.killTweensOf(circle)
				})
			}
		}, 100)
	}, [])

	return (
		<section className="bg-pozadiBoxu relative py-80 md:py-120 overflow-hidden">
			<Inner className="text-center">
				<div className="linear-gradient(80deg, #F2F7F7 0%, rgba(242, 247, 247, 0) 100%) relative z-10">
					<div className="text-h3 md:text-h2 max-w-[725px] mx-auto pb-134">{headline.children}</div>
				</div>
				<div className="relative -mx-50 md:mx-auto max-w-[1045px] -mt-150 md:-mt-180 -mb-10 md:-mb-75">
					<div className="relative">
						<img src="/world_map.svg" alt="world map" width="1045" height="516" loading="lazy" />

						<svg
							width="832"
							height="328"
							viewBox="0 0 832 328"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="w-[79.85%] h-auto absolute left-[10.8%] top-1/2 -translate-y-[39%]"
						>
							<circle opacity="0.2" cx="397" cy="44" r="44" fill="#4B865C" />
							<circle cx="397" cy="44" r="43.5" stroke="#4B865C" />
							<circle opacity="0.2" cx="675" cy="199" r="20" fill="#4B865C" />
							<circle opacity="0.2" cx="780" cy="96" r="20" fill="#4B865C" />
							<circle opacity="0.2" cx="590" cy="148" r="20" fill="#4B865C" />
							<circle cx="590" cy="148" r="19.5" stroke="#4B865C" />
							<circle cx="780" cy="96" r="19.5" stroke="#4B865C" />
							<circle cx="675" cy="199" r="19.5" stroke="#4B865C" />
							<circle cx="812" cy="308" r="19.5" stroke="#4B865C" />
							<circle opacity="0.2" cx="162" cy="77" r="20" fill="#4B865C" />
							<circle opacity="0.2" cx="116" cy="81" r="20" fill="#4B865C" />
							<circle opacity="0.2" cx="20" cy="55" r="20" fill="#4B865C" />
							<circle opacity="0.2" cx="812" cy="308" r="20" fill="#4B865C" />

							<circle cx="420" cy="43" r="2" fill="#4B865C" />
							<circle
								cx="420"
								cy="43"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[0] = el
								}}
							/>

							<circle cx="400" cy="36" r="2" fill="#4B865C" />
							<circle
								cx="400"
								cy="36"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[1] = el
								}}
							/>

							<circle cx="403" cy="36" r="2" fill="#4B865C" />
							<circle
								cx="403"
								cy="36"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[2] = el
								}}
							/>

							<circle cx="412" cy="46" r="2" fill="#4B865C" />
							<circle
								cx="412"
								cy="46"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[3] = el
								}}
							/>

							<circle cx="409" cy="51" r="2" fill="#4B865C" />
							<circle
								cx="409"
								cy="51"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[4] = el
								}}
							/>

							<circle cx="400" cy="48" r="2" fill="#4B865C" />
							<circle
								cx="400"
								cy="48"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[5] = el
								}}
							/>

							<circle cx="780" cy="96" r="2" fill="#4B865C" />
							<circle
								cx="780"
								cy="96"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[6] = el
								}}
							/>

							<circle cx="590" cy="148" r="2" fill="#4B865C" />
							<circle
								cx="590"
								cy="148"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[7] = el
								}}
							/>

							<circle cx="675" cy="199" r="2" fill="#4B865C" />
							<circle
								cx="675"
								cy="199"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[8] = el
								}}
							/>

							<circle cx="812" cy="308" r="2" fill="#4B865C" />
							<circle
								cx="812"
								cy="308"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[9] = el
								}}
							/>

							<circle cx="162" cy="77" r="2" fill="#4B865C" />
							<circle
								cx="162"
								cy="77"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[10] = el
								}}
							/>

							<circle cx="116" cy="81" r="2" fill="#4B865C" />
							<circle
								cx="116"
								cy="81"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[11] = el
								}}
							/>

							<circle cx="20" cy="55" r="2" fill="#4B865C" />
							<circle
								cx="20"
								cy="55"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[12] = el
								}}
							/>

							<circle cx="374" cy="40" r="2" fill="#4B865C" />
							<circle
								cx="374"
								cy="40"
								r="2"
								fill="#4B865C"
								opacity="0.5"
								ref={el => {
									circleRefs.current[13] = el
								}}
							/>

							<g opacity="0.5">
								<path d="M797 295L690.5 211.5" stroke="#4B865C" />
								<path d="M689 185L766 110" stroke="#4B865C" />
								<path d="M658 188.5L606.5 159" stroke="#4B865C" />
								<path d="M572 139L435 66" stroke="#4B865C" />
								<path d="M181.5 81.5L655.5 195" stroke="#4B865C" />
								<path d="M181.5 74.5L353.5 49" stroke="#4B865C" />
								<path d="M135.5 79.5L142.5 79" stroke="#4B865C" />
								<path d="M609.5 143.5L761 102" stroke="#4B865C" />
								<path d="M96.5 76.5L39 61.5" stroke="#4B865C" />
							</g>
							<circle cx="116" cy="81" r="19.5" stroke="#4B865C" />
							<circle cx="162" cy="77" r="19.5" stroke="#4B865C" />
							<circle cx="20" cy="55" r="19.5" stroke="#4B865C" />
						</svg>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-16 md:gap-32 max-w-[664px] mx-auto relative z-10">
					{items.map((item, index) => (
						<div
							key={index}
							className="bg-[url('/global_network_box_bg.png')] rounded bg-no-repeat bg-cover flex flex-col py-20 md:py-38 px-10 md:px-15 items-center justify-center"
						>
							<div className="text-[40px] md:text-[80px] leading-none font-semibold text-white">
								<AnimatedNumber finalValue={Number(item.title)} />
							</div>
							<span className="text-[10px] leading-none sm:text-p14 md:text-lg text-odstavec-dark">{item.text}</span>
						</div>
					))}
				</div>
				<div className="mt-32 max-w-[615px] mx-auto">{leadText}</div>
			</Inner>
		</section>
	)
}
