import { CopyIcon, TextIcon } from 'lucide-react'

import { Component, EntitySubTree, Field, FieldView, RedirectOnPersist } from '@contember/interface'
import { Link } from '@contember/react-routing'
import { Binding, PersistButton } from '@app/lib/binding'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { CheckboxField, InputField, TextareaField } from '@app/lib/form'
import {
	DataGrid,
	DataGridActionColumn,
	DataGridColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { DropdownMenuItem } from '@app/lib/ui/dropdown'
import { UDDropdown } from '@app/components/uddropdown'
import { LocaleSideDimension } from '@app/components/locale-side-dimension'

type ValueProps = {
	label?: true
}
const Value = Component(
	(props: ValueProps) => (
		<FieldView
			field="root.isTextarea"
			render={(field) => {
				if (field.value) {
					return <TextareaField field="value" label={props.label && 'Value'} />
				}
				return <InputField field="value" label={props.label && 'Value'} />
			}}
		/>
	),
	() => (
		<>
			<Field field="value" />
			<Field field="root.isTextarea" />
		</>
	),
)

export const create = () => (
	<Binding>
		<EntitySubTree entity="Translation" isCreating={true}>
			<Slots.Title>New translation</Slots.Title>
			<Slots.Actions>
				<PersistButton />
				<RedirectOnPersist to="translations/list" />
			</Slots.Actions>
			<InputField field="key" label="Key" />
			<LocaleSideDimension>
				<Value label />
			</LocaleSideDimension>
			<CheckboxField field="isTextarea" label="Textarea" />
		</EntitySubTree>
	</Binding>
)

export const list = () => {
	return (
		<Binding>
			<Slots.Title>Translations</Slots.Title>
			<Slots.Actions>
				<Link to="translations/create">
					<Button>Add new</Button>
				</Link>
				<PersistButton />
			</Slots.Actions>

			<DataGrid entities="Translation">
				<DataGridToolbar>
					<DataGridQueryFilter />
				</DataGridToolbar>
				<DataGridLoader>
					<DataGridTable>
						<DataGridTextColumn field="key" header="Key" />
						<DataGridColumn>
							<LocaleSideDimension>
								<Value />
							</LocaleSideDimension>
						</DataGridColumn>
						<DataGridActionColumn>
							<UDDropdown>
								<FieldView
									field="key"
									render={(key) => {
										return (
											<DropdownMenuItem>
												<Button
													onClick={() => {
														const copyString = key.value as string
														navigator.clipboard.writeText(copyString)
													}}
													type="button"
													variant="outline"
													className="w-full"
												>
													<CopyIcon className="mr-2 w-4" />
													Copy
												</Button>
											</DropdownMenuItem>
										)
									}}
								/>
								<DropdownMenuItem>
									<TextIcon className="mr-2 w-4" />
									<CheckboxField field="isTextarea" label="Textarea" />
								</DropdownMenuItem>
							</UDDropdown>
						</DataGridActionColumn>
					</DataGridTable>
				</DataGridLoader>

				<DataGridPagination />
			</DataGrid>
		</Binding>
	)
}
