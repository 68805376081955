import { InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'

type BlockButtonProps = {
	headline?: string
}

export const BlockButton = Component(({ headline }: BlockButtonProps) => (
	<>
		<div>
			<h4 className="mt-4 mb-2">{headline ?? 'Button'}</h4>
			<InputField field="text" label="Label" />
			<SelectLinkField field="link" label="Link" />
		</div>
	</>
))
