'use client'

import { useRef, useState, type ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockSectionHeader, Inner, Pagination } from '../atoms'
import { CaseStudy, type CaseStudyProps } from '../loop'
import { NextLinkComponent } from '../types'

export type CaseStudiesProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
		button?: ReactNode
	}
	caseStudies: Array<Omit<CaseStudyProps, 'usedProducts' | 'usedSolutions'>>
	itemsPerPage?: number
	usedSolutions: string
	usedProducts: string
	loadMore: string
	linkComponent?: NextLinkComponent
}

export const CaseStudies = ({ headline, caseStudies, itemsPerPage = 9, linkComponent, usedProducts, usedSolutions, loadMore }: CaseStudiesProps) => {
	const sectionRef = useRef<HTMLElement>(null)
	const [limit, setLimit] = useState(itemsPerPage)
	const pagedCaseStudies = caseStudies.slice(0, limit)

	const clientLinkComponent: NextLinkComponent = ({ href, children }) => <a href={href}>{children}</a>

	return (
		<section className="mt-[140px] mb-240" ref={sectionRef}>
			<Inner>
				<BlockSectionHeader headline={headline.children} level={headline.level} headlineClassName={headline.className} button={headline.button} />
				<div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-32 mb-24 md:mb-32">
					{pagedCaseStudies.map((caseStudy, index) =>
						(linkComponent ?? clientLinkComponent)({
							href: caseStudy.link,
							children: <CaseStudy key={index} {...caseStudy} usedProducts={usedProducts} usedSolutions={usedSolutions} />,
						}),
					)}
				</div>
				{itemsPerPage < caseStudies.length && (
					<Pagination
						itemsSpan={itemsPerPage}
						totalItems={caseStudies.length}
						loadMore={loadMore}
						onLoadMore={setLimit}
						currentLimit={limit}
					/>
				)}
			</Inner>
		</section>
	)
}
