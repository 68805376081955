import { Component } from '@contember/interface'
import { FileField } from './articleSignUp'
import { InputField } from '@app/lib/form'

export const FileDownload = Component(() => (
	<>
		<InputField field="file.title" label="Title" />
		<FileField baseField="file" label="Document" />
	</>
))
