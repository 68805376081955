import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

export type TechnologyItemProps = {
	logo: ReactNode
	title?: ReactNode
	teaser?: ReactNode
	link: string | null
	buttonLabel: string
}

export const TechnologyItem = ({
	logo,
	title,
	teaser,
	variant,
	link,
	buttonLabel,
}: TechnologyItemProps & {
	variant: 'default' | 'small'
}) => {
	if (variant === 'small') {
		return (
			<article className="rounded border bg-white border-outlines-light px-[25px] py-[16px] flex justify-center items-center transition-all group-hover:border-cta-default">
				<div>
					<div className="max-h-[40px]  [&_img]:w-auto [&_img]:h-[40px] [&_img]:object-contain">{logo}</div>
				</div>
			</article>
		)
	}

	return (
		<article
			className={cn('rounded border bg-white border-outlines-light p-32', {
				'flex flex-col justify-between gap-4': !link,
			})}
		>
			<div className={`min-h-[${link ? '150px' : '100px'}]`}>
				<div className="max-h-[70px] [&>img]:w-auto [&>img]:max-h-[70px] [&>img]:max-w-[200px]">{logo}</div>
			</div>
			<header className="">
				<h3 className={cn('text-nadpisy text-h4 md:text-h3', { 'mb-16': teaser || link })}>{title}</h3>
			</header>
			{teaser && <div className="text-odstavec mb-16">{teaser}</div>}
			{link && (
				<footer className="mt-auto mb-0">
					<span className="btn btn--tercial group-hover:text-cta-hover group-hover:after:bg-cta-hover">{buttonLabel}</span>
				</footer>
			)}
		</article>
	)
}
