import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { RichText } from '../rich-text'

export const NotFoundForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
			<RichText field="text" label="Text" />
			<InputField field="returnBack" label="Return back" required />
		</LocaleSideDimension>
	</FormLayout>
))
