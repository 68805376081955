import { ArticleDataGrid } from '@app/components/article-datagrid'
import { CopyEntity, CopyPopover } from '@app/components/copier'
import { DeleteEntityButton, ToggleButton } from '@app/components/deleteEntityButton'
import { ArticleForm } from '@app/components/forms/article-form'
import { PreviewButton } from '@app/components/previewButton'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { Slots } from '@app/lib/layout'
import { AnchorButton, Button } from '@app/lib/ui/button'
import { EntitySubTree, Link, RedirectOnPersist } from '@contember/interface'
import { useState } from 'react'

export const List = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Article list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="article/create">
								<Button>Create article</Button>
							</Link>
						</Slots.Actions>
						<ArticleDataGrid />
					</>
				</div>
			</Binding>
		</>
	)
}

export const Create = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Article create</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Article" isCreating>
						<RedirectOnPersist to="article/edit(id: $entity.id)" />
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<ArticleForm />
					</EntitySubTree>
					<CopyEntity entityName="Article">
						<ArticleForm />
					</CopyEntity>
				</div>
			</Binding>
		</>
	)
}

export const Edit = () => {
	const [showDeleteButton, setShowDeleteButton] = useState(false)

	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Article edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Article(id=$id)" isCreating={false}>
						<Slots.Actions>
							<div className="flex items-start gap-4">
								<CopyPopover>
									<Link to="article/create(copy: $entity.id)">
										<AnchorButton variant="secondary">Duplicate</AnchorButton>
									</Link>
								</CopyPopover>
								<PersistButton />
								<div className="flex flex-col items-end">
									<PreviewButton pageType="article" />
									<ToggleButton isOpen={showDeleteButton} onClick={() => setShowDeleteButton(!showDeleteButton)} />
									{showDeleteButton && <DeleteEntityButton />}
								</div>
							</div>
						</Slots.Actions>

						<ArticleForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
