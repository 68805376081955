import { DataGridTextColumn } from '@app/lib/datagrid'
import { Component } from '@contember/interface'
import { LocaleBadge } from './locale-badge'

type DataGridLocaleTextColumnProps = {
	field: string
	header: string
}

export const DataGridLocaleTextColumn = Component<DataGridLocaleTextColumnProps>((props, env) => {
	const dimensions = env.getDimensionOrElse('locale', [])

	return (
		<>
			{dimensions.map((dimension) => (
				<DataGridTextColumn
					key={dimension}
					header={
						<>
							{props.header} <LocaleBadge>{dimension}</LocaleBadge>
						</>
					}
					field={`locales(locale.code="${dimension}").${props.field}`}
				/>
			))}
		</>
	)
})
