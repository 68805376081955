import type { ReactNode } from 'react'
import { Inner } from '../atoms'

export type ContactUsStripeProps = {
	title: ReactNode
	button: ReactNode
}
export const ContactUsStripe = (props: ContactUsStripeProps) => {
	return (
		<section className="my-150 md:my-160">
			<Inner className="text-center">
				<div className="mx-auto py-35 px-40 rounded max-w-[1114px] flex gap-24 flex-col lg:flex-row items-center justify-between bg-[url('/contact_us_stripe_bg.webp')] bg-cover bg-no-repeat">
					<h6 className="text-h3 text-white">{props.title}</h6>
					{props.button}
				</div>
			</Inner>
		</section>
	)
}
