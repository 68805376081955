'use client'
import { type ReactNode, useEffect } from 'react'

export type PartnersNavigationItemProps = {
	image: ReactNode
	title: string
	teaser: ReactNode
	link: string
}

const countEqualHeights = () => {
	const els: HTMLElement[] = Array.from(document.querySelectorAll('[data-equal-heights="partners"]'))
	for (const el of els) {
		el.style.minHeight = 'auto'
	}
	const heights = els.map(el => el.offsetHeight)
	const maxHeight = Math.max(...heights)
	for (const el of els) {
		el.style.minHeight = `${maxHeight}px`
	}
}

export const PartnersNavigationItem = ({ image, title, teaser }: Omit<PartnersNavigationItemProps, 'link'>) => {
	useEffect(() => {
		window.addEventListener('load', countEqualHeights)
		window.addEventListener('resize', countEqualHeights)
		return () => {
			window.removeEventListener('load', countEqualHeights)
			window.removeEventListener('resize', countEqualHeights)
		}
	}, [])

	return (
		<article className="flex flex-col h-full bg-dark-2 rounded">
			<header className="w-full h-0 pb-[61%] relative">
				<div className="absolute inset-0 [&>img]:w-full [&>img]:h-full [&>img]:object-cover">{image}</div>
			</header>
			<div className="p-24 md:p-32  flex flex-col h-full">
				<h2 className="text-h3 sm:text-h2 md:text-h3 xl:text-h2 text-white mb-20 md:mb-48" data-equal-heights="partners">
					{title}
				</h2>
				<div className="text-odstavec-dark mt-auto pt-1">{teaser}</div>
			</div>
			<footer className="bg-dark-1 p-24 md:p-32 [&>.btn]:w-full mb-0 mt-auto">
				<span className="btn btn--primary group-hover:bg-cta-hover">Více informací</span>
			</footer>
		</article>
	)
}
