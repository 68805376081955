import type { ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockConnection, BlockSectionHeader, Inner } from '../atoms'
import { BlogItem, type BlogItemProps } from '../loop'
import { NextLinkComponent } from '../types'

export type BlogTeaserProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
		button?: ReactNode
	}
	articles: Array<BlogItemProps>
	linkComponent: NextLinkComponent
}

export const BlogTeaser = ({ headline, articles, linkComponent }: BlogTeaserProps) => {
	return (
		<section className="bg-pozadiBoxu relative py-80 md:py-120">
			<BlockConnection fillColor="#F2F7F7" />
			<Inner>
				<BlockSectionHeader headline={headline.children} level={headline.level} headlineClassName={headline.className} button={headline.button} />
				<div className="grid grid-cols-1 md:grid-cols-3 gap-80 md:gap-32">
					{articles.map((article, index) => (
						<BlogItem key={index} {...article} linkComponent={linkComponent} />
					))}
				</div>
				{headline.button && <div className="flex [&>.btn]:w-full mt-80 text-center md:hidden">{headline.button}</div>}
			</Inner>
		</section>
	)
}
