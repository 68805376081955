import { ProductPreview } from '@app/components/previews/product-preview'
import {
	FormLayout,
	ImageField,
	InputField,
	MultiSelectField,
	RadioEnumField,
	SelectField,
	SortableMultiSelectField,
	TextareaField,
} from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { Component, If } from '@contember/interface'
import { ContentEditor } from '../content-editor'
import { LocaleSideDimension } from '../locale-side-dimension'
import { AuthorPreview } from '../previews/author-preview'
import { SolutionsPreview } from '../previews/solutions-preview'
import { TagsPreview } from '../previews/tags-preview'
import { PrimaryLocale } from '../primary-locale'
import { RichText } from '../rich-text'
import { AuthorForm } from './author-form'
import { TagForm } from './tag-form'
import { AlertCircle } from 'lucide-react'

export const CaseStudyForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<If condition="[publishedAt = null && archivedAt = null && link.url != null]">
				<div className="bg-blue-50 text-blue-500 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Draft
				</div>
			</If>
			<If condition="[publishedAt != null]">
				<div className="bg-lime-50 text-green-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Published
				</div>
			</If>
			<If condition="[archivedAt != null]">
				<div className="bg-amber-100 text-amber-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Archived
				</div>
			</If>
			<InputField field="publishedAt" label="Published at" />
			<InputField field="archivedAt" label="Archived at" />
		</LocaleSideDimension>
		<div className="grid grid-cols-2 gap-4">
			<ImageField
				label="Logo"
				baseField="logo"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
		</div>
		<LocaleSideDimension>
			<SelectField field="author" label="Author" createNewForm={<AuthorForm />}>
				<AuthorPreview />
			</SelectField>
		</LocaleSideDimension>

		<div className="bg-red-50">
			<span className="text-red-500">Do not use:</span>
			<SelectField field="author" label="Author" createNewForm={<AuthorForm />}>
				<AuthorPreview />
			</SelectField>
		</div>
		<LocaleSideDimension>
			<TextareaField field="title" label="Title" required />
			<SlugField label="Link" field="link.url" derivedFrom="title" persistedHardPrefix="/" persistedSoftPrefix="references/" />
			<RichText field="leadText" label="Lead text" />
			<InputField field="infoText" label="Info text" />
			<InputField field="revenue" label="Revenue (visible on case study tile)" />
		</LocaleSideDimension>
		<MultiSelectField field="tags" label="Tags" createNewForm={<TagForm />}>
			<PrimaryLocale>
				<TagsPreview />
			</PrimaryLocale>
		</MultiSelectField>
		<SortableMultiSelectField field="products" label="Used products" connectAt="product" sortableBy="displayOrder">
			<PrimaryLocale>
				<ProductPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<SortableMultiSelectField field="solutions" label="Used solutions" connectAt="solution" sortableBy="displayOrder">
			<PrimaryLocale>
				<SolutionsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<LocaleSideDimension>
			<ContentEditor
				label="Content"
				field="content.data"
				referencesField="content.references"
				enableFileDownload={false}
				enableArticleLocking={true}
			/>
			<SeoForm field="seo" />
		</LocaleSideDimension>
	</FormLayout>
))
