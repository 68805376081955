import type { ReactNode } from 'react'

export type TestimonialItemProps = {
	avatar: ReactNode
	name: string
	role: string
	content: ReactNode
	companyLogo?: ReactNode
	displayOrder?: number
}

export const TestimonialItem = ({ avatar, name, role, content, companyLogo, displayOrder }: TestimonialItemProps) => {
	return (
		<article className="border border-outlines-light bg-white">
			<header className="flex items-center justify-between bg-pozadiBoxu border-b border-outlines-light p-24">
				<div className="flex items-center gap-20">
					<div className="rounded-full max-w-[80px] [&>img]:block overflow-hidden">{avatar}</div>
					<div className="flex flex-col">
						<h6 className="text-h4">{name}</h6>
						<small className="text-base">{role}</small>
					</div>
				</div>
				{companyLogo && <div className="max-w-[130px]">{companyLogo}</div>}
			</header>
			<blockquote className="p-24 text-odstavec">{content}</blockquote>
		</article>
	)
}
