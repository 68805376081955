import clsx from 'clsx'
import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

export type ProductAdvantageItemProps = {
	className?: string
	icon: ReactNode
	title: ReactNode
	teaser?: ReactNode
	mode?: 'light' | 'dark'
}

export const ProductAdvantageItem = ({ className, icon, title, teaser, mode }: ProductAdvantageItemProps & { mode: 'dark' | 'light' }) => {
	return (
		<div className={cn('md:py-32 [&:nth-child(3n+1)>article]:before:hidden', className)}>
			<article
				className={clsx(
					"px-32 relative md:before:content-[''] before:w-2 before:absolute before:-left-32 before:top-0 before:bottom-0  h-full",
					{ 'before:bg-outlines-dark/10': mode === 'dark' },
					{ 'before:bg-outlines-light': mode === 'light' },
				)}
			>
				<div className="mb-20 md:mb-56 h-70 [&>img]:h-[70px] max-h-[70px]">{icon}</div>
				<h4 className={clsx('text-nadpisy text-h4 mb-14', { 'text-white': mode === 'dark' })}>{title}</h4>
				<div className={clsx('text-odstavec mb-14', { 'text-odstavec-dark': mode === 'dark' })}>{teaser}</div>
			</article>
		</div>
	)
}
