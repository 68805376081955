import { FileFragmentType } from '@website/api'
import { Inner } from '../../atoms'

export type FileDownloadProps = {
	file?: FileFragmentType | null
}

export const FileDownload = ({ file }: FileDownloadProps) => {
	if (!file?.url) return null

	return (
		<section className="my-20 md:my-40">
			<Inner className="text-center max-w-[1210px]">
				<div className="md:px-40 flex gap-20 items-center">
					<a target='_blank' href={file.url} download className="flex gap-20 items-end group">
						<span className="w-32 h-32 block group-hover:filter group-hover:brightness-[80%] transition-all duration-300">
							<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.79 70.43">
								<defs>
									<style>{'.cls-1{fill:#4b865c;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#a7d6b0;}'}</style>
								</defs>
								<g id="Layer_1-2">
									<path
										className="cls-2"
										d="M52.79,19.8v39.59c0,1.81-.63,3.37-1.91,4.65-1.32,1.29-2.87,1.95-4.69,1.95H6.6c-1.81,0-3.37-.66-4.65-1.95-1.29-1.29-1.95-2.84-1.95-4.65V6.6c0-1.81.66-3.37,1.95-4.65C3.23.63,4.78,0,6.6,0h26.4l19.8,19.8Z"
									/>
									<path
										className="cls-1"
										d="M25.34,70.07l-11.89-11.89c-.48-.48-.48-1.26,0-1.74l3.21-3.21c.48-.48,1.26-.48,1.74,0l6.94,6.94c.48.48,1.26.48,1.74,0l6.94-6.94c.48-.48,1.26-.48,1.74,0l3.21,3.21c.48.48.48,1.26,0,1.74l-11.89,11.89c-.48.48-1.26.48-1.74,0Z"
									/>
									<path className="cls-1" d="M29.71,65.99h-7v-33.39c0-.67.55-1.22,1.22-1.22h4.56c.67,0,1.22.55,1.22,1.22v33.39Z" />
								</g>
							</svg>
						</span>
						<h6 className="text-[18px] md:text-[21px]">{file.title}</h6>
					</a>
				</div>
			</Inner>
		</section>
	)
}
