import { DataGridLocaleTextColumn } from '@app/components/atoms/data-grid-locale-text-column'
import { CreateEntityModalButton } from '@app/components/createEntityModalButton'
import { CategoryForm } from '@app/components/forms/category-form'
import { UDDropdown } from '@app/components/uddropdown'
import { Binding } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridActionColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Categories</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<CreateEntityModalButton entityName="Category" buttonLabel="Create">
								<CategoryForm />
							</CreateEntityModalButton>
						</Slots.Actions>
						<DataGrid entities="Category">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridLocaleTextColumn field="name" header="Title" />
									<DataGridActionColumn>
										<UDDropdown editForm={<CategoryForm />} />
									</DataGridActionColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}
