import { DomainForm } from '@app/components/forms/domain-form'
import { Binding, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import { Slots } from '@app/lib/layout'
import { EntitySubTree } from '@contember/interface'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Domain settings</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity={'Domain(unique = One)'} setOnCreate="(unique = One)">
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<DomainForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
