import {
	BlockEditorField as BlockEditorFieldBase,
	type BlockEditorFieldProps as BlockEditorFieldPropsBase,
	EditorBlock,
	EditorBlockToolbar,
	EditorInlineToolbar,
} from '@app/lib/editor'
import { Toggle } from '@app/lib/ui/toggle'
import { uic } from '@app/lib/utils'
import { Component, HasOne } from '@contember/interface'
import {
	anchorElementType,
	boldMark,
	codeMark,
	createAlignHandler,
	EditorElementTrigger,
	EditorGenericTrigger,
	EditorMarkTrigger,
	EditorReferenceTrigger,
	headingElementType,
	highlightMark,
	italicMark,
	orderedListElementType,
	paragraphElementType,
	strikeThroughMark,
	underlineMark,
	unorderedListElementType,
} from '@contember/react-slate-editor'
import {
	AlignCenterIcon,
	AlignJustifyIcon,
	AlignLeftIcon,
	AlignRightIcon,
	AtSignIcon,
	BadgeDollarSignIcon,
	BoldIcon,
	Box,
	BoxesIcon,
	CodeIcon,
	EarthIcon,
	FileDown,
	Heading1Icon,
	Heading2Icon,
	Heading3Icon,
	HighlighterIcon,
	ImageIcon,
	ItalicIcon,
	Link2Icon,
	ListIcon,
	ListOrderedIcon,
	LockIcon,
	MailIcon,
	PilcrowIcon,
	QuoteIcon,
	StrikethroughIcon,
	UnderlineIcon,
	YoutubeIcon,
} from 'lucide-react'
import { ArticleSignUp } from './blocks/articleSignUp'
import { CompanyInNumbersForm } from './blocks/companyInNumbers'
import { CrossSellCompany } from './blocks/crossSellCompany'
import { ProductTilesForm } from './blocks/productTiles'
import { QuoteForm } from './blocks/quote'
import { WebinarTeaserForm } from './blocks/webinarTeaserForm'
import { YoutubeEmbed } from './blocks/youtubeEmbed'
import { ImageField } from './image-field'
import { FileDownload } from './blocks/fileDownload'
import { CustomizedTilesForm } from './blocks/customizedTilesForm'

export interface BlockEditorFieldProps {
	field: BlockEditorFieldPropsBase['field']
	referencesField: BlockEditorFieldPropsBase['referencesField']
	label?: string
	enableArticleLocking?: boolean
	enableFileDownload?: boolean
}

const BlockButton = uic('button', {
	baseClass: 'bg-white p-2 inline-flex flex-col hover:bg-gray-100 border rounded-md w-32 items-center justify-center',
})

export const ContentEditor = Component(({ field, enableFileDownload, referencesField, label, enableArticleLocking }: BlockEditorFieldProps) => (
	<div className="mr-4 flex flex-col gap-2">
		{label && <label className="text-sm font-medium">{label}</label>}
		<BlockEditorFieldBase
			field={field}
			referencesField={referencesField}
			referenceDiscriminationField="type"
			plugins={
				[
					// Example how to register custom plugin
					// editor => {
					// 	editor.registerElement({
					// 		type: 'link',
					// 		isInline: true,
					// 		render: LinkElement,
					// 	})
					// },
				]
			}
		>
			<EditorBlockToolbar>
				<div className="flex flex-wrap gap-2">
					<EditorReferenceTrigger referenceType="companyInNumbers">
						<BlockButton>
							<BadgeDollarSignIcon /> Company in numbers
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="quote">
						<BlockButton>
							<QuoteIcon /> Quote
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="webinarTeaser">
						<BlockButton>
							<EarthIcon /> Webinar teaser
						</BlockButton>
					</EditorReferenceTrigger>
					{/* <EditorReferenceTrigger referenceType="crossSellCategory">
						<BlockButton>
							<BlocksIcon /> Cross sell category
						</BlockButton>
					</EditorReferenceTrigger> */}
					<EditorReferenceTrigger referenceType="crossSellCompany">
						<BlockButton>
							<MailIcon /> Contact banner
						</BlockButton>
					</EditorReferenceTrigger>
					{/* <EditorReferenceTrigger referenceType="fileDownload">
						<BlockButton>
							<BlocksIcon /> File Download
						</BlockButton>
					</EditorReferenceTrigger> */}

					<EditorReferenceTrigger referenceType="image">
						<BlockButton>
							<ImageIcon /> Image
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="embed">
						<BlockButton>
							<YoutubeIcon /> YouTube
						</BlockButton>
					</EditorReferenceTrigger>
					{enableFileDownload && (
						<EditorReferenceTrigger referenceType="fileDownload">
							<BlockButton>
								<FileDown /> File download
							</BlockButton>
						</EditorReferenceTrigger>
					)}
					{enableArticleLocking && (
						<EditorReferenceTrigger referenceType="lockArticle">
							<BlockButton>
								<LockIcon /> Lock rest of article
							</BlockButton>
						</EditorReferenceTrigger>
					)}
					<EditorReferenceTrigger referenceType="productTiles">
						<BlockButton>
							<Box /> Product tiles
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="customTiles">
						<BlockButton>
							<BoxesIcon	 /> Customized tiles
						</BlockButton>
					</EditorReferenceTrigger>
					<EditorReferenceTrigger referenceType="articleSignUp">
						<BlockButton>
							<AtSignIcon /> Gated content
						</BlockButton>
					</EditorReferenceTrigger>
				</div>
			</EditorBlockToolbar>
			<EditorInlineToolbar>
				<div>
					<EditorMarkTrigger mark={boldMark}>
						<Toggle>
							<BoldIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={italicMark}>
						<Toggle>
							<ItalicIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={underlineMark}>
						<Toggle>
							<UnderlineIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={strikeThroughMark}>
						<Toggle>
							<StrikethroughIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={highlightMark}>
						<Toggle>
							<HighlighterIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorMarkTrigger mark={codeMark}>
						<Toggle>
							<CodeIcon className="h-3 w-3" />
						</Toggle>
					</EditorMarkTrigger>
					<EditorElementTrigger elementType={anchorElementType}>
						<Toggle>
							<Link2Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					{/*<Popover>*/}
					{/*	<PopoverTrigger asChild>*/}
					{/*		<Toggle><LinkIcon className="h-3 w-3" /></Toggle>*/}
					{/*	</PopoverTrigger>*/}
					{/*	<PopoverContent>*/}
					{/*		<EditorInlineReferencePortal referenceType="link">*/}
					{/*			<LinkField field="link" />*/}
					{/*			<ConfirmReferenceButton />*/}
					{/*		</EditorInlineReferencePortal>*/}
					{/*	</PopoverContent>*/}
					{/*</Popover>*/}
				</div>
				<div>
					<EditorElementTrigger elementType={paragraphElementType} suchThat={{ isNumbered: false }}>
						<Toggle>
							<PilcrowIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 1, isNumbered: false }}>
						<Toggle>
							<Heading1Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 2, isNumbered: false }}>
						<Toggle>
							<Heading2Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={headingElementType} suchThat={{ level: 3, isNumbered: false }}>
						<Toggle>
							<Heading3Icon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={unorderedListElementType}>
						<Toggle>
							<ListIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>
					<EditorElementTrigger elementType={orderedListElementType}>
						<Toggle>
							<ListOrderedIcon className="h-3 w-3" />
						</Toggle>
					</EditorElementTrigger>

					<EditorGenericTrigger {...createAlignHandler('start')}>
						<Toggle className="ml-4">
							<AlignLeftIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('end')}>
						<Toggle>
							<AlignRightIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('center')}>
						<Toggle>
							<AlignCenterIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
					<EditorGenericTrigger {...createAlignHandler('justify')}>
						<Toggle>
							<AlignJustifyIcon className="h-3 w-3" />
						</Toggle>
					</EditorGenericTrigger>
				</div>
			</EditorInlineToolbar>

			<EditorBlock name="quote" label="Quote">
				<HasOne field="block">
					<QuoteForm />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="companyInNumbers" label="Company in numbers">
				<HasOne field="block">
					<CompanyInNumbersForm />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="webinarTeaser" label="Webinar teaser">
				<HasOne field="block">
					<WebinarTeaserForm />
				</HasOne>
			</EditorBlock>
			{/* <EditorBlock name="crossSellCategory" label="Cross sell category">
				<HasOne field="block">
					<CrossSellCategory />
				</HasOne>
			</EditorBlock> */}
			<EditorBlock name="crossSellCompany" label="Contact banner">
				<HasOne field="block">
					<CrossSellCompany />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="fileDownload" label="File download">
				<FileDownload />
			</EditorBlock>
			<EditorBlock name="articleSignUp" label="Gated content">
				<HasOne field="block">
					<ArticleSignUp />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="image" label="Image">
				<ImageField baseField="image" />
			</EditorBlock>
			<EditorBlock name="embed" label="Embed">
				<YoutubeEmbed />
			</EditorBlock>
			<EditorBlock name="productTiles" label="Products">
				<HasOne field="block">
					<ProductTilesForm />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="customTiles" label="Customized tiles">
				<HasOne field="block">
					<CustomizedTilesForm />
				</HasOne>
			</EditorBlock>
			<EditorBlock name="lockArticle" label="Lock rest of article">
				<hr />
			</EditorBlock>
		</BlockEditorFieldBase>
		<div />
	</div>
))
