'use client'

import { useRef, useState, type ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { BlockConnection, BlockSectionHeader, Inner, Pagination } from '../atoms'
import { TestimonialItem, type TestimonialItemProps } from '../loop'
import { cn } from '../utils/cn'

export type TestimonialsProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
		className?: string
	}
	testimonials: Array<TestimonialItemProps>
	itemsPerPage?: number
	loadMore: string
}

export const Testimonials = ({ headline, testimonials, itemsPerPage = 4, loadMore }: TestimonialsProps) => {
	const sectionRef = useRef<HTMLElement>(null)
	const [limit, setLimit] = useState(itemsPerPage)
	const pagedTestimonials = testimonials.slice(0, limit)

	return (
		<section className="bg-pozadiBoxu relative mt-160 md:mt-240 py-80 md:py-120" ref={sectionRef}>
			<BlockConnection fillColor="#F2F7F7" />
			<Inner className="max-w-[1210px]">
				<BlockSectionHeader
					headline={headline.children}
					headlineClassName={cn('mx-auto text-center md:mx-0 md:text-left', headline.className)}
					level={headline.level}
				/>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-24 md:gap-32">
					{pagedTestimonials.map((testimonial, index) => (
						<TestimonialItem key={index} {...testimonial} />
					))}
				</div>
				{itemsPerPage < testimonials.length && (
					<Pagination
						loadMore={loadMore}
						itemsSpan={itemsPerPage}
						totalItems={testimonials.length}
						currentLimit={limit}
						onLoadMore={setLimit}
					/>
				)}
			</Inner>
		</section>
	)
}
