import { Component, EntitySubTree, Field, HasMany, HasOne, useEntitySubTree, useEnvironment } from "@contember/interface";
import { FormFieldScope, FormInputProps } from "@contember/react-form";
import { FormContainer, FormContainerProps } from "@app/lib/form";
import { Input, InputBare, InputLike } from "@app/lib/ui/input";
import { cn } from "@app/lib/utils";
import { ClipboardIcon, ExternalLinkIcon } from "lucide-react";
import * as React from "react";
import { ComponentProps, forwardRef, ReactNode } from "react";
import defaultSlugify from "slugify";
import { FormSlugInput, SlugInputOwnProps } from "./FormSlugInput";
import { LocaleSideDimension } from "@app/components/locale-side-dimension";

export type SlugFieldProps = Omit<FormInputProps, "children"> &
  Omit<FormContainerProps, "children"> &
  Omit<SlugInputOwnProps, "slugify"> & {
    required?: boolean;
    inputProps?: ComponentProps<typeof SlugInput>;
    slugify?: (value: string) => string;
    domain?: string;
  };

export const SlugField = Component(
  ({
    field,
    label,
    description,
    inputProps,
    required,
    slugify,
    domain,
    ...props
  }: SlugFieldProps) => {
    return (
      <FormFieldScope field={field}>
        <FormContainer description={description} label={label}>
          <FormSlugInput
            field={field}
            slugify={slugify ?? defaultSlugify}
            {...props}
          >
            <SlugInput
              required={required}
              domain={domain}
              {...(inputProps ?? {})}
              className={cn("max-w-md", inputProps?.className)}
            />
          </FormSlugInput>
        </FormContainer>
      </FormFieldScope>
    );
  }
);


export const LocaleSlugField = Component<SlugFieldProps>(
	(props) => {

		const domainSubtree = useEntitySubTree({ entity: "Domain(unique = One)" })
		const domain = domainSubtree.getField<string>(`locales(locale.code=$currentLocaleCode).domain`).value ?? undefined
 debugger


		return <SlugField domain={domain}  {...props} />
	},
	(props, env) => {
		return <>
		<EntitySubTree entity="Domain(unique = One)">
      <HasOne field="locales(locale.code=$currentLocaleCode)">
			<Field field="domain"/>
      </HasOne>
      
		</EntitySubTree>
		<SlugField {...props} />
		</>
	},
)

export type SlugInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type"
> & {
  prefix?: ReactNode;
  href?: string;
  domain?: string;
};

export const SlugInput = forwardRef<HTMLInputElement, SlugInputProps>(
  ({ prefix, href, className, domain, ...props }, ref) => {

    const targetUrl = domain ? `${domain}${href}` : href

    const copyToClipboard = () => {
          if (targetUrl) {
              navigator.clipboard.writeText(targetUrl);
          }
      };


    return (
      <InputLike className="relative">
        {prefix && (
          <span className="-my-2 -ml-2 text-gray-400 self-stretch py-1 pl-2 flex  flex-none items-center">{prefix}</span>
        )}
        <InputBare className={cn("pr-1", className)} {...props} ref={ref} />
       {targetUrl && (
		   <div className="ml-auto self-stretch flex items-center bg-gray-50 -my-2 -mr-2 ">
		   <a
			   className="px-2 text-gray-600 rounded-r-md border-l hover:bg-gray-100 h-full flex items-center"
			   href={targetUrl}
			   target="_blank"
			   rel="noreferrer"
		   >
			   <ExternalLinkIcon className="h-4 w-4" />
		   </a>
		   <button
		   		type='button'
			   className="px-2 text-gray-600 bg-gray-50 rounded-r-md border-l hover:bg-gray-100 h-full flex items-center"
			   onClick={copyToClipboard}
		   >
			   <ClipboardIcon className="h-4 w-4" />
		   </button>
       </div>)}
      </InputLike>
    );
  }
);
