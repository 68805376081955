import { InputField, SelectEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { blockBgColor } from '@app/labels'
import { ImageField } from '../image-field'

export const NumberedListForm = Component(() => (
	<>
		<SelectEnumField field="bgColor" label="Background color" options={blockBgColor} defaultValue="green" />
		<ImageField label="Icon" baseField="icon" />
		<BlockHeadline />
		<h4 className="mt-8 mb-4">Lead text</h4>
		<BlockEditorField field="content.data" referencesField="content.references" label="Content" />

		<h4 className="mt-8 mb-4">Ordered items</h4>
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<InputField field="title" label="Title" />
			<RichText field="text" label="Description" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
