import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'

export const DomainForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField
				field="domain"
				label="Domain"
				inputProps={{
					placeholder: 'https://vshosting.cz',
				}}
				required
			/>
		</LocaleSideDimension>
	</FormLayout>
))
