import { InputField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { BlockButton } from './blockButton'

export const CrossSellCompany = Component(() => (
	<>
		<InputField field="primaryText" label="Text" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
		<HasOne field="secondaryButton">
			<BlockButton />
		</HasOne>
	</>
))
