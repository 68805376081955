import { SortableMultiSelectField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { PrimaryLocale } from '../primary-locale'
import { PartnerPreview } from '../previews/partner-preview'

export const PartnersNavigationForm = Component(() => (
	<SortableMultiSelectField
		field="partners"
		label="Partners"
		description="If empty all partners will be shown"
		sortableBy="displayOrder"
		connectAt="partner"
	>
		<PrimaryLocale>
			<PartnerPreview />
		</PrimaryLocale>
	</SortableMultiSelectField>
))
