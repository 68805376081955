import type { ReactNode } from 'react'
import { InfoIcon, TagList } from '../atoms'

export type CaseStudyProps = {
	link: string
	image: ReactNode
	tags: ReactNode[]
	title: ReactNode
	teaser: ReactNode
	revenue?: string
	products?: string[]
	solutions?: string[]
	usedProducts: string
	usedSolutions: string
}

export const CaseStudy = ({
	image,
	usedProducts,
	usedSolutions,
	tags,
	title,
	teaser,
	revenue,
	products,
	solutions,
}: Omit<CaseStudyProps, 'link'>) => {
	return (
		<article className="bg-pozadiBoxu flex flex-col h-full p-32 rounded border border-pozadiBoxu transition-all duration-300 hover:border-cta-default">
			<header>
				<div className="bg-white flex px-30 py-40 [&_img]:object-contain h-[190px] items-center [&_img]:w-[76%] [&_img]:h-[110px] [&_img]:my-auto [&_img]:mx-auto">
					{image}
				</div>
				<TagList tags={tags} mode="dark" />
			</header>
			<h4 className="text-h4">{title}</h4>
			<div className="text-odstavec mt-12">{teaser}</div>
			<div className="mt-auto">
				{revenue && (
					<div className="bg-white border-2 border-outlines-light mt-32 py-12 px-16 font-semibold flex items-center gap-12">
						<InfoIcon />
						{revenue}
					</div>
				)}
				{products?.length ? (
					<div className="mt-16">
						<p className="text-odstavec">{usedProducts}</p>
						<ul className="text-lg font-semibold leading-snug">
							{products.map(product => (
								<li key={product}>- {product}</li>
							))}
						</ul>
					</div>
				) : null}
				{solutions?.length ? (
					<div className="mt-16">
						<p className="text-odstavec">{usedSolutions}</p>
						<ul className="text-lg font-semibold leading-snug">
							{solutions.map(solution => (
								<li key={solution}>- {solution}</li>
							))}
						</ul>
					</div>
				) : null}
			</div>
		</article>
	)
}
