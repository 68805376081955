import { SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasOne, StaticRender } from '@contember/interface'
import { ReviewsPreview } from '../previews/reviews-preview'
import { PrimaryLocale } from '../primary-locale'
import { BlockHeadline } from './blockHeadline'

export const TestimonialsForm = Component(() => (
	<>
		<BlockHeadline />
		<SortableMultiSelectField field="reviews" sortableBy="displayOrder" connectAt="review" label="Reviews">
			<PrimaryLocale>
				<ReviewsPreview />
			</PrimaryLocale>

			<StaticRender>
				<HasOne field="avatar">
					<Field field={'url'} />
					<Field field={'alt'} />
				</HasOne>
				<HasOne field="logo">
					<Field field={'url'} />
					<Field field={'alt'} />
				</HasOne>
				<PrimaryLocale>
					<HasOne field="comment">
						<Field field="data" />
					</HasOne>
					<Field field="position" />
				</PrimaryLocale>
			</StaticRender>
		</SortableMultiSelectField>
	</>
))
