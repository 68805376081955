import { Inner } from '../atoms'
import { NavigationTile, type NavigationTileProps } from '../loop'
import { NextLinkComponent } from '../types'

export type NavigationTilesProps = {
	items: Array<NavigationTileProps>
	linkComponent: NextLinkComponent
}

export const NavigationTiles = ({ items, linkComponent }: NavigationTilesProps) => {
	return (
		<section className="bg-black md:bg-dark-1 py-80 md:py-48">
			<Inner>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-24 md:gap-32">
					{items.map((item, index) => linkComponent({ href: item.link, children: <NavigationTile key={index} {...item} /> }))}
				</div>
			</Inner>
		</section>
	)
}
