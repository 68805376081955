export const blockHeadlineSize = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
}

export const blockHeadlineLevel = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
}

export const blockBgColor = {
	white: 'white',
	green: 'green',
}

export const blockBgType = {
	default: 'default',
	gradient: 'gradient',
}

export const blockVariant = {
	light: 'light',
	dark: 'dark',
}

export const blockConnectAt = {
	top: 'Top',
	bottom: 'Bottom',
	none: 'None',
}

export const articleStatus = {
	draft: 'Draft',
	inReview: 'In review',
	published: 'Published',
	archived: 'Archived',
}

export const socialLinkIcon = {
	facebook: 'Facebook',
	linkedin: 'LinkedIn',
	x: 'X',
}

export const navigationItemType = {
	link: 'Link',
	button: 'Button',
}

export const pageType = {
	homepage: 'Homepage',
	contentPage: 'Content page',
	contactPage: 'Contact page',
	blogPage: 'Blog homepage',
}

export const pageBgType = {
	default: 'Light',
	dark: 'Dark',
}

export const technologyType = {
	primary: 'Primary',
	secondary: 'Secondary',
}

export const itemsSize = {
	small: 'Small',
	large: 'Large',
}

export const SignUpArticleReferenceType = {
	webinar: 'Webinar',
	download: 'Download',
}
