import { FormLayout, ImageField, InputField } from '@app/lib/form'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'

export const ReferenceForm = Component(() => (
	<FormLayout>
		<InputField field="order" label="Order" required />

		<ImageField
			label="Logo"
			baseField="logo"
			urlField="url"
			widthField="width"
			heightField="height"
			fileNameField="meta.fileName"
			fileTypeField="meta.fileType"
			fileSizeField="meta.fileSize"
			lastModifiedField="meta.lastModified"
		/>

		<LocaleSideDimension minWidth>
			<InputField field="title" label="Title" required />
			<InputField field="info" label="Info" required />
			<BlockEditorField field="perex.data" referencesField="perex.references" label="Perex" />
		</LocaleSideDimension>
	</FormLayout>
))
