import { HeroForm } from '@app/components/forms/hero-form'
import { pageBgType, pageType } from '@app/labels'
import { FormLayout, InputField, RadioEnumField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@app/lib/ui/collapsible'
import { Component, HasOne, If } from '@contember/interface'
import { ChevronsUpDownIcon } from 'lucide-react'
import { BlockEditor } from '../block-editor'
import { LocaleSideDimension } from '../locale-side-dimension'

export const PageForm = Component(() => (
	<>
		<FormLayout>
			<LocaleSideDimension>
				<InputField field="publishedAt" label="Published at" />
				<InputField field="archivedAt" label="Archived at" />
			</LocaleSideDimension>

			<RadioEnumField field="type" label="Type" defaultValue="contentPage" options={pageType} orientation="horizontal" />
			<RadioEnumField field="bgType" label="Background type" defaultValue="default" options={pageBgType} orientation="horizontal" />
		</FormLayout>

		<LocaleSideDimension>
			<SlugField label="Link" field="link.url" derivedFrom="title" persistedHardPrefix="/" />
		</LocaleSideDimension>

		<If condition="[type=blogPage]">
			<LocaleSideDimension>
				<InputField field="title" label="Title" />
			</LocaleSideDimension>
		</If>

		<If condition="[type!=blogPage]">
			<Collapsible defaultOpen={true}>
				<CollapsibleTrigger>
					<div className="flex items-center gap-1 hover:underline mt-0 mb-2 font-semibold">
						Hero banner
						<ChevronsUpDownIcon className="w-4" />
					</div>
				</CollapsibleTrigger>

				<CollapsibleContent className="border border-gray-100 mt-4 p-4 rounded">
					<>
						<If condition="[type=contactPage]">
							<HasOne field="hero">
								<HeroForm withSubtitle />
							</HasOne>
						</If>
						<If condition="[type=contentPage]">
							<LocaleSideDimension>
								<InputField field="title" label="Title" />
							</LocaleSideDimension>
							<HasOne field="hero">
								<HeroForm />
							</HasOne>
						</If>
						<If condition="[type=homepage]">
							<DefaultRepeater field="carouselItems" sortableBy="displayOrder">
								<HasOne field="hero">
									<HeroForm />
								</HasOne>
								<RepeaterItemActions>
									<RepeaterRemoveItemButton />
								</RepeaterItemActions>
							</DefaultRepeater>
						</If>
					</>
				</CollapsibleContent>
			</Collapsible>
		</If>

		<LocaleSideDimension minWidth>
			<If condition="[root.type!=blogPage]">
				<h4 className="text-3xl font-medium pt-16">Detail - block content</h4>
				<BlockEditor />
			</If>

			<SeoForm field="seo" />
		</LocaleSideDimension>
	</>
))
