import { AuthorForm } from '@app/components/forms/author-form'
import { CategoryForm } from '@app/components/forms/category-form'
import { TagForm } from '@app/components/forms/tag-form'
import { AuthorPreview } from '@app/components/previews/author-preview'
import { CategoryPreview } from '@app/components/previews/category-preview'
import { TagsPreview } from '@app/components/previews/tags-preview'
import { FormLayout, ImageField, InputField, MultiSelectField, RadioEnumField, SelectField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo/'
import { LocaleSlugField, SlugField } from '@app/lib/plugins/url'
import { Component, Field, If } from '@contember/interface'
import { PrimaryLocale } from '../primary-locale'
import { LocaleSideDimension } from '../locale-side-dimension'
import { RichText } from '../rich-text'
import { ContentEditor } from '../content-editor'
import { AlertCircle } from 'lucide-react'

export const ArticleForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<If condition="[publishedAt = null && archivedAt = null && link.url != null]">
				<div className="bg-blue-50 text-blue-500 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Draft
				</div>
			</If>
			<If condition="[publishedAt != null]">
				<div className="bg-lime-50 text-green-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Published
				</div>
			</If>
			<If condition="[archivedAt != null]">
				<div className="bg-amber-100 text-amber-600 py-1.5 px-3 text-sm rounded-md flex gap-2 w-fit items-center mb-4">
					<AlertCircle size={16} /> Archived
				</div>
			</If>
			<InputField field="publishedAt" label="Published at" />
			<InputField field="archivedAt" label="Archived at" />
		</LocaleSideDimension>
		<div className="grid grid-cols-2 gap-4">
			<ImageField
				label="Image"
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>{' '}
		</div>
		<LocaleSideDimension>
			<SelectField field="author" label="Author" createNewForm={<AuthorForm />}>
				<AuthorPreview />
			</SelectField>
		</LocaleSideDimension>
		<div className="bg-red-50">
			<span className="text-red-500">Do not use:</span>
			<SelectField field="author" label="Author" createNewForm={<AuthorForm />}>
				<AuthorPreview />
			</SelectField>
		</div>
		<div className="grid grid-cols-2 gap-4">
			<SelectField field="category" label="Category" createNewForm={<CategoryForm />}>
				<PrimaryLocale>
					<CategoryPreview />
				</PrimaryLocale>
			</SelectField>
			<MultiSelectField field="tags" label="Tags" createNewForm={<TagForm />}>
				<PrimaryLocale>
					<TagsPreview />
				</PrimaryLocale>
			</MultiSelectField>
		</div>
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
			<LocaleSlugField label="Link" field="link.url" derivedFrom="title" persistedHardPrefix="/" persistedSoftPrefix="blog/" />
			<RichText field="leadText" label="Lead text" />
			<ContentEditor
				label="Content"
				field="content.data"
				referencesField="content.references"
				enableArticleLocking={true}
				enableFileDownload={false}
			/>
			<SeoForm field="seo" />
		</LocaleSideDimension>
	</FormLayout>
))
