import { dict } from '@app/lib/dict'
import { ImageField as DefaultImageField, InputField, TextareaField } from '@app/lib/form'
import { Button } from '@app/lib/ui/button'
import { UploaderDropzoneAreaUI } from '@app/lib/upload'
import { Component, Field, HasOne, StaticRender, SugarableHasOneRelation } from '@contember/interface'
import { UploadIcon } from 'lucide-react'
import { SelectImage } from './select-image'

type ImageFieldProps = {
	label?: string
	baseField: string | SugarableHasOneRelation
}

export const ImageField = Component<ImageFieldProps>(({ label, baseField }) => {
	return (
		<>
			<DefaultImageField
				label={label}
				baseField={baseField}
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
				dropzonePlaceholder={
					<UploaderDropzoneAreaUI className="w-60">
						<UploadIcon className={'w-12 h-12 text-gray-400'} />
						<div className={'font-semibold text-sm'}>{dict.uploader.dropFiles}</div>
						<div className={'text-xs'}>{dict.uploader.or}</div>
						<div className={'flex gap-2 items-center text-xs'}>
							<Button size={'sm'} variant={'outline'}>
								{dict.uploader.browseFiles}
							</Button>
							<div onClick={e => e.stopPropagation()}>
								<SelectImage baseField={baseField} />
							</div>
						</div>
					</UploaderDropzoneAreaUI>
				}
				edit={<div className={'flex flex-col lg:flex-row gap-4'}>
					<InputField field="author" label="Author" />
					<InputField field="origin" label="Source" />
					<TextareaField field="description" label="Description" />
				</div>}
			/>
			<StaticRender>
				<HasOne field={baseField}>
					<Field field="author" />
					<Field field="origin" />
					<Field field="description" />
				</HasOne>
			</StaticRender>
		</>
	)
})
