'use client'

import gsap from 'gsap'
import { Flip, ScrollTrigger } from 'gsap/all'
import { type ReactNode, useEffect, useRef } from 'react'
import { Inner } from '../atoms'
import { cn } from '../utils/cn'

gsap.registerPlugin(Flip, ScrollTrigger)

export type SupportedTechnologiesProps = {
	animate?: boolean
	technologyLogos: Array<ReactNode>
	caption: {
		totalCount: ReactNode
		subtitle: ReactNode
		teaser: ReactNode
		cta?: ReactNode
	}
}

export const SupportedTechnologies = ({ technologyLogos, caption, animate = true }: SupportedTechnologiesProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (wrapperRef.current && animate) {
			const boxes = wrapperRef.current.querySelectorAll('.technology_gallery__item')
			const caption = wrapperRef.current.querySelector('.technology_gallery__caption')
			const toState = Flip.getState(boxes?.length ? [...Array.from(boxes), caption] : [caption], {
				props: 'filter, opacity',
			})

			wrapperRef.current.classList.add('finished')
			wrapperRef.current.classList.remove('finished')

			gsap.matchMedia().add('(min-width: 768px)', () => {
				Flip.to(toState, {
					ease: 'none',
					absoluteOnLeave: false,
					absolute: false,
					scale: false,
					simple: true,
					scrollTrigger: {
						trigger: wrapperRef.current,
						start: 'top 35%',
						end: '+=100%',
						pin: wrapperRef.current,
						scrub: true,
					},
					stagger: 0,
				})
			})
		}
	}, [animate])

	return (
		<section className="my-160 md:my-240">
			<Inner>
				<div
					className={cn('technolyOuterWrapper', 'bg-dark-1 py-120 md:py-137 px-40 xl:pl-230 md:pr-0 overflow-hidden', 'finished')}
					ref={wrapperRef}
				>
					<div className={cn('w-full flex flex-col md:flex-row justify-start items-center lg:h-[290px] relative')}>
						<div
							className={cn(
								'technology',
								'relative flex-none grid grid-cols-5 justify-start items-center gap-20 h-[240px] md:h-[290px] w-[180px] md:w-full',
								'technology_gallery',
							)}
						>
							{technologyLogos.slice(0, 5).map((logo, index) => (
								<div
									key={`supportedTechnologies_${index}`}
									className={cn(
										'technologyItem',
										'flex relative items-center justify-center px-40 rounded-[16px] overflow-hidden h-[240px] md:h-[290px] w-[180px] md:w-[222px]',
										`after:content-[''] after:absolute after:inset-2 after:rounded-[14px] after:bg-dark-3`,
										'technology_gallery__item',
									)}
								>
									{logo}
								</div>
							))}
						</div>
						<div
							className={cn(
								'technologyCaption',
								'relative md:absolute md:left-full md:pl-50 xl:pl-130 md:top-1/2 md:-translate-y-1/2 md:opacity-0 md:translate-x-[320px] max-w-[350px] lg:max-w-none text-center md:text-left mt-64 md:mt-0',
								'technology_gallery__caption',
							)}
						>
							<h6 className={cn('technologyCaptionHeadline', 'text-[109px] lg:text-[164px] font-semibold leading-[120%] relative text-transparent')}>
								{caption.totalCount}
							</h6>
							<h5 className="text-h3 text-white mt-16 mb-12">{caption.subtitle}</h5>
							<div className="text-odstavec-dark max-w-[440px] mb-32">{caption.teaser}</div>
							{caption.cta}
						</div>
					</div>
				</div>
			</Inner>
		</section>
	)
}
