import { ImageField, InputField, RadioEnumField, SelectField, SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasOne, If, StaticRender } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { PrimaryLocale } from '../primary-locale'
import { ProductPreview } from '../previews/product-preview'
import { blockVariant, itemsSize } from '@app/labels'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { RichText } from '../rich-text'
import { SolutionsPreview } from '../previews/solutions-preview'
import { TechnologiesPreview } from '../previews/technologies-preview'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'

export const CustomizedTilesForm = Component(() => (
	<>
		<BlockHeadline />
		<RadioEnumField field="itemsSize" orientation="horizontal" label="Items size" options={itemsSize} defaultValue="large" />
		<RadioEnumField field="variant" orientation="horizontal" label="Variant" options={blockVariant} defaultValue="dark" />
		<br />
		<HasOne field="customizedList">
			<DefaultRepeater field="items" sortableBy="displayOrder">
				<RadioEnumField
					field="type"
					label="Typ"
					options={{
						product: 'Product',
						solution: 'Solution',
						technology: 'Technology',
						text: 'Text',
					}}
					orientation="horizontal"
				/>
				<If condition="[type=product]">
					<SelectField field="product" label="Product">
						<PrimaryLocale>
							<ProductPreview />
						</PrimaryLocale>
					</SelectField>
				</If>
				<If condition="[type=solution]">
					<SelectField field="solution" label="Solution">
						<PrimaryLocale>
							<SolutionsPreview />
						</PrimaryLocale>
					</SelectField>
				</If>
				<If condition="[type=technology]">
					<SelectField field="technology" label="Technology">
						<PrimaryLocale>
							<TechnologiesPreview />
						</PrimaryLocale>
					</SelectField>
				</If>
				<If condition="[type=text]">
					<HasOne field="item">
						<ImageField
							label="Icon"
							baseField="image"
							urlField="url"
							widthField="width"
							heightField="height"
							fileNameField="meta.fileName"
							fileTypeField="meta.fileType"
							fileSizeField="meta.fileSize"
							lastModifiedField="meta.lastModified"
						/>
						<InputField field="title" label="Title" />
						<RichText field="text" label="Text" />
						<SelectLinkField field="link" label="Link" />
					</HasOne>
				</If>
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
		</HasOne>

		{/* <SortableMultiSelectField
			field="products"
			label="Products"
			description="If all products/solutions/technologies and extra items are empty, all products will be shown"
			sortableBy="displayOrder"
			connectAt="product"
		>
			<StaticRender>
				<Field field="icon.url" />
			</StaticRender>
			<PrimaryLocale>
				<StaticRender>
					<Field field="leadText" />
				</StaticRender>
				<ProductPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<br />
		<SortableMultiSelectField field="solutions" label="Solutions" sortableBy="displayOrder" connectAt="solution">
			<PrimaryLocale>
				<SolutionsPreview />
			</PrimaryLocale>
		</SortableMultiSelectField>
		<br />
		<SortableMultiSelectField field="technologies" label="Technologies" connectAt="technology" sortableBy="displayOrder">
			<PrimaryLocale>
				<TechnologiesPreview />
			</PrimaryLocale>
			<StaticRender>
				<HasOne field="image">
					<Field field="url" />
					<Field field="alt" />
				</HasOne>
			</StaticRender>
		</SortableMultiSelectField>
		<br />
		Extra custom items without link:
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Extra items">
			<ImageField
				label="Icon"
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<InputField field="title" label="Title" />
			<RichText field="text" label="Text" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater> */}
	</>
))
