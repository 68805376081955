import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

type TagProps = {
	children: ReactNode
	mode: 'light' | 'dark'
}

export const Tag = ({ children, mode }: TagProps) => {
	return (
		<span
			className={cn('inline-flex items-center px-12 min-h-25  uppercase text-xs rounded-[120px] font-semibold', {
				'bbg-outlines-light border border-outlines-light text-cta-default': mode === 'light',
				'bg-cta-default/20  border-0 text-cta-default': mode === 'dark',
			})}
		>
			{children}
		</span>
	)
}
