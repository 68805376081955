import { CopyColumnArgs, CopyHandler, CopyHasManyRelationArgs } from './entity-copier'
import { generateUuid, PRIMARY_KEY_NAME } from '@contember/interface'

export class ContentCopyHandler implements CopyHandler {
	constructor(private blockEntity: string, private data: string, private referencesField: string) {
	}

	copyColumn({ source, marker }: CopyColumnArgs): boolean {
		return source.name === this.blockEntity && marker.fieldName === this.data
	}

	copyHasManyRelation({ source, target, copier, marker }: CopyHasManyRelationArgs): boolean {
		if (source.name !== this.blockEntity || marker.parameters.field !== this.referencesField) {
			return false
		}
		const list = source.getEntityList(marker.parameters)
		const targetList = target.getEntityList(marker.parameters)

		targetList.disconnectAll()
		const referenceMapping = new Map<string, string>()
		for (const subEntity of list) {
			const newId = generateUuid()
			referenceMapping.set(subEntity.id as string, newId)
			targetList.createNewEntity(target => {
				copier.copy(subEntity, target())
				target().getField(PRIMARY_KEY_NAME).updateValue(newId)
			})
		}
		const jsonValue = source.getField(this.data).value
		if (typeof jsonValue !== 'object' || jsonValue === null) {
			return true
		}
		const traverse = (value: any): any => {
			if (Array.isArray(value)) {
				return value.map(traverse)
			}
			if (typeof value === 'object' && value !== null) {
				const result: Record<string, any> = {}
				for (const key in value) {
					if (key === 'referenceId' && typeof value[key] === 'string') {
						result[key] = referenceMapping.get(value[key])
					} else {
						result[key] = traverse(value[key])
					}
				}
				return result
			}
			return value
		}

		target.getField(this.data).updateValue(traverse(jsonValue))

		return true
	}
}
