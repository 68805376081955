import { SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasMany, HasOne, StaticRender } from '@contember/interface'
import { ArticlesPreview } from '../previews/articles-preview'
import { PrimaryLocale } from '../primary-locale'
import { BlockHeadline } from './blockHeadline'

export const BlogTeaserForm = Component((_props, env) => {
	const locales = env.getDimensionOrElse('locale', [])

	if (locales.length === 0) {
		return null
	}

	return (
		<>
			<BlockHeadline canHaveButton={true} />
			<SortableMultiSelectField field="articles" label="Articles" connectAt="article" sortableBy="displayOrder" options={`Article[locales.locale.code = '${locales[0]}']`}>
				<StaticRender>
					<HasOne field="image">
						<Field field="url" />
					</HasOne>
					<HasOne field="author">
						<Field field="name" />
					</HasOne>
					<HasMany field="tags">
						<PrimaryLocale>
							<Field field="name" />
						</PrimaryLocale>
					</HasMany>
					<Field field="publishedAt" />
					<PrimaryLocale>
						<Field field="leadText" />
					</PrimaryLocale>
				</StaticRender>
				<PrimaryLocale>
					<ArticlesPreview />
				</PrimaryLocale>
			</SortableMultiSelectField>
		</>
	)
})
