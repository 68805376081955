import { Component, Field, HasOne, If } from '@contember/interface'
import { InputField, SelectEnumField, SelectField } from '@app/lib/form'

export const SelectLinkField = Component<{ field: string; label?: string; localeHidden?: boolean }>(({ field, label, localeHidden = false }, env) => {
	const locales = env.getDimensionOrElse('locale', [])

	if (locales.length === 0) {
		return null
	}

	const locale = locales[0]

	return (
		<HasOne field={field}>
			<div className="flex flex-col gap-4">
				{label && <div className="">{label}</div>}
				<SelectEnumField label="Type" field="type" options={{ external: 'External', internal: 'Internal' }} />
				<If condition="[type = external]">
					<InputField field="externalTarget" label="URL" required />
				</If>
				<If condition="[type = internal]">
					<SelectField
						field="internalTarget"
						label="Target"
						initialSorting={{ url: 'asc' }}
						options={`Url[(articleLink.locale.code != null && articleLink.locale.code = '${locale}') || 
						(pageLink.locale.code != null && pageLink.locale.code = '${locale}') ||
						(productLink.locale.code != null && productLink.locale.code = '${locale}') ||
						(technologyLink.locale.code != null && technologyLink.locale.code = '${locale}') || 
						(solutionLink.locale.code != null && solutionLink.locale.code = '${locale}') || 
						(partnerLink.locale.code != null && partnerLink.locale.code = '${locale}') || 
						(caseStudyLink.locale.code != null && caseStudyLink.locale.code = '${locale}') || 
						(categoryLink.locale.code != null && categoryLink.locale.code = '${locale}')]`}
						
					>
						<Field field="url" />
						{!localeHidden && <div className="bg-slate-100 py-1 border-1 px-2 rounded-xl">
							<Field field="articleLink.locale.code" />
							<Field field="pageLink.locale.code" />
							<Field field="productLink.locale.code" />
							<Field field="technologyLink.locale.code" />
							<Field field="solutionLink.locale.code" />
							<Field field="partnerLink.locale.code" />
							<Field field="caseStudyLink.locale.code" />
							<Field field="categoryLink.locale.code" /></div>}
					</SelectField>
				</If>
			</div>
		</HasOne>
	)
})
