import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { SlugField } from '@app/lib/plugins/url'

export const CategoryForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField field="name" label="Name" required />
			<SlugField label="Link" field="link.url" derivedFrom="name" persistedHardPrefix="/" />
			<InputField field="description" label="Description" required />
		</LocaleSideDimension>
	</FormLayout>
))
