import { InputField } from '@app/lib/form'
import { DefaultRepeater } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'
import { ImageField } from '../image-field'

export const SchemaForm = Component(() => (
	<>
		<BlockHeadline />
		<h4 className="mt-8 mb-4">Info boxes</h4>
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<InputField field="title" label="Title" />
			<RichText field="text" label="Lead text" />
		</DefaultRepeater>
		<ImageField label="Schema image" baseField="image" />
	</>
))
