import { InputField, SelectEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'
import { blockVariant } from '@app/labels'
import { ImageField } from '../image-field'

export const AdvantageTiles = Component(() => (
	<>
		<SelectEnumField field="variant" label="Variant" options={blockVariant} />
		<BlockHeadline />

		<DefaultRepeater field="items" sortableBy="displayOrder" title="Items">
			<ImageField label="Icon" baseField="image" />
			<InputField field="title" label="Title" />
			<RichText field="text" label="Description" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
