import { InputField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { BlockButton } from './blockButton'
import { RichText } from '../rich-text'

export const ContactBannerForm = Component(() => (
	<>
		<InputField field="primaryText" label="Headline" />
		<RichText field="secondaryText" label="Content" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
