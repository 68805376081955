import { cn } from '../utils/cn'
import { Headline, type HeadlineLevel } from './Headline'
import type { ReactNode } from 'react'

type BlockSectionHeaderProps = {
	level?: HeadlineLevel
	headline: ReactNode
	button?: ReactNode
	wrapperClassName?: string
	headlineClassName?: string
}

const SECTION_HEADLINE_COMMON_CLASSES = 'text-h3 md:text-h1 w-full max-w-[690px]'

export const BlockSectionHeader = ({ level = 'h2', headline, button, wrapperClassName, headlineClassName }: BlockSectionHeaderProps) => {
	return (
		<div className={cn('flex justify-between items-end mb-40 md:mb-64', wrapperClassName)}>
			<Headline className={cn(SECTION_HEADLINE_COMMON_CLASSES, headlineClassName)} level={level}>
				{headline}
			</Headline>
			{button ? <div className="hidden md:block">{button}</div> : null}
		</div>
	)
}
