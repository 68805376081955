import { DataGridLocaleTextColumn } from '@app/components/atoms/data-grid-locale-text-column'
import { CreateEntityModalButton } from '@app/components/createEntityModalButton'
import { TagForm } from '@app/components/forms/tag-form'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { Link, EntitySubTree } from '@contember/interface'
import { TrashIcon } from 'lucide-react'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Tags</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<CreateEntityModalButton entityName="Tag" buttonLabel="Create">
								<TagForm />
							</CreateEntityModalButton>
						</Slots.Actions>
						<DataGrid entities="Tag">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridLocaleTextColumn field="name" header="Title" />
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<Link to="tags/edit(id: $entity.id)">
												<Button variant="secondary" size="sm">
													Edit
												</Button>
											</Link>
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}

export const Edit = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Tag edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Tag(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<TagForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}