import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'

export const LocaleForm = Component(() => (
	<FormLayout>
		<div className="lg:flex gap-4">
			<InputField field="code" label="Code" required />
			<InputField field="label" label="Label" required />
		</div>
	</FormLayout>
))
