import { PagePreview } from '@app/components/previews/page-preview'
import { SortableMultiSelectField } from '@app/lib/form'
import { Component, Field, HasOne, StaticRender } from '@contember/interface'
import { PrimaryLocale } from '../primary-locale'

export const NavigationProductTilesForm = Component(() => (
	<SortableMultiSelectField field="pages" label="Pages navigation" sortableBy="displayOrder" connectAt="page">
		<PrimaryLocale>
			<PagePreview />
		</PrimaryLocale>
		<StaticRender>
			<HasOne field="hero">
				<PrimaryLocale>
					<Field field="title" />
					<Field field="description" />
				</PrimaryLocale>
			</HasOne>
		</StaticRender>
	</SortableMultiSelectField>
))
