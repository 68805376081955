import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'

export const TagForm = Component(() => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField field="name" label="Name" required />
		</LocaleSideDimension>
	</FormLayout>
))
