import { ImageField } from '@app/components/image-field'
import { InputField, TextareaField } from '@app/lib/form'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@app/lib/ui/collapsible'
import { Component, HasOne } from '@contember/interface'
import { ChevronsUpDownIcon } from 'lucide-react'

interface SeoForm {
	field: string
}

export const SeoForm = Component<SeoForm>(({ field }) => (
	<Collapsible className="mt-10 border border-green-600 p-4 rounded">
		<CollapsibleTrigger>
			<div className="flex items-center gap-1 hover:underline mt-0 mb-2 font-semibold">
				SEO setting
				<ChevronsUpDownIcon className="w-4" />
			</div>
		</CollapsibleTrigger>
		<CollapsibleContent className="border border-gray-100 mt-4 p-4 rounded">
			<HasOne field={field}>
				<InputField field="title" label="Title" />
				<TextareaField field="description" label="Description" />
				<InputField field="keywords" label="Keywords" />
				<ImageField baseField="image" />
			</HasOne>
		</CollapsibleContent>
	</Collapsible>
))
