import type { ReactNode } from 'react'
import { Inner } from '../atoms'

export type ContactBlockProps = {
	title: ReactNode
	items: Array<{
		title?: ReactNode
		text: ReactNode
		cta?: ReactNode
	}>
}

export const ContactBlock = ({ title, items }: ContactBlockProps) => {
	return (
		<section className="mt-12 pb-32">
			<Inner>
				<div className="flex flex-col max-w-[540px] border-outlines-dark/10 rounded border py-40 px-32">
					<h2 className="text-odstavec-dark text-5xl font-semibold pb-16">{title}</h2>

					<div>
						{items.map((item, index) => (
							<div key={`block_${index}`} className="flex flex-col py-12">
								{item.title && <h3 className="text-odstavec-dark">{item.title}</h3>}
								<div className="text-body font-semibold text-white">{item.text}</div>
								<div className="text-cta">{item.cta}</div>
							</div>
						))}
					</div>
				</div>
			</Inner>
		</section>
	)
}
