import { NavigationItemForm } from '@app/components/forms/navigation-item-form'
import { FormLayout } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { NavigationMenuForm } from './navigation-menu-form'

export const NavigationNewForm = Component(() => (
	<FormLayout>
		{/* <ImageField
			label="Logo"
			baseField="logo"
			urlField="url"
			widthField="width"
			heightField="height"
			fileNameField="meta.fileName"
			fileTypeField="meta.fileType"
			fileSizeField="meta.fileSize"
			lastModifiedField="meta.lastModified"
		/> */}
		<LocaleSideDimension>
			<DefaultRepeater field="items" title="Menu" sortableBy="order">
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
				<NavigationMenuForm over="items" />
			</DefaultRepeater>
		</LocaleSideDimension>
	</FormLayout>
))
