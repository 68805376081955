import { FormLayout, InputField, ImageField } from '@app/lib/form'
import { SeoForm } from '@app/lib/plugins/seo/'
import { SlugField } from '@app/lib/plugins/url'
import { Component } from '@contember/interface'
import { BlockEditor } from '../block-editor'
import { RichText } from '../rich-text'
import { LocaleSideDimension } from '../locale-side-dimension'

export const PartnerForm = Component(() => (
	<FormLayout>
		<ImageField
			label="Image"
			baseField="logo"
			urlField="url"
			widthField="width"
			heightField="height"
			fileNameField="meta.fileName"
			fileTypeField="meta.fileType"
			fileSizeField="meta.fileSize"
			lastModifiedField="meta.lastModified"
		/>

		<LocaleSideDimension minWidth>
			<InputField field="title" label="Title" required />
			<RichText field="leadText" label="Quote" />
			<SlugField label="Link" field="link.url" derivedFrom="title" persistedHardPrefix="/" persistedSoftPrefix="partners/" />
			<BlockEditor />
			<SeoForm field="seo" />
		</LocaleSideDimension>
	</FormLayout>
))
