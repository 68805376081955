'use client'
import { type ReactNode } from 'react'
import type { HeadlineLevel } from '../atoms'
import { AnimatedNumber, Inner } from '../atoms'

export type DynamicNumbersProps = {
	headline: {
		children: ReactNode
		level?: HeadlineLevel
	}
	leadText: ReactNode
	items: Array<{
		title: string
		text: ReactNode
	}>
	noAnimation?: boolean
}

export const DynamicNumbers = (props: DynamicNumbersProps) => {
	const { headline, leadText, items, noAnimation = false } = props

	return (
		<section className="bg-pozadiBoxu relative py-80 md:pb-[18rem] overflow-hidden">
			<Inner className="text-center">
				<div className="linear-gradient(80deg, #F2F7F7 0%, rgba(242, 247, 247, 0) 100%) relative z-10">
					<div className="text-h3 md:text-h2 max-w-[725px] mt-60 mx-auto">{headline.children}</div>
				</div>
				<div className="mt-40 mb-80 max-w-[615px] mx-auto">{leadText}</div>
				<div className="grid grid-cols-3 gap-16 md:gap-32 max-w-[664px] mx-auto relative z-10">
					{items.map((item, index) => (
						<div
							key={index}
							className="bg-[url('/global_network_box_bg.png')] rounded bg-no-repeat bg-cover flex flex-col py-20 md:py-38 px-10 md:px-15 items-center justify-center"
						>
							<div className="text-[40px] md:text-[80px] leading-none font-semibold text-white">
								<AnimatedNumber finalValue={Number(item.title)} />
							</div>
							<span className="text-[10px] leading-none sm:text-p14 md:text-lg text-odstavec-dark">{item.text}</span>
						</div>
					))}
				</div>
			</Inner>
		</section>
	)
}
