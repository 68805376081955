import type { ReactNode } from 'react'
import { cn } from '../utils/cn'
import { Tag } from './Tag'

type TagListProps = {
	tags: ReactNode[]
	className?: string
	mode?: 'light' | 'dark'
}

export const TagList = ({ tags, className, mode = 'light' }: TagListProps) => {
	return (
		<ul className={cn(['flex flex-wrap gap-10 gap-y-4 my-16', className])}>
			{tags.map((tag, index) => (
				<li key={index}>
					<Tag mode={mode}>{tag}</Tag>
				</li>
			))}
		</ul>
	)
}
