import { ReferenceForm } from '@app/components/forms/reference-form'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridNumberColumn,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'
import { Button } from '@app/lib/ui/button'
import { EntitySubTree, Field, Link, RedirectOnPersist } from '@contember/interface'
import { TrashIcon } from 'lucide-react'

export const List = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Reference list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="reference/create">
								<Button>Create reference</Button>
							</Link>
						</Slots.Actions>
						<DataGrid entities="Reference">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn>
										<div className="flex gap-4">
											<Link to="reference/edit(id: $entity.id)">
												<Button variant="outline">Edit</Button>
											</Link>
										</div>
									</DataGridColumn>
									<DataGridNumberColumn field="order" header="Order" />
									<DataGridHasOneColumn field="logo" header="Logo">
										<Field field="id" />
									</DataGridHasOneColumn>
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}

export const Create = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Reference create</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Reference" isCreating>
						<RedirectOnPersist to="reference/edit(id: $entity.id)" />
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<ReferenceForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}

export const Edit = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Reference edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Reference(id=$id)" isCreating={false}>
						<Slots.Actions>
							<PersistButton />
						</Slots.Actions>
						<ReferenceForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
