import { ImageField, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { RichText } from '../rich-text'

export const BlogTeaserExternalForm = Component(() => (
	<>
		<BlockHeadline />
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<ImageField
				label="Image"
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<InputField field="title" label="Title" />
			<RichText field="text" label="Text" />
			<InputField field="topIdentifier" label="Author" />
			<InputField field="date" label="Published at" />
			<SelectLinkField field="link" label="Link" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>

	// <SortableMultiSelectField field="articles" label="Articles" connectAt="article" sortableBy="displayOrder">
	// 	<StaticRender>
	// 		<HasOne field="image">
	// 			<Field field="url" />
	// 		</HasOne>
	// 		<HasOne field="author">
	// 			<Field field="name" />
	// 		</HasOne>
	// 		<HasMany field="tags">
	// 			<PrimaryLocale>
	// 				<Field field="name" />
	// 			</PrimaryLocale>
	// 		</HasMany>
	// 		<Field field="publishedAt" />
	// 		<PrimaryLocale>
	// 			<Field field="leadText" />
	// 		</PrimaryLocale>
	// 	</StaticRender>
	// 	<PrimaryLocale>
	// 		<ArticlesPreview />
	// 	</PrimaryLocale>
	// </SortableMultiSelectField>
))
