import { FormLayout, InputField, ImageField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'
import { RichText } from '../rich-text'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'

export const ConfigurationForm = Component(() => (
	<FormLayout>
		<ImageField
			label="Gradient Icon"
			baseField="icon"
			urlField="url"
			widthField="width"
			heightField="height"
			fileNameField="meta.fileName"
			fileTypeField="meta.fileType"
			fileSizeField="meta.fileSize"
			lastModifiedField="meta.lastModified"
		/>
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
			<RichText field="description" label="Lead text" />
			<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
			<InputField field="price" label="Price" required />
			<InputField field="buttonLabel" label="Button title" />
			<SelectLinkField field="link" label="Link" />
		</LocaleSideDimension>
	</FormLayout>
))
