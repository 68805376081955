'use client'

type PaginationProps = {
	totalItems: number
	loadMore: string
	currentLimit: number
	onLoadMore: (itemLimit: number) => void
	itemsSpan: number
}

export const Pagination = ({ totalItems, loadMore, onLoadMore, currentLimit, itemsSpan = 9 }: PaginationProps) => (
	<div className="text-center mt-60 md:mt-80">
		{totalItems > 0 && currentLimit < totalItems && (
			<button className="mt-32 btn btn--primary" type="button" onClick={() => onLoadMore(currentLimit + itemsSpan)}>
				{loadMore}
			</button>
		)}
	</div>
)

