import type { ReactNode } from 'react'
import { BlockConnection, Inner } from '../atoms'
import { InNumbersItem } from '../loop'
import { cn } from '../utils/cn'

export type CompanyInNumbersProps = {
	headline: ReactNode
	teaser?: ReactNode
	items: Array<{
		icon: ReactNode
		title: ReactNode
		teaser: ReactNode
	}>
	gradientBg?: boolean
	footerText?: ReactNode
	cta?: ReactNode
	separatorPosition?: 'top' | 'bottom' | false
	context?: 'default' | 'article'
}

export const CompanyInNumbers = ({
	headline,
	teaser,
	items,
	gradientBg,
	footerText,
	cta,
	separatorPosition = false,
	context = 'default',
}: CompanyInNumbersProps) => {
	return (
		<section
			className={cn('my-0 bg-dark-1 pt-80 md:pt-240 pb-160 md:pb-196 relative', {
				'bg-[url("/company_in_numbers_gradient_bg.png")] bg-no-repeat bg-center_top bg-cover': !!gradientBg,
				'mt-120': separatorPosition === 'top',
				'py-80 md:py-120 mb-80': context === 'article',
				'mb-80 md:mb-120': context === 'article' && separatorPosition === 'bottom',
			})}
		>
			<div className="absolute right-0 bottom-75 w-[560px]">
				<img src="/bg_tylda.png" width="1120" height="668" alt="" loading="lazy" />
			</div>
			<Inner className="text-center">
				<div className="text-h4 md:text-h2 text-white mb-16 flex items-end justify-center gap-8 md:gap-17 [&>img]:w-[120px] [&>img]:md:w-[250px]">
					{headline}
				</div>
				<div className="text-odstavec-dark mx-auto max-w-[610px] mb-64">{teaser}</div>
				<div className="grid grid-cols-2 lg:grid-cols-4 gap-15 md:gap-32 relative z-10">
					{items.map((item, index) => (
						<InNumbersItem key={index} {...item} />
					))}
				</div>
				{footerText && <div className="text-white text-h4 mt-80 mb-24 relative z-10">{footerText}</div>}
				{cta}
			</Inner>
			{separatorPosition !== false && <BlockConnection position={separatorPosition} fillColor="#1B2128" />}
		</section>
	)
}
