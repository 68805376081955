import { Component, EntityListSubTree, Field, useEntityListSubTree, useEnvironment, useField } from '@contember/react-binding'
import { LocaleSideDimension } from './locale-side-dimension'
import { Button } from '@app/lib/ui/button'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { DeleteEntityDialog } from '@app/lib/binding'

type PageType = 'article' | 'page' | 'product' | 'technology' | 'solution' | 'partner' | 'caseStudy'

type DeleteButtonInnerProps = {
	id: string
	domains: Array<{
		domain: string
		localeCode: string
	}>
}

const DeleteButtonInner = (props: DeleteButtonInnerProps) => {
	const { domains } = props

	const env = useEnvironment()
	const locale = env.getVariable('currentLocaleCode')

	const domain = domains.find(d => d.localeCode === locale)?.domain

	if (!domain) {
		return null
	}

	return (
		<DeleteEntityDialog
			trigger={
				<Button variant="destructive" size="sm">
					Delete ({locale?.toString()})
				</Button>
			}
		/>
	)
}

export const DeleteEntityButton = Component(
	props => {
		const domainsLocale = Array.from(useEntityListSubTree('domains')).map(e => {
			const domain = e.getField<string>('domain').value!
			const localeCode = e.getField<string>('locale.code').value!
			return { domain, localeCode }
		})

		const id = useField<string>('id').value!

		return (
			<LocaleSideDimension className="mt-0">
				<DeleteButtonInner id={id} domains={domainsLocale} />
			</LocaleSideDimension>
		)
	},
	() => {
		return (
			<>
				<Field field="id" />
				<EntityListSubTree entities="DomainLocale" alias="domains">
					<Field field="domain" />
					<Field field="locale.code" />
				</EntityListSubTree>
			</>
		)
	},
)

export const ToggleButton = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => (
	<button onClick={onClick} className="flex items-center gap-2 text-sm text-gray-900 hover:text-gray-800 p-2 px-3">
		{isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
		{isOpen ? 'Hide delete buttons' : 'Delete buttons'}
	</button>
)
