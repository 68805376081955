import { ContentFragmentType, JSONValue } from '@website/api'
import type { ReactNode } from 'react'
import { LinkComponent, NextLinkComponent } from '../types'
import { renderElement, renderLeaf } from './renderers'
import type { LeafRenderer, RichTextElement } from './types'

export type RenderRichTextProps = {
	renderLeaf: LeafRenderer
	renderElement: (element: RichTextElement, leafRenderer: LeafRenderer) => ReactNode
}

export const renderRichText = ({
	value,
	linkComponent,
	nextLinkComponent,
	getTranslation,
	references,
}: {
	value: JSONValue | string
	linkComponent: LinkComponent
	nextLinkComponent: NextLinkComponent
	getTranslation: (key: string) => string
	references?: ContentFragmentType['references']
}) => {
	let parsed: any
	try {
		parsed = typeof value === 'string' ? JSON.parse(value) : value
	} catch (_e) {
		return value
	}

	if (value && Array.isArray(parsed.children)) {
		return parsed.children.map((element: RichTextElement) =>
			renderElement({
				element,
				renderLeaf,
				linkComponent,
				nextLinkComponent,
				getTranslation,
				references,
			}),
		)
	}

	if (typeof parsed === 'number') {
		return value
	}

	return null
}

export const RichTextRenderer = ({
	value,
	linkComponent,
	getTranslation,
	nextLinkComponent,
}: {
	value?: JSONValue | string
	linkComponent: LinkComponent
	nextLinkComponent: NextLinkComponent
	getTranslation: (key: string) => string
}) => {
	if (!value) return null

	return <>{renderRichText({ value, linkComponent, getTranslation, nextLinkComponent })}</>
}

type ContentRendererProps = {
	content: ContentFragmentType
	linkComponent: LinkComponent
	getTranslation: (key: string) => string
	nextLinkComponent: NextLinkComponent
}
export const ContentRenderer = ({ content, nextLinkComponent, getTranslation, linkComponent }: ContentRendererProps) => {
	return <>{renderRichText({ value: content.data, linkComponent, nextLinkComponent, getTranslation, references: content.references })}</>
}
