import { DataGridLocaleTextColumn } from '@app/components/atoms/data-grid-locale-text-column'
import { SolutionForm } from '@app/components/forms/solution-form'
import { PreviewButton } from '@app/components/previewButton'
import { Binding, DeleteEntityDialog, PersistButton } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridColumn,
	DataGridHasOneColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { ImageField } from '@app/lib/form'
import { Slots } from '@app/lib/layout'
import { AnchorButton, Button } from '@app/lib/ui/button'
import { EntitySubTree, Link, RedirectOnPersist } from '@contember/interface'
import { TrashIcon } from 'lucide-react'
import { CopyEntity, CopyPopover } from '@app/components/copier'
import { ToggleButton, DeleteEntityButton } from '@app/components/deleteEntityButton'
import { useState } from 'react'

export const List = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Solution list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<Slots.Actions>
							<Link to="solution/create">
								<Button>Create solution</Button>
							</Link>
						</Slots.Actions>
						<DataGrid entities="Solution">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridColumn>
										<div className="flex gap-4">
											<Link to="solution/edit(id: $entity.id)">
												<Button variant="outline">Edit</Button>
											</Link>
										</div>
									</DataGridColumn>
									<DataGridHasOneColumn field="icon" header="Icon">
										<ImageField urlField="url" small />
									</DataGridHasOneColumn>
									<DataGridLocaleTextColumn field="name" header="Name" />
									<DataGridLocaleTextColumn field="link.url" header="URL" />
									<DataGridColumn>
										<div className="flex justify-end items-center gap-4">
											<DeleteEntityDialog
												trigger={
													<Button variant="destructive" size="sm">
														<TrashIcon className="w-3 h-3" />
													</Button>
												}
											/>
										</div>
									</DataGridColumn>
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}

export const Create = () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Solution create</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Solution" isCreating>
						<RedirectOnPersist to="solution/edit(id: $entity.id)" />
						<Slots.Actions>
							<CopyPopover />
							<PersistButton />
						</Slots.Actions>
						<SolutionForm />
					</EntitySubTree>
					<CopyEntity entityName="Solution">
						<SolutionForm />
					</CopyEntity>
				</div>
			</Binding>
		</>
	)
}

export const Edit = () => {
	const [showDeleteButton, setShowDeleteButton] = useState(false)

	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Solution edit</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<EntitySubTree entity="Solution(id=$id)" isCreating={false}>
						<Slots.Actions>
							<div className="flex items-start gap-4">
								<CopyPopover>
									<Link to="solution/create(copy: $entity.id)">
										<AnchorButton variant="secondary">Duplicate</AnchorButton>
									</Link>
								</CopyPopover>
								<PersistButton />
								<div className="flex flex-col items-end">
									<PreviewButton pageType="solution" />
									<ToggleButton isOpen={showDeleteButton} onClick={() => setShowDeleteButton(!showDeleteButton)} />
									{showDeleteButton && <DeleteEntityButton />}
								</div>
							</div>
						</Slots.Actions>
						<SolutionForm />
					</EntitySubTree>
				</div>
			</Binding>
		</>
	)
}
