import { CheckboxField, FormLayout, InputField, RadioEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { LocaleSideDimension } from '../locale-side-dimension'
import { navigationItemType } from '@app/labels'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible'
import { ChevronsUpDownIcon } from 'lucide-react'

export interface NavigationItemFormProps {
	enableChildren?: boolean
	over?: 'parent' | 'children' | 'items' | 'parentItem' | 'subNavigation' | 'navigationItemLink' | (string & {})
}

export const NavigationItemForm = Component(({ over, enableChildren = true }: NavigationItemFormProps) => (
	<FormLayout>
		<LocaleSideDimension>
			<InputField field="title" label="Title" required />
			<SelectLinkField field="link" label="Link" />
			<RadioEnumField
				field="type"
				label="Type"
				options={navigationItemType}
				orientation="horizontal"
				inputProps={{ className: 'ignoreLocale' }}
				defaultValue="link"
			/>
		</LocaleSideDimension>
		<CheckboxField field="isExtended" defaultValue={false} label="Is extended menu (two sides)" />
		{over !== 'parent' && enableChildren && (
			<Collapsible>
				<CollapsibleTrigger>
					<div className="flex items-center gap-1 hover:underline mt-0 mb-2 font-semibold">
						<span>Submenu items</span>
						<ChevronsUpDownIcon className="w-4" />
					</div>
				</CollapsibleTrigger>
				<CollapsibleContent>
					<DefaultRepeater field="children" title="Children" sortableBy="order" initialEntityCount={0}>
						<RepeaterItemActions>
							<RepeaterRemoveItemButton />
						</RepeaterItemActions>
						<NavigationItemForm over="parent" />
					</DefaultRepeater>
				</CollapsibleContent>
			</Collapsible>
		)}
	</FormLayout>
))
