import { BlockRepeater } from '@app/lib/plugins/blocks'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { cn } from '@app/lib/utils'
import { entityToJson } from '@app/utils/entity-to-json'
import { Component, EntityView } from '@contember/interface'
import { Block } from '@contember/react-block-repeater'
import { BlockFragmentType, CaseStudyFragmentType, LinkFragmentType, signUpArticleType } from '@website/api'
import {
	AdvantagesList,
	type AdvantagesListProps,
	ArticleSignUpBlock,
	ArticleSignUpBlockProps,
	BlogTeaser,
	type BlogTeaserProps,
	CaseStudies,
	type CaseStudiesProps,
	CompanyBoard,
	type CompanyBoardProps,
	CompanyInNumbers,
	type CompanyInNumbersProps,
	ConfigurationOverview,
	type ConfigurationOverviewProps,
	ContactBlock,
	ContactBlockProps,
	ContactUs,
	type ContactUsProps,
	ContactUsStripe,
	type ContactUsStripeProps,
	ContentRenderer,
	DiscoverMoreProducts,
	type DiscoverMoreProductsProps,
	DockWithImage,
	type DockWithImageProps,
	GlobalNetwork,
	type GlobalNetworkProps,
	DynamicNumbers,
	type DynamicNumbersProps,
	Headline,
	Icon,
	NavigationTiles,
	NavigationTilesProps,
	OurClients,
	type OurClientsProps,
	OurTeams,
	type OurTeamsProps,
	PagesNavigation,
	type PagesNavigationProps,
	PartnersNavigation,
	type PartnersNavigationProps,
	ProductAdvantages,
	type ProductAdvantagesProps,
	Quote,
	type QuoteProps,
	RichTextRenderer,
	Scheme,
	type SchemeProps,
	ServiceIncludes,
	type ServiceIncludesProps,
	SupportedTechnologies,
	type SupportedTechnologiesProps,
	Technologies,
	TechnologiesProps,
	Testimonials,
	type TestimonialsProps,
	TestimonialsWithCaseStudies,
	TestimonialsWithCaseStudiesProps,
	Timeline,
	type TimelineProps,
	WebinarInfo,
	WebinarInfoProps,
} from '@website/common'
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { AdvantageTiles } from './blocks/advantageTiles'
import { BlogArticlesForm } from './blocks/blogArticlesForm'
import { BlogTeaserForm } from './blocks/blogTeaser'
import { CaseStudiesAndLogosForm } from './blocks/caseStudiesAndLogos'
import { CaseStudiesForm } from './blocks/caseStudiesForm'
import { CompanyInNumbersForm } from './blocks/companyInNumbers'
import { ConfigurationsForm } from './blocks/configurationsForm'
import { ContactBannerForm } from './blocks/contactBannerForm'
import { ContactBarForm } from './blocks/contactBarForm'
import { ContactBlockForm } from './blocks/contactBlockForm'
import { DockWithImageForm } from './blocks/dockWithImageForm'
import { LogosForm } from './blocks/logos'
import { NavigationProductTilesForm } from './blocks/navigationProductTilesForm'
import { NetworkAnimationForm } from './blocks/networkAnimation'
import { NumberedListForm } from './blocks/numberedListForm'
import { NumberedListWithTilesForm } from './blocks/numberedListWithTilesForm'
import { OurTeamsForm } from './blocks/ourTeamsForm'
import { ArticleSignUp } from './blocks/articleSignUp'
import { PagesNavigationForm } from './blocks/pagesNavigationForm'
import { PartnersNavigationForm } from './blocks/partnersNavigationForm'
import { PeopleListForm } from './blocks/peopleListForm'
import { ProductTilesForm } from './blocks/productTiles'
import { QuoteForm } from './blocks/quote'
import { SchemaForm } from './blocks/schemaForm'
import { SolutionTilesForm } from './blocks/solutionTilesForm'
import { TechnologiesAnimationForm } from './blocks/technologiesAnimation'
import { TechnologyTilesForm } from './blocks/technologyTilesForm'
import { TestimonialsForm } from './blocks/testimonialsForm'
import { TimelineForm } from './blocks/timelineForm'
import { WebinarTeaserForm } from './blocks/webinarTeaserForm'
import { InputField } from '@app/lib/form'
import { CustomizedTilesForm } from './blocks/customizedTilesForm'
import { BlogTeaserExternalForm } from './blocks/blogTeaserExternalForm'
import { DynamicNumbersForm } from './blocks/dynamicNumbers'

const getTranslation = (key: string) => 'See more' // TODO: get translation

const NextLink = ({ children, className }: PropsWithChildren<{ href: string; className?: string }>) => <span className={className}>{children}</span>
const Link = ({ children, className }: PropsWithChildren<{ link?: LinkFragmentType | null; className?: string }>) => (
	<span className={className}>{children}</span>
)
const sortItems = (a: any, b: any) => a.displayOrder - b.displayOrder

const renderCaseStudy = (caseStudy: CaseStudyFragmentType | null) => ({
	link: caseStudy?.localesByLocale?.link?.url ?? '#',
	image: caseStudy?.logo?.url && <img src={caseStudy.logo.url} alt={caseStudy.logo.alt ?? 'case study'} />,
	tags: caseStudy?.tags?.map(tag => tag.localesByLocale?.name ?? '') ?? [],
	title: caseStudy?.localesByLocale?.title && (
		<RichTextRenderer value={caseStudy.localesByLocale.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
	),
	teaser: caseStudy?.localesByLocale?.leadText && (
		<div>
			<RichTextRenderer
				value={caseStudy.localesByLocale.leadText}
				getTranslation={getTranslation}
				nextLinkComponent={NextLink}
				linkComponent={Link}
			/>
		</div>
	),
	revenue: caseStudy?.localesByLocale?.revenue ?? '',
	products: caseStudy?.products?.map(product => product.product?.localesByLocale?.name ?? '') ?? [],
	solutions: caseStudy?.solutions?.map(solution => solution.solution?.localesByLocale?.name ?? '') ?? [],
})

interface VisualBlockWrapperProps {
	label?: ReactNode
	className?: string
	debuggerJson?: Record<string, unknown>
}

const VisualBlockWrapper = ({ children, label, className, debuggerJson }: PropsWithChildren<VisualBlockWrapperProps>) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const shadowRootRef = useRef<ShadowRoot | null>(null)
	const wrapperRef = useRef<HTMLDivElement | null>(null)
	const [shadowRootCreated, setShadowRootCreated] = useState(false)

	useEffect(() => {
		if (containerRef.current && !shadowRootRef.current) {
			shadowRootRef.current = containerRef.current.attachShadow({ mode: 'open' })

			const linkElem = document.createElement('link')
			linkElem.rel = 'stylesheet'
			linkElem.href = '/website.css'
			shadowRootRef.current.appendChild(linkElem)

			const wrapper = document.createElement('div')
			wrapper.classList.add('shadow-dom-wrapper')

			shadowRootRef.current.appendChild(wrapper)

			wrapperRef.current = wrapper

			setShadowRootCreated(true)
		}
	}, [])

	return (
		<div className="relative overflow-hidden group my-4">
			<p className="text-gray-900 text-xs border border-gray-900 bg-white px-2 py-1 rounded-full font-bold mb-4 absolute top-0 left-0 transition-all opacity-0 invisible group-hover:visible group-hover:opacity-100">
				{label}
			</p>

			<div className={cn('relative overflow-hidden', className)} ref={containerRef}>
				{shadowRootCreated && wrapperRef.current && createPortal(children, wrapperRef.current)}
			</div>

			<pre>{JSON.stringify(debuggerJson, null, 2)}</pre>
		</div>
	)
}

type articleFormProps = {
	type: signUpArticleType | null
	file: string | null | undefined
	listId: string | undefined
	articleId: string | undefined
}
interface BlockEditorProps {
	articleSignUpForm?: ({ type, file, listId, articleId }: articleFormProps) => ReactNode
}

export const BlockEditor = Component<BlockEditorProps>(({ articleSignUpForm }) => (
	<BlockRepeater field="blocks">
		<Block name="content" label="Content">
			<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
		</Block>

		<Block name="navigationProductTiles" label="Navigation tiles -with gradient" form={<NavigationProductTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType

					const navigationTiles: NavigationTilesProps = {
						items: block.pages.sort(sortItems).map((page, index) => ({
							buttonLabel: getTranslation('button.seeMore'),
							title: page.page?.hero?.localesByLocale?.title ? (
								<RichTextRenderer
									value={page.page.hero.localesByLocale.title}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							) : null,
							teaser: page.page?.hero?.localesByLocale?.description ? (
								<RichTextRenderer
									value={page.page.hero.localesByLocale.description}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							) : null,
							link: page.page?.localesByLocale?.link?.url ?? '#',
							index: index % 2 === 0 ? 1 : 2,
						})),
						linkComponent: NextLink,
					}

					return (
						<VisualBlockWrapper label="Navigation titles - width gradient">
							{block.pages.length ? (
								<NavigationTiles {...navigationTiles} />
							) : (
								<NavigationTiles
									linkComponent={NextLink}
									items={[
										{
											title: 'Title',
											teaser: 'Teaser',
											buttonLabel: getTranslation('button.seeMore'),
											link: '#',
											index: 1,
										},
										{
											title: 'Title',
											teaser: 'Teaser',
											buttonLabel: getTranslation('button.seeMore'),
											link: '#',
											index: 2,
										},
									]}
								/>
							)}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="pagesNavigation" label="Pages navigation - with numbers" form={<PagesNavigationForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const pagesNavigation: PagesNavigationProps = {
						pages: block.pages.map(({ page }) => ({
							title: page?.localesByLocale?.title ?? '',
							teaser: page?.hero?.localesByLocale?.description && (
								<RichTextRenderer
									value={page.hero.localesByLocale.description}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							getTranslation: getTranslation,
							link: page?.localesByLocale?.link?.url ?? '#',
						})),
						linkComponent: ({ href, children }) => (
							<NextLink href={href} className="group hover:no-underline h-full">
								{children}
							</NextLink>
						),
					}
					return (
						<VisualBlockWrapper label="Pages navigation - with numbers">
							<PagesNavigation key={block.id} {...pagesNavigation} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="companyInNumbers" label="Company in numbers" form={<CompanyInNumbersForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const companyInNumbers: CompanyInNumbersProps = {
						headline: (
							<>
								{block.headline?.logo?.url ? <img src={block.headline.logo.url} alt={block.headline.logo.alt ?? 'logo'} /> : null}
								{block.headline?.text && (
									<Headline level={block.headline?.size ?? 'h2'}>
										<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
									</Headline>
								)}
							</>
						),
						gradientBg: block.bgType === 'gradient',
						separatorPosition: block.bgType === 'gradient' ? 'top' : 'bottom',
						teaser: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						items: block.items.sort(sortItems).map(item => ({
							icon: <img src={item.image?.url} alt={item.image?.alt ?? 'icon'} />,
							title: <RichTextRenderer value={item.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							teaser: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
						cta: block.primaryButton?.link && (
							<div className={'relative z-10 mt-40 md:mt-64'}>
								<Link link={block.primaryButton.link} className="btn btn--primary mx-auto">
									<RichTextRenderer
										value={block.primaryButton.text}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</Link>
							</div>
						),
					}

					return (
						<>
							<VisualBlockWrapper label="Company in numbers" className="-mt-150">
								<CompanyInNumbers {...companyInNumbers} />
							</VisualBlockWrapper>
						</>
					)
				}}
			/>
		</Block>

		<Block name="networkAnimation" label="Global network map" form={<NetworkAnimationForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const globalNetwork: GlobalNetworkProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						leadText: (
							<RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						items: block.items.sort(sortItems).map(item => ({
							title: item.title ?? '',
							text: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
					}
					return (
						<VisualBlockWrapper label="Global network map">
							<GlobalNetwork key={block.id} {...globalNetwork} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="dynamicNumbers" label="Dynamic numbers" form={<DynamicNumbersForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const dynamicNumbers: DynamicNumbersProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						leadText: (
							<RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						items: block.items.sort(sortItems).map(item => ({
							title: item.title ?? '',
							text: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
					}
					return (
						<VisualBlockWrapper label="Dynamic numbers">
							<DynamicNumbers key={block.id} {...dynamicNumbers} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="caseStudiesAndLogos" label="Case studies with logos" form={<CaseStudiesAndLogosForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const testimonialsWithCaseStudies: TestimonialsWithCaseStudiesProps = {
						footer: {
							title: (
								<RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							cta: block.primaryButton?.link && (
								<div className={'relative z-10 mt-40 md:mt-64'}>
									<Link link={block.primaryButton.link} className="btn btn--primary-outline mx-auto">
										<RichTextRenderer
											value={block.primaryButton.text}
											getTranslation={getTranslation}
											nextLinkComponent={NextLink}
											linkComponent={Link}
										/>
									</Link>
								</div>
							),
						},
						logoBar: {
							logos: block.items.map((item, index) => item.image?.url && <img key={index} src={item.image.url} alt={item.image.alt ?? 'logo'} />),
						},
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						usedProducts: getTranslation('caseStudies.usedProducts.text'),
						usedSolutions: getTranslation('caseStudies.usedSolutions.text'),
						caseStudies: block.caseStudies.map(({ caseStudy }) => renderCaseStudy(caseStudy)),
						linkComponent: ({ href, children }) => (
							<NextLink href={href} className="group hover:no-underline h-full">
								{children}
							</NextLink>
						),
					}

					return (
						<VisualBlockWrapper label="Case studies with logos">
							<TestimonialsWithCaseStudies key={block.id} {...testimonialsWithCaseStudies} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="caseStudies" label="All case studies list" form={<CaseStudiesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const caseStudies: CaseStudiesProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						usedSolutions: getTranslation('caseStudies.usedSolutions.text'),
						usedProducts: getTranslation('caseStudies.usedProducts.text'),
						loadMore: getTranslation('pagination.loadMore'),
						caseStudies: block.caseStudies.map(({ caseStudy }) => ({
							image: caseStudy?.logo?.url && <img src={caseStudy.logo.url} alt={caseStudy.logo.alt ?? 'case study'} />,
							tags: caseStudy?.tags?.map(tag => tag.localesByLocale?.name ?? '') ?? [],
							title: caseStudy?.localesByLocale?.title && (
								<RichTextRenderer
									value={caseStudy.localesByLocale.title}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: caseStudy?.localesByLocale?.leadText && (
								<div>
									<RichTextRenderer
										value={caseStudy.localesByLocale.leadText}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</div>
							),
							revenue: caseStudy?.localesByLocale?.revenue ?? '',
							products: caseStudy?.products?.map(product => product.product?.localesByLocale?.name ?? '') ?? [],
							link: caseStudy?.localesByLocale?.link?.url ?? '#',
						})),
						linkComponent: NextLink,
					}
					return (
						<VisualBlockWrapper label="All case studies list">
							<CaseStudies key={block.id} {...caseStudies} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>
		<Block name="blogArticles" label="All blog articles list" form={<BlogArticlesForm />}>
			<EntityView
				render={() => {
					return (
						<VisualBlockWrapper label="All blog articles list">
							<div>All blog articles will be shown paginated</div>
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="technologiesAnimation" label="Technology animation" form={<TechnologiesAnimationForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const supportedTechnologies: SupportedTechnologiesProps = {
						caption: {
							totalCount: block.primaryText,
							subtitle: (
								<RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							teaser: (
								<RichTextRenderer value={block.tertiaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							cta: block.primaryButton?.link && (
								<Link link={block.primaryButton.link} className="btn btn--primary">
									<RichTextRenderer
										value={block.primaryButton?.text}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</Link>
							),
						},
						technologyLogos: block.items
							.sort(sortItems)
							.map((item, index) => item.image?.url && <img key={index} src={item.image.url} alt={item.image.alt ?? 'logo'} />),
					}
					return (
						<VisualBlockWrapper label="Technology animation">
							<SupportedTechnologies key={block.id} {...supportedTechnologies} animate={false} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="blogTeaser" label="Blog teaser" form={<BlogTeaserForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const blogTeaser: BlogTeaserProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						articles: block.articles.map(({ article }) => ({
							authorLabel: getTranslation('author.label'),
							link: article?.localesByLocale?.link?.url ?? '#',
							linkComponent: NextLink,
							image: article?.image?.url && <img src={article.image.url} alt={article.image.alt ?? 'article'} />,
							tags: article?.tags?.map(tag => tag.localesByLocale?.name ?? '') ?? [],
							title: article?.localesByLocale?.title && (
								<RichTextRenderer
									value={article.localesByLocale.title}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: article?.localesByLocale?.leadText && (
								<div>
									<RichTextRenderer
										value={article.localesByLocale.leadText}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</div>
							),
							author: article?.localesByLocale?.author?.name ?? article?.author?.name ?? '',
							pubDate: article?.localesByLocale?.publishedAt ?? '',
						})),
						linkComponent: NextLink,
					}

					return (
						<VisualBlockWrapper label="Blog teaser">
							<BlogTeaser key={block.id} {...blogTeaser} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="blogTeaserExternal" label="Blog teaser external" form={<BlogTeaserExternalForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const blogTeaserExternal: BlogTeaserProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						articles: block.items
							.sort((a, b) => a.displayOrder - b.displayOrder)
							.map(item => ({
								tags: [],
								link: item?.link?.externalTarget ?? item.link?.internalTarget?.url ?? '',
								linkComponent: NextLink,
								image: item?.image?.url && <img src={item.image.url} alt={item.image.alt ?? 'item'} />,
								title: item?.title && (
									<RichTextRenderer value={item.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
								),
								teaser: item?.text && (
									<div>
										<RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
									</div>
								),
								author: item?.topIdentifier ?? '',
								pubDate: item?.date ?? '',
							})),
						linkComponent: NextLink,
					}

					return (
						<VisualBlockWrapper label="Blog teaser external">
							<BlogTeaser key={block.id} {...blogTeaserExternal} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="contactBanner" label="Contact banner" form={<ContactBannerForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const contactUs: ContactUsProps = {
						title: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						content: (
							<RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						button: (
							<Link link={block.primaryButton?.link}>
								<div className="btn btn--primary">{block.primaryButton?.text}</div>
							</Link>
						),
					}
					return (
						<VisualBlockWrapper label="Contact banner">
							<ContactUs key={block.id} {...contactUs} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="productTiles" label="Product tiles" form={<ProductTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const discoverMoreProducts: DiscoverMoreProductsProps = {
						mode: block.variant ?? 'dark',
						variant: block.itemsSize ?? 'large',
						seeMoreButtonLabel: getTranslation('button.seeMore'),
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						linkComponent: ({ href, children }) => (
							<NextLink href={href} className="group hover:no-underline h-full">
								{children}
							</NextLink>
						),
						products: block.products.map(({ product }) => ({
							link: product?.localesByLocale?.link?.url ?? '#',
							icon:
								block.variant === 'dark' ? (
									product?.icon?.url ? (
										<img src={product.icon.url} alt={product.icon.alt ?? 'product'} />
									) : (
										product?.tileIcon?.url && <img src={product.tileIcon.url} alt={product.tileIcon.alt ?? 'product'} />
									)
								) : product?.tileIcon?.url ? (
									<img src={product.tileIcon.url} alt={product.tileIcon.alt ?? 'product'} />
								) : product?.icon?.url ? (
									<img src={product.icon.url} alt={product.icon.alt ?? 'product'} />
								) : null,
							title: product?.localesByLocale?.name && (
								<RichTextRenderer
									value={product.localesByLocale.name}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: product?.localesByLocale?.leadText && (
								<div>
									<RichTextRenderer
										value={product.localesByLocale.leadText}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</div>
							),
						})),
					}

					return (
						<VisualBlockWrapper label="Product tiles">
							{block.products.length ? (
								<DiscoverMoreProducts key={block.id} {...discoverMoreProducts} />
							) : (
								<div className="relative">
									<DiscoverMoreProducts
										seeMoreButtonLabel={getTranslation('button.seeMore')}
										linkComponent={NextLink}
										headline={{
											children: 'Product tiles',
											level: 'h2',
										}}
										products={[
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
										]}
									/>
									<div className="text-2xl w-full text-center">There will be all products</div>
								</div>
							)}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="customizedTiles" label="Customized tiles" form={<CustomizedTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const discoverMoreProducts: DiscoverMoreProductsProps = {
						mode: block.variant ?? 'dark',
						variant: block.itemsSize ?? 'large',
						seeMoreButtonLabel: getTranslation('button.seeMore'),
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						linkComponent: ({ href, children }) => (
							<NextLink href={href} className="group hover:no-underline h-full">
								{children}
							</NextLink>
						),
						products:
							block.customizedList?.items
								.sort((a, b) => a.displayOrder - b.displayOrder)
								.map(item => {
									if ('product' in item && item.product) {
										return {
											link: item.product.pageDisabled ? '' : item.product.localesByLocale?.link?.url ?? '',
											icon:
												block.variant === 'dark' ? (
													item.product.icon?.url ? (
														<img src={item.product.icon?.url} alt={item.product.icon?.alt ?? 'product'} />
													) : (
														item.product.tileIcon?.url && <img src={item.product.tileIcon?.url} alt={item.product.tileIcon?.alt ?? 'product'} />
													)
												) : item.product.tileIcon?.url ? (
													<img src={item.product.tileIcon?.url} alt={item.product.tileIcon?.alt ?? 'product'} />
												) : item.product.icon?.url ? (
													<img src={item.product.icon?.url} alt={item.product.icon?.alt ?? 'product'} />
												) : null,
											title: item.product.localesByLocale?.name,
											teaser: (
												<RichTextRenderer
													value={item.product.localesByLocale?.leadText}
													getTranslation={getTranslation}
													nextLinkComponent={NextLink}
													linkComponent={Link}
												/>
											),
										}
									}
									if ('solution' in item && item.solution) {
										return {
											link: item.solution.pageDisabled ? '' : item.solution.localesByLocale?.link?.url ?? '',
											icon:
												block.variant === 'dark' ? (
													item.solution.icon?.url ? (
														<img src={item.solution.icon?.url} alt={item.solution.icon?.alt ?? 'solution'} />
													) : (
														item.solution.tileIcon?.url && <img src={item.solution.tileIcon?.url} alt={item.solution.tileIcon?.alt ?? 'solution'} />
													)
												) : item.solution.tileIcon?.url ? (
													<img src={item.solution.tileIcon?.url} alt={item.solution.tileIcon?.alt ?? 'solution'} />
												) : item.solution.icon?.url ? (
													<img src={item.solution.icon?.url} alt={item.solution.icon?.alt ?? 'solution'} />
												) : null,
											title: item.solution.localesByLocale?.name,
											teaser: item.solution.localesByLocale?.leadText,
										}
									}
									if ('technology' in item && item.technology) {
										return {
											link: item.technology.pageDisabled ? '' : item.technology.localesByLocale?.link?.url ?? '',
											icon: item.technology.image?.url ? (
												<img src={item.technology.image?.url} alt={item.technology.image?.alt ?? 'technology'} />
											) : null,
											title: item.technology.localesByLocale?.title,
											teaser: item.technology.localesByLocale?.leadText,
										}
									}
									if ('item' in item && item.item) {
										return {
											link: item.item.link?.externalTarget ?? item.item.link?.internalTarget?.url ?? '',
											icon: item.item.image?.url ? <img src={item.item.image.url} alt={item.item.image.alt ?? 'item'} /> : null,
											title: item.item.title,
											teaser: item.item.text,
										}
									}
									return null
								})
								.filter((item): item is NonNullable<typeof item> => item !== null) ?? [],
					}

					return (
						<VisualBlockWrapper label="Customized tiles">
							{block.customizedList?.items.length && <DiscoverMoreProducts key={block.id} {...discoverMoreProducts} />}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="solutionTiles" label="Solution tiles" form={<SolutionTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const discoverMoreProducts: DiscoverMoreProductsProps = {
						seeMoreButtonLabel: getTranslation('button.seeMore'),
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						linkComponent: NextLink,
						products: block.solutions.map(({ solution }) => ({
							link: solution?.localesByLocale?.link?.url ?? '#',
							icon: solution?.icon?.url && <img src={solution.icon.url} alt={solution.icon.alt ?? 'product'} />,
							title: solution?.localesByLocale?.name && (
								<RichTextRenderer
									value={solution.localesByLocale.name}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: solution?.localesByLocale?.leadText && (
								<div>
									<RichTextRenderer
										value={solution.localesByLocale.leadText}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</div>
							),
						})),
					}
					return (
						<VisualBlockWrapper label="Solution tiles">
							{block.products.length ? (
								<DiscoverMoreProducts key={block.id} {...discoverMoreProducts} />
							) : (
								<div className="relative">
									<DiscoverMoreProducts
										seeMoreButtonLabel={getTranslation('button.seeMore')}
										linkComponent={NextLink}
										headline={{
											children: 'Product tiles',
											level: 'h2',
										}}
										products={[
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
											{
												link: '#',
												icon: <span />,
												title: 'Product title',
												teaser: 'Product teaser',
											},
										]}
									/>
									<div className="text-2xl w-full text-center">There will be all products</div>
								</div>
							)}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>
		<Block name="technologyTiles" label="Technology tiles" form={<TechnologyTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType

					const technologies: TechnologiesProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						technologies: block.technologies.map(({ technology }) => ({
							link: technology?.localesByLocale?.link?.url ?? '#',
							buttonLabel: getTranslation('button.seeMore'),
							logo: technology?.image?.url && <img src={technology?.image?.url} alt={technology?.image?.alt ?? 'technology'} />,
							title: technology?.localesByLocale?.title && (
								<RichTextRenderer
									value={technology.localesByLocale.title}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: technology?.localesByLocale?.description && (
								<RichTextRenderer
									value={technology.localesByLocale.description}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
						})),
						variant: block.itemsSize === 'large' ? 'default' : 'small',
						linkComponent: ({ href, children }) => (
							<NextLink href={href} className="group hover:no-underline h-full">
								{children}
							</NextLink>
						),
					}

					return (
						<VisualBlockWrapper label="Technology tiles">
							{block.technologies.length ? (
								<Technologies key={block.id} {...technologies} />
							) : (
								<div className="relative">
									<Technologies
										linkComponent={NextLink}
										variant={block.itemsSize === 'large' ? 'default' : 'small'}
										headline={technologies.headline}
										technologies={[
											{
												title: 'Technology title',
												teaser: 'Sunt adipisicing aute mollit fugiat Lorem in nisi mollit nostrud consequat ea cillum reprehenderit.',
												buttonLabel: getTranslation('button.seeMore'),
												link: '#',
												logo: (
													<span
														style={{
															border: '1px solid rgb(75, 134, 92)',
															width: '100%',
															height: '100%',
															display: 'block',
															aspectRatio: '4/1.8',
															backgroundColor: 'rgb(242, 247, 247)',
														}}
													/>
												),
											},
											{
												title: 'Technology title',
												teaser: 'Sunt adipisicing aute mollit fugiat Lorem in nisi mollit nostrud consequat ea cillum reprehenderit.',
												buttonLabel: getTranslation('button.seeMore'),
												link: '#',
												logo: (
													<span
														style={{
															border: '1px solid rgb(75, 134, 92)',
															width: '100%',
															height: '100%',
															display: 'block',
															aspectRatio: '4/1.8',
															backgroundColor: 'rgb(242, 247, 247)',
														}}
													/>
												),
											},
											{
												title: 'Technology title',
												teaser: 'Sunt adipisicing aute mollit fugiat Lorem in nisi mollit nostrud consequat ea cillum reprehenderit.',
												buttonLabel: getTranslation('button.seeMore'),
												link: '#',
												logo: (
													<span
														style={{
															border: '1px solid rgb(75, 134, 92)',
															width: '100%',
															height: '100%',
															display: 'block',
															aspectRatio: '4/1.8',
															backgroundColor: 'rgb(242, 247, 247)',
														}}
													/>
												),
											},
										]}
									/>
									<div className="max-w-[1440px] w-full text-center absolute top-1/2 px-16 xl:px-48 mx-auto">
										<div
											className="text-2xl"
											style={{
												transform: block.itemsSize === 'large' ? 'translateY(-35%)' : 'translateY(25%)',
												backgroundColor: 'rgba(255,255,255, 0.3)',
												backdropFilter: 'blur(8px)',
												WebkitBackdropFilter: 'blur(8px)',
												paddingTop: block.itemsSize === 'large' ? '200px' : '10px',
												paddingBottom: block.itemsSize === 'large' ? '200px' : '10px',
											}}
										>
											There will be all {block.itemsSize === 'large' ? 'primary' : 'secondary'} technologies
										</div>
									</div>
								</div>
							)}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="logos" label="Client logos" form={<LogosForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const ourClients: OurClientsProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />
							),
							level: block.headline?.size ?? 'h2',
						},
						bgColor: block.bgColor ?? undefined,
						text: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						logos: block.items
							.sort(sortItems)
							.map(
								item =>
									item.image?.url && (
										<img
											src={item.image.url}
											width={item.image.width ?? undefined}
											height={item.image.height ?? undefined}
											alt={item.image.alt ?? 'logo'}
										/>
									),
							),
						footer: {
							title: (
								<RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />
							),
							cta: block.primaryButton?.text && <div className="btn btn--primary-outline mx-auto">{block.primaryButton?.text}</div>,
						},
					}

					return (
						<VisualBlockWrapper label="Client logos">
							<OurClients {...ourClients} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="advantageTiles" label="Advantage tiles" form={<AdvantageTiles />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const advantages: ProductAdvantagesProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						advantages: block.items.sort(sortItems).map(advantage => ({
							icon: advantage.image?.url && <img src={advantage.image.url} alt={advantage.image.alt ?? 'icon'} />,
							title: advantage.title,
							teaser: <RichTextRenderer value={advantage.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							content: advantage.content && (
								<ContentRenderer content={advantage.content} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
						})),
					}

					return (
						<VisualBlockWrapper label="Advantage tiles">
							<ProductAdvantages key={block.id} {...advantages} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="dockWithImage" label="Dock with image" form={<DockWithImageForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const dockWithImage: DockWithImageProps = {
						headline: {
							children: block.primaryText,
							level: block.headline?.size ?? 'h2',
						},
						content: block.content?.data && (
							<RichTextRenderer value={block.content?.data} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						buttons: [
							<Link key={block.primaryButton?.id} link={block.primaryButton?.link}>
								<div className="btn btn--primary">{block.primaryButton?.text}</div>
							</Link>,
							<Link link={block.secondaryButton?.link} key={block.secondaryButton?.id}>
								<div className="btn btn--primary btn--primary-outline-dark">{block.secondaryButton?.text}</div>
							</Link>,
						],
						image: block.image?.url && <img src={block.image.url} alt={block.image.alt ?? 'image'} />,
					}
					return (
						<VisualBlockWrapper label="Dock with image">
							<DockWithImage key={block.id} {...dockWithImage} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="contactBar" label="Contact stripe" form={<ContactBarForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const contactUsStripe: ContactUsStripeProps = {
						title: block.primaryText,
						button: (
							<Link key={block.primaryButton?.id} link={block.primaryButton?.link}>
								<div className="btn btn--primary">{block.primaryButton?.text}</div>
							</Link>
						),
					}
					return (
						<VisualBlockWrapper label="Contact stripe">
							<ContactUsStripe key={block.id} {...contactUsStripe} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="numberedList" label="Numbered list" form={<NumberedListForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const serviceIncludes: ServiceIncludesProps = {
						title: block.headline?.text && (
							<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						leadText: block.content && (
							<ContentRenderer content={block.content} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						bgColor: block.bgColor ?? undefined,
						items: block.items.sort(sortItems).map(item => ({
							title: item.title,
							content: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
					}
					return (
						<VisualBlockWrapper label="Numbered list">
							<ServiceIncludes key={block.id} {...serviceIncludes} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="numberedListWithTiles" label="Numbered list with tiles" form={<NumberedListWithTilesForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const advantagesList: AdvantagesListProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						teaser: block.content && (
							<ContentRenderer content={block.content} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						items: block.items.sort(sortItems).map(item => ({
							headline: <RichTextRenderer value={item.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							advantages: item.subItems.sort(sortItems).map(advantage => ({
								icon: advantage.image?.url && <img src={advantage.image.url} alt={advantage.image.alt ?? 'icon'} />,
								title: advantage.title,
								teaser: <RichTextRenderer value={advantage.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
								content: advantage.content?.data && (
									<ContentRenderer content={advantage.content} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
								),
							})),
						})),
					}

					return (
						<VisualBlockWrapper label="Numbered list with tiles">
							<AdvantagesList key={block.id} {...advantagesList} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="testimonials" label="Testimonials" form={<TestimonialsForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const testimonials: TestimonialsProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						loadMore: getTranslation('pagination.loadMore'),
						testimonials: block.reviews
							.sort((a: any, b: any) => a.displayOrder - b.displayOrder)
							.map(review => ({
								avatar: review.review?.avatar?.url && <img src={review.review?.avatar?.url} alt={review.review.avatar.alt ?? 'avatar'} />,
								companyLogo: review.review?.logo?.url && <img src={review.review?.logo?.url} alt={review.review.logo.alt ?? 'logo'} />,
								name: review.review?.localesByLocale?.name ?? '',
								content: review.review?.localesByLocale?.comment?.data && (
									<ContentRenderer
										content={review.review.localesByLocale.comment}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								),
								role: review.review?.localesByLocale?.position ?? '',
							})),
					}
					return (
						<VisualBlockWrapper label="Testimonials">
							<Testimonials key={block.id} {...testimonials} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="configurations" label="Configurations" form={<ConfigurationsForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const configurations: ConfigurationOverviewProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						description: block.primaryText && (
							<RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
						),
						configurations: block.configurations.map(configuration => ({
							icon: configuration.configuration?.icon?.url && (
								<img src={configuration.configuration.icon.url} alt={configuration.configuration.icon.alt ?? 'icon'} />
							),
							title: configuration.configuration?.localesByLocale?.title && (
								<RichTextRenderer
									value={configuration.configuration.localesByLocale.title}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							teaser: configuration.configuration?.localesByLocale?.description && (
								<RichTextRenderer
									value={configuration.configuration.localesByLocale.description}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							content: configuration.configuration?.localesByLocale?.content && (
								<ContentRenderer
									content={configuration.configuration.localesByLocale.content}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							price: configuration.configuration?.localesByLocale?.price ?? '',
							cta: block.primaryButton?.link && (
								<Link link={block.primaryButton.link} className="btn btn--primary">
									<RichTextRenderer
										value={block.primaryButton?.text}
										getTranslation={getTranslation}
										nextLinkComponent={NextLink}
										linkComponent={Link}
									/>
								</Link>
							),
						})),
					}

					return (
						<VisualBlockWrapper label="Configurations">
							<ConfigurationOverview key={block.id} {...configurations} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="partnersNavigation" label="Partners navigation" form={<PartnersNavigationForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const partnersNavigation: PartnersNavigationProps = {
						items: block.partners.map(({ partner }) => ({
							title: partner?.localesByLocale?.title ?? '',
							teaser: partner?.localesByLocale?.leadText && (
								<RichTextRenderer
									value={partner.localesByLocale.leadText}
									getTranslation={getTranslation}
									nextLinkComponent={NextLink}
									linkComponent={Link}
								/>
							),
							image: partner?.logo?.url && <img src={partner.logo.url} alt={partner.logo.alt ?? 'logo'} />,
							link: partner?.localesByLocale?.link?.url ?? '#',
						})),
						linkComponent: NextLink,
					}
					return (
						<VisualBlockWrapper label="Partners navigation">
							{block.partners.length ? (
								<PartnersNavigation key={block.id} {...partnersNavigation} />
							) : (
								<PartnersNavigation
									key={block.id}
									linkComponent={NextLink}
									items={[
										{
											title: 'Partner 1',
											teaser: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget nunc.',
											image: <div className={'w-full h-full bg-black'} />,
											link: '#',
										},
										{
											title: 'Partner 2',
											teaser: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget nunc.',
											image: <div className={'w-full h-full bg-black'} />,
											link: '#',
										},
										{
											title: 'Partner 3',
											teaser: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget nunc.',
											image: <div className={'w-full h-full bg-black'} />,
											link: '#',
										},
									]}
								/>
							)}
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="quote" label="Quote" form={<QuoteForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const quote: QuoteProps = {
						title: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />,
						image: block.image?.url && <img src={block.image?.url} alt="image" />,
						authorName: (
							<RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />
						),
						authorRole: (
							<RichTextRenderer value={block.tertiaryText} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />
						),
						text: block.content?.data && (
							<RichTextRenderer value={block.content.data} getTranslation={getTranslation} linkComponent={Link} nextLinkComponent={NextLink} />
						),
						link: '#',
						icon: block.icon?.url && (
							<img
								src={block.icon.url}
								alt={block.icon.alt ?? 'icon'}
								width={block.icon.width ?? undefined}
								height={block.icon.height ?? undefined}
							/>
						),
						tags: block.tags.map(({ tag }) => tag?.localesByLocale?.name ?? ''),
					}

					return (
						<VisualBlockWrapper label="Quote">
							<Quote {...quote} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="schema" label="Schema" form={<SchemaForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const schema: SchemeProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						info: block.items.sort(sortItems).map(item => ({
							title: item.title ?? '',
							content: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
						scheme: {
							image: block.image && <img src={block.image?.url} alt={block.image?.alt ?? 'image'} />,
						},
					}
					return (
						<VisualBlockWrapper label="Schema">
							<Scheme key={block.id} {...schema} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="timeline" label="Timeline" form={<TimelineForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const timeline: TimelineProps = {
						title: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						items: block.items.sort(sortItems).map(item => ({
							year: <RichTextRenderer value={item.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							items: item.subItems.map(yearItem => ({
								title: <RichTextRenderer value={yearItem.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
								content: <RichTextRenderer value={yearItem.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							})),
						})),
					}
					return (
						<VisualBlockWrapper label="Timeline">
							<Timeline key={block.id} {...timeline} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="contactBlock" label="Contact block" form={<ContactBlockForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const contactBlock: ContactBlockProps = {
						title: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						items: block.items.sort(sortItems).map(item => ({
							title: <RichTextRenderer value={item.title} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
							text: item.content && (
								<ContentRenderer content={item.content} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							cta: item.button?.link?.id && (
								<Link link={item.button.link} className="text-cta-default flex gap-10 pt-20 tracking-[0.5px] uppercase font-semibold items-center">
									<RichTextRenderer value={item.button?.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
									<Icon name="arrowCircle" />
								</Link>
							),
						})),
					}
					return (
						<VisualBlockWrapper label="Contact block">
							<div className="bg-dark-1 pt-48">
								<ContactBlock key={block.id} {...contactBlock} />
							</div>
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="webinarTeaser" label="Webinar programme" form={<WebinarTeaserForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const webinarTeaser: WebinarInfoProps = {
						title: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						items: block.items.sort(sortItems).map(item => ({
							title: item.title ?? '',
							description: <RichTextRenderer value={item.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						})),
					}
					return (
						<VisualBlockWrapper label="Webinar programme">
							<WebinarInfo key={block.id} {...webinarTeaser} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="peopleList" label="People list" form={<PeopleListForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const companyBoard: CompanyBoardProps = {
						headline: {
							children: block.headline?.text && (
								<RichTextRenderer value={block.headline.text} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />
							),
							level: block.headline?.size ?? 'h2',
						},
						members: block.items.sort(sortItems).map(item => ({
							name: item.title ?? '',
							role: item.text ?? '',
							image: item.image?.url && <img src={item.image.url} alt={item.image.alt ?? 'image'} />,
						})),
					}
					return (
						<VisualBlockWrapper label="People list">
							<CompanyBoard key={block.id} {...companyBoard} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block name="ourTeams" label="Our teams" form={<OurTeamsForm />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const ourTeams: OurTeamsProps = {
						number: <RichTextRenderer value={block.primaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						title: <RichTextRenderer value={block.secondaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
						teams: block.items.sort(sortItems).map(item => item.title ?? ''),
						teaser: <RichTextRenderer value={block.tertiaryText} getTranslation={getTranslation} nextLinkComponent={NextLink} linkComponent={Link} />,
					}
					return (
						<VisualBlockWrapper label="Our teams">
							<OurTeams key={block.id} {...ourTeams} />
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>
		<Block name="articleSignUp" label="Gated content" form={<ArticleSignUp />}>
			<EntityView
				render={accessor => {
					const block = entityToJson(accessor) as BlockFragmentType
					const articleSignUp: ArticleSignUpBlockProps = {
						title: block.primaryText ?? '',
						type: block.signUpArticleType ?? 'webinar',
						getTranslation: getTranslation,
					}
					return (
						<VisualBlockWrapper label="Gated content">
							<ArticleSignUpBlock key={block.id} {...articleSignUp}>
								{articleSignUpForm?.({
									type: block.signUpArticleType ?? 'webinar',
									file: block.file?.url,
									listId: block.secondaryText ?? '',
									articleId: block.id,
								})}
							</ArticleSignUpBlock>
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>

		<Block
			name="careerWidget"
			label="Career widget"
			form={
				<>
					<InputField field="primaryText" label="Title" />
					<InputField field="secondaryText" label="Teaser" />
				</>
			}
		>
			<EntityView
				render={accessor => {
					return (
						<VisualBlockWrapper label="Career widget">
							<div>
								<br />
								<br />
								Here will be Teamio widget
							</div>
						</VisualBlockWrapper>
					)
				}}
			/>
		</Block>
	</BlockRepeater>
))
