import { Binding, FeedbackTrigger } from '@app/lib/binding'
import {
	AlertDialog,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogTrigger,
} from '@app/lib/ui/alert-dialog'
import { Button } from '@app/lib/ui/button'
import {
	Component,
	type EntityAccessor,
	EntityKeyProvider,
	EntitySubTree,
	PersistTrigger,
	RedirectOnPersist,
	TreeRootIdProvider,
	useCurrentRequest,
} from '@contember/interface'
import { PlusCircle } from 'lucide-react'
import type * as React from 'react'

interface CreateEntityModalButtonProps {
	entityName: string
	children: React.ReactNode
	redirectOnPersistTo?: string
	refreshOnPersist?: boolean
	buttonLabel?: string
	button?: React.ReactNode
	dialogProps?: React.ComponentProps<typeof AlertDialogContent>
	onPersistSuccess?: (getEntityAccessor: () => EntityAccessor) => void
}

export const CreateEntityModalButton = Component<CreateEntityModalButtonProps>(
	({
		entityName,
		children,
		redirectOnPersistTo,
		refreshOnPersist = true,
		buttonLabel,
		button,
		dialogProps,
		onPersistSuccess,
	}) => {
		const req = useCurrentRequest()

		return (
			<AlertDialog>
				<AlertDialogTrigger asChild>
					{button ? (
						button
					) : (
						<Button className="flex gap-1.5 px-4">
							<PlusCircle className="w-4" />
							{buttonLabel || 'Vytvořit'}
						</Button>
					)}
				</AlertDialogTrigger>
				<AlertDialogContent {...dialogProps}>
					<TreeRootIdProvider treeRootId={undefined}>
						<EntityKeyProvider entityKey={undefined as unknown as string}>
							<Binding>
								<EntitySubTree entity={entityName} isCreating onPersistSuccess={onPersistSuccess}>
									{children}
									{(redirectOnPersistTo || refreshOnPersist) && <RedirectOnPersist to={redirectOnPersistTo ?? req} />}
									<AlertDialogFooter>
										<AlertDialogCancel>Zrušit</AlertDialogCancel>
										<FeedbackTrigger>
											<PersistTrigger>
												<Button>Vytvořit</Button>
											</PersistTrigger>
										</FeedbackTrigger>
									</AlertDialogFooter>
								</EntitySubTree>
							</Binding>
						</EntityKeyProvider>
					</TreeRootIdProvider>
				</AlertDialogContent>
			</AlertDialog>
		)
	},
	() => <>{null}</>,
	'CreateEntityModalButton',
)
