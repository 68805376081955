export const tailwindMergeConfig = {
	override: {
		classGroups: {
			container: ['container'],
			borderRadius: ['rounded', 'rounded-xs', 'rounded-full'],
			borderWidth: ['border', 'border-5'],
			fontSize: [
				'text-xs',
				'text-sm',
				'text-base',
				'text-md',
				'text-lg',
				'text-h0',
				'text-h1',
				'text-h2',
				'text-h3',
				'text-h4',
				'text-p14',
			],
			lineHeight: ['leading-20', 'leading-22', 'leading-24'],
			fontFamily: ['font-sans'],
			colors: [
				'bg-transparent',
				'bg-darkBlue',
				'bg-dark-1',
				'bg-dark-2',
				'bg-dark-3',
				'bg-form-inputDarkPlaceholder',
				'bg-nadpisy',
				'bg-odstavec',
				'bg-odstavec-dark',
				'text-footerNavigationHeadline',
				'bg-copyright-bg',
				'text-copyright-text',
				'bg-outlines-light',
				'bg-outlines-dark',
				'bg-pozadiBoxu',
				'bg-cta-default',
				'bg-cta-hover',
			],
			spacing: [
				'p-0',
				'p-1',
				'p-2',
				'p-3',
				'p-4',
				'p-5',
				'p-6',
				'p-7',
				'p-8',
				'p-9',
				'p-10',
				'p-11',
				'p-12',
				'p-13',
				'p-14',
				'p-15',
				'p-16',
				'p-17',
				'p-18',
				'p-20',
				'p-21',
				'p-22',
				'p-23',
				'p-24',
				'p-25',
				'p-27',
				'p-28',
				'p-30',
				'p-32',
				'p-33',
				'p-34',
				'p-35',
				'p-36',
				'p-37',
				'p-38',
				'p-39',
				'p-40',
				'p-42',
				'p-44',
				'p-46',
				'p-48',
				'p-50',
				'p-54',
				'p-56',
				'p-60',
				'p-64',
				'p-65',
				'p-75',
				'p-80',
				'p-90',
				'p-100',
				'p-120',
				'p-128',
				'p-130',
				'p-134',
				'p-137',
				'p-150',
				'p-160',
				'p-176',
				'p-180',
				'p-196',
				'p-230',
				'p-240',
			],
		},
	},
}

export default tailwindMergeConfig
