import { InputField } from '@app/lib/form'
import { DefaultRepeater } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { RichText } from '../rich-text'
import { BlockButton } from './blockButton'
import { ImageField } from '../image-field'

export const TechnologiesAnimationForm = Component(() => (
	<>
		<h4 className="mt-8 mb-4">Technology logos</h4>
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<ImageField label="Logo" baseField="image" />
		</DefaultRepeater>
		<InputField field="primaryText" label="Total number" />
		<InputField field="secondaryText" label="Subheadline" />
		<RichText field="tertiaryText" label="Lead text" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
