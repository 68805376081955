import { Binding } from '@app/lib/binding'
import { BackButton } from '@app/lib/buttons'
import {
	DataGrid,
	DataGridDateColumn,
	DataGridDateTimeColumn,
	DataGridEnumColumn,
	DataGridLoader,
	DataGridPagination,
	DataGridQueryFilter,
	DataGridTable,
	DataGridTextColumn,
	DataGridToolbar,
} from '@app/lib/datagrid'
import { Slots } from '@app/lib/layout'

export default () => {
	return (
		<>
			<Binding>
				<div className="flex flex-col gap-12">
					<Slots.Title>Form submission list</Slots.Title>
					<Slots.Back>
						<BackButton />
					</Slots.Back>
					<>
						<DataGrid entities="FormSubmission">
							<DataGridToolbar>
								<DataGridQueryFilter />
							</DataGridToolbar>
							<DataGridLoader>
								<DataGridTable>
									<DataGridEnumColumn header="Source page" field="sourcePage" options={{ contact: 'Contact', career: 'Career' }} />
									<DataGridTextColumn header="Email" field="email" />
									<DataGridTextColumn header="Message" field="message" />
									<DataGridDateTimeColumn field="createdAt" header="Submitted at" />
								</DataGridTable>
							</DataGridLoader>
							<DataGridPagination />
						</DataGrid>
					</>
				</div>
			</Binding>
		</>
	)
}
