import { FormLayout, InputField } from '@app/lib/form'
import { Component } from '@contember/interface'
import { LocaleSideDimension } from '../locale-side-dimension'

export const AuthorForm = Component(() => (
	<FormLayout>
		<InputField field="name" label="Name" required />
		<LocaleSideDimension>
			<InputField field="bio" label="Bio" />
		</LocaleSideDimension>
	</FormLayout>
))
