import { SideDimensions } from '@app/lib/dimensions'
import { CheckboxField, FormLayout, InputField, ImageField, RadioEnumField } from '@app/lib/form'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'
import { LocaleSideDimension } from '../locale-side-dimension'

export interface HeroFormProps {
	over?: 'hero' | 'heroes' | 'heroText' | 'heroImage' | (string & {})
	withSubtitle?: boolean
}

export const HeroForm = Component<HeroFormProps>(({ withSubtitle }) => (
	<FormLayout>
		<div className="grid grid-cols-2 gap-4">
			<RadioEnumField field="heroType" label="Type" defaultValue="withIcon" options={{ withImage: 'withImage', withIcon: 'withIcon' }} />
			<ImageField
				label="Image"
				baseField="image"
				urlField="url"
				widthField="width"
				heightField="height"
				fileNameField="meta.fileName"
				fileTypeField="meta.fileType"
				fileSizeField="meta.fileSize"
				lastModifiedField="meta.lastModified"
			/>
			<div>
				<InputField field="topIdentifier" label="Top identifier" />
				<CheckboxField field="hasSolidBackground" label="Has solid background" defaultValue={false} />
			</div>
		</div>
		<LocaleSideDimension>
			<RichText field="title" label="Title (visible on page hero)" />
			{withSubtitle && <RichText field="subtitle" label="Subtitle" />}
			<InputField field="leadText" label="Quote" />
			<InputField field="description" label="Description" />

			<br />
			<InputField field="primaryLinkLabel" label="Primary link label" />
			<SelectLinkField field="primaryLink" label="Primary link" />
			<br />
			<InputField field="secondaryLinkLabel" label="Secondary link label" />
			<SelectLinkField field="secondaryLink" label="Secondary link" />
		</LocaleSideDimension>
	</FormLayout>
))
