import { InputField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'
import { BlockEditorField } from '@app/lib/plugins/rich-text/editor'
import { ImageField } from '../image-field'

export const NumberedListWithTilesForm = Component(() => (
	<>
		<BlockHeadline />
		<h4 className="mt-8 mb-4">Lead text</h4>
		<BlockEditorField field="content.data" referencesField="content.references" label="Content" />

		<DefaultRepeater field="items" sortableBy="displayOrder" title="Items">
			<RichText field="title" label="Title" />

			<DefaultRepeater field="subItems" sortableBy="displayOrder" title="Sub items">
				<ImageField label="Icon" baseField="image" />
				<InputField field="title" label="Title" />
				<RichText field="text" label="Lead text" />
				<BlockEditorField field="content.data" referencesField="content.references" label="Content" />
				<RepeaterItemActions>
					<RepeaterRemoveItemButton />
				</RepeaterItemActions>
			</DefaultRepeater>
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
	</>
))
