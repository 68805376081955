import { Inner } from '../atoms'
import { PagesNavigationItem, type PagesNavigationItemProps } from '../loop'
import { NextLinkComponent } from '../types'

export type PagesNavigationProps = {
	pages: Array<PagesNavigationItemProps>
	linkComponent: NextLinkComponent
}

export const PagesNavigation = ({ pages, linkComponent }: PagesNavigationProps) => {
	return (
		<section className="mt-160 mb-160 md:mb-240">
			<Inner>
				<ol className="grid grid-cols-1 md:grid-cols-2 gap-24 md:gap-32 pagesNavigationOl">
					{pages.map((page, index) => (
						<li key={index} className={'pagesNavigationOlItem'}>
							{linkComponent({ href: page.link, children: <PagesNavigationItem {...page} /> })}
						</li>
					))}
				</ol>
			</Inner>
		</section>
	)
}
