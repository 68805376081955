import { Menu, MenuItem } from '@app/lib/ui/menu'
import { Component } from '@contember/interface'
import {
	Database,
	Handshake,
	KeyRoundIcon,
	LockKeyholeIcon,
	MemoryStickIcon,
	MessageCircleCodeIcon,
	MonitorCogIcon,
	Newspaper,
	Package,
	PanelsTopLeftIcon,
	Ratio,
	SendIcon,
	Settings,
	UserIcon,
	UsersIcon,
} from 'lucide-react'

const Line = () => <span className={'h-full w-[1px] bg-gray-200'}>&nbsp;</span>

export const Navigation = Component(() => (
	<Menu>
		<MenuItem label="Pages" icon={<PanelsTopLeftIcon />} to="page/list" />
		<MenuItem label="Articles" icon={<Newspaper />} collapsible>
			<MenuItem icon={<Line />} label="Articles" to="article/list" />
			<MenuItem icon={<Line />} label="Tags" to="tags" />
			<MenuItem icon={<Line />} label="Categories" to="categories" />
		</MenuItem>
		<MenuItem label="Products" icon={<Package />} to="product/list" />
		<MenuItem label="Solutions" icon={<Database />} to="solution/list" />
		<MenuItem label="Technologies" icon={<MemoryStickIcon />} to="technology/list" />
		<MenuItem label="Partners" icon={<Handshake />} to="partner/list" />
		<MenuItem label="Configurations" icon={<MonitorCogIcon />} to="configuration/list" />
		<MenuItem label="Case studies" icon={<Ratio />} to="caseStudy/list" />
		<MenuItem label="Review" icon={<MessageCircleCodeIcon />} to="review/list" />
		<MenuItem label="Settings" icon={<Settings />} collapsible>
			<MenuItem icon={<Line />} label="Domain settings" to="domainSettings" />
			<MenuItem icon={<Line />} label="404 settings" to="notFoundSettings" />
			<MenuItem icon={<Line />} label="Header settings" to="headerSettings" />
			<MenuItem icon={<Line />} label="Navigation" to="navigationSettings" />
			<MenuItem icon={<Line />} label="Footer settings" to="footerSettings" />
			<MenuItem icon={<Line />} label="Redirects" to="redirect/list" />
			<MenuItem icon={<Line />} label="Social links" to="socialLinks" />
			<MenuItem icon={<Line />} label="Translations" to="translations/list" />
			<MenuItem icon={<Line />} label="Locales" to="locales" />
		</MenuItem>

		<MenuItem label="Submissions" icon={<SendIcon />} collapsible>
			<MenuItem icon={<Line />} label="Form submissions" to="formSubmissionList" />
			<MenuItem icon={<Line />} label="Newsletter submissions" to="newsletterSubmissionList" />
			<MenuItem icon={<Line />} label="Article unlocks" to="articleUnlocksList" />
			<MenuItem icon={<Line />} label="Gated content submissions" to="gatedContentSubmussionList" />
		</MenuItem>
		<MenuItem icon={<UserIcon size={16} />} label={'Tenant'} collapsible>
			<MenuItem icon={<LockKeyholeIcon />} label={'Security'} to={'tenant/security'} />
			<MenuItem icon={<UsersIcon />} label={'Members'} to={'tenant/members'} />
			<MenuItem icon={<KeyRoundIcon />} label={'API keys'} to={'tenant/apiKeys'} />
		</MenuItem>
	</Menu>
))
