import type { ReactNode } from 'react'

export type CompanyBoardMemberItemProps = {
	image: ReactNode
	name: string
	role: string
}

export const CompanyBoardMemberItem = ({ image, name, role }: CompanyBoardMemberItemProps) => {
	return (
		<article className="flex flex-col items-center">
			<div className="mb-11 w-full max-w-[160px]">
				<div className="w-full h-0 pb-[100%] rounded-full overflow-hidden relative">
					<div className="absolute inset-0">{image}</div>
				</div>
			</div>
			<p className="text-center text-odstavec-dark">
				<strong className="text-white">{name}</strong>
				<br />
				{role}
			</p>
		</article>
	)
}
