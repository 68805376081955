import { SelectEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { BlockHeadline } from './blockHeadline'
import { RichText } from '../rich-text'
import { BlockButton } from './blockButton'
import { blockBgColor } from '@app/labels'
import { ImageField } from '../image-field'

export const LogosForm = Component(() => (
	<>
		<SelectEnumField field="bgColor" label="Background color" options={blockBgColor} defaultValue="green" />
		<BlockHeadline />
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Logos">
			<ImageField label="Logo" baseField="image" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
		<RichText field="primaryText" label="More clients text" />
		<RichText field="secondaryText" label="Text above button" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
