import { InputField } from '@app/lib/form'
import { Component, HasOne } from '@contember/interface'
import { BlockButton } from './blockButton'

export const ContactBarForm = Component(() => (
	<>
		<InputField field="primaryText" label="Content" />
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
