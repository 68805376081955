import { DeleteEntityDialog } from '@app/lib/binding'
import { DefaultDropdown, DropdownMenuItem } from '@app/lib/ui/dropdown'
import { EditIcon, TrashIcon } from 'lucide-react'
import React from 'react'
import { CurrentEntityLazyModalEdit } from './modalEdit'

type UDDropdown = {
	editForm?: React.ReactNode
	editFormDialogProps?: React.ComponentProps<typeof CurrentEntityLazyModalEdit>['dialogProps']
	children?: React.ReactNode
}

export const UDDropdown = React.memo<UDDropdown>(({ editForm, editFormDialogProps, children }) => {
	return (
		<DefaultDropdown>
			{children}
			{editForm && (
				<CurrentEntityLazyModalEdit
					dialogProps={editFormDialogProps}
					button={
						<DropdownMenuItem
							className="hover:bg-accent"
							onSelect={(e) => e.preventDefault()}
							onPointerMove={(e) => e.preventDefault()}
						>
							<EditIcon className="w-4 mr-2" />
							<span>Edit</span>
						</DropdownMenuItem>
					}
				>
					{editForm}
				</CurrentEntityLazyModalEdit>
			)}
			<DeleteEntityDialog
				immediatePersist
				trigger={
					<DropdownMenuItem onSelect={(e) => e.preventDefault()}>
						<TrashIcon className="text-red-500 w-4 mr-2" />
						<span>Odstranit</span>
					</DropdownMenuItem>
				}
			/>
		</DefaultDropdown>
	)
})
