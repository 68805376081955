import type { ReactNode } from 'react'

export type PagesNavigationItemProps = {
	title: string
	teaser: ReactNode
	link: string
	getTranslation: (key: string) => string
}

export const PagesNavigationItem = ({ title, teaser, link, getTranslation }: PagesNavigationItemProps) => {
	return (
		<article className="relative flex flex-col h-full bg-dark-2 rounded p-48 overflow-hidden bg-[url('/pages_navigation_bg.webp')] bg-no-repeat bg-center bg-cover min-h-[360px]">
			<h2 className="text-h3 sm:text-h2 md:text-h3 xl:text-h2 text-white mb-8">{title}</h2>
			<div className="text-odstavec-dark max-w-[435px]">{teaser}</div>
			{link && (
				<footer className="mt-auto mb-0">
					<span className="btn btn--tercial group-hover:text-cta-hover group-hover:after:bg-cta-hover">
						{getTranslation('navigationTile.button.discover')}
					</span>
				</footer>
			)}
		</article>
	)
}
