export const Logo = () => {
	return (
		<svg width="177" height="31" viewBox="0 0 137 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.27791 18.5549L0 5.13965H4.49963L7.52629 13.6373L10.5818 5.13965H15.1102L9.83231 18.5549H5.27791Z"
				fill="#222B36"
			/>
			<path
				d="M14.71 16.8311L16.4885 13.8881C17.4887 14.7759 19.6276 15.6666 21.2101 15.6666C22.487 15.6666 23.0145 15.2515 23.0145 14.6404C23.0145 14.0293 21.9883 13.8074 20.7085 13.5854C18.4313 13.2251 15.2086 12.6976 15.2086 9.22414C15.2086 6.86335 17.2351 4.80811 20.9564 4.80811C23.2048 4.80811 25.1217 5.53162 26.5658 6.58663L24.9285 9.42015C24.1243 8.64187 22.5966 7.94718 20.9852 7.94718C19.985 7.94718 19.2903 8.36227 19.2903 8.89265C19.2903 9.47492 20.0138 9.58734 21.4291 9.83812C23.7063 10.2532 27.0962 10.8095 27.0962 14.4473C27.0962 17.0012 24.8478 18.8922 20.9852 18.8922C18.5956 18.8922 16.1253 18.0879 14.71 16.8369V16.8311Z"
				fill="#222B36"
			/>
			<path
				d="M38.0233 18.5548V10.7518C38.0233 9.11454 37.1614 8.58416 35.8009 8.58416C34.5498 8.58416 33.7168 9.27885 33.2181 9.88995V18.5548H28.9404V0.0288086H33.2181V6.77681C34.0223 5.83134 35.6077 4.80516 37.8561 4.80516C40.9116 4.80516 42.301 6.52603 42.301 8.99924V18.552H38.0233V18.5548Z"
				fill="#222B36"
			/>
			<path
				d="M44.1455 11.8328C44.1455 8.05667 46.8954 4.80518 51.4498 4.80518C56.0042 4.80518 58.783 8.05379 58.783 11.8328C58.783 15.6118 56.0619 18.8863 51.4498 18.8863C46.8378 18.8863 44.1455 15.6089 44.1455 11.8328ZM54.3929 11.8328C54.3929 10.0543 53.3379 8.58417 51.4498 8.58417C49.5618 8.58417 48.5615 10.0571 48.5615 11.8328C48.5615 13.6084 49.5906 15.1102 51.4498 15.1102C53.3091 15.1102 54.3929 13.6372 54.3929 11.8328Z"
				fill="#222B36"
			/>
			<path
				d="M59.4521 16.8311L61.2307 13.8881C62.2309 14.7759 64.3697 15.6666 65.9523 15.6666C67.2292 15.6666 67.7567 15.2515 67.7567 14.6404C67.7567 14.0293 66.7305 13.8074 65.4507 13.5854C63.1735 13.2251 59.9508 12.6976 59.9508 9.22414C59.9508 6.86335 61.9772 4.80811 65.6986 4.80811C67.947 4.80811 69.8638 5.53162 71.308 6.58663L69.6707 9.42015C68.8665 8.64187 67.3388 7.94718 65.7274 7.94718C64.7272 7.94718 64.0325 8.36227 64.0325 8.89265C64.0325 9.47492 64.756 9.58734 66.1713 9.83812C68.4485 10.2532 71.8384 10.8095 71.8384 14.4473C71.8384 17.0012 69.59 18.8922 65.7274 18.8922C63.3378 18.8922 60.8675 18.0879 59.4521 16.8369V16.8311Z"
				fill="#222B36"
			/>
			<path
				d="M74.5652 14.943V8.86083H72.3428V5.13948H74.5652V1.4729H78.8141V5.13948H81.5352V8.86083H78.8141V13.7496C78.8141 14.5279 79.258 15.1102 80.0074 15.1102C80.4802 15.1102 80.9529 14.943 81.0913 14.7758L81.9243 17.9984C81.3968 18.4971 80.3418 18.8863 78.7593 18.8863C76.0382 18.8863 74.5652 17.5257 74.5652 14.943Z"
				fill="#222B36"
			/>
			<path d="M87.8167 5.13965H83.5391V18.5549H87.8167V5.13965Z" fill="#222B36" />
			<path
				d="M99.5711 18.5548V10.8066C99.5711 9.16933 98.7093 8.58417 97.3775 8.58417C96.0458 8.58417 95.2675 9.27886 94.766 9.88996V18.5548H90.4883V5.13955H94.766V6.77683C95.5702 5.83136 97.1556 4.80518 99.4039 4.80518C102.459 4.80518 103.849 6.5837 103.849 9.05403V18.552H99.5711V18.5548Z"
				fill="#222B36"
			/>
			<path
				d="M106.271 21.9995L108.133 18.944C109.159 20.0538 110.661 20.5006 112.214 20.5006C113.63 20.5006 115.63 19.9183 115.63 17.4163V16.3872C114.52 17.693 113.186 18.3329 111.658 18.3329C108.381 18.3329 105.743 16.0846 105.743 11.5561C105.743 7.02765 108.297 4.80811 111.658 4.80811C113.157 4.80811 114.546 5.39038 115.63 6.72499V5.14248H119.879V17.3615C119.879 22.8326 115.547 24 112.214 24C109.908 24 107.994 23.4177 106.271 21.9995ZM115.63 13.3606V9.77759C115.103 9.05407 113.964 8.58422 113.076 8.58422C111.381 8.58422 110.133 9.6104 110.133 11.5561C110.133 13.5018 111.384 14.5539 113.076 14.5539C113.964 14.5539 115.103 14.0812 115.63 13.3606Z"
				fill="#222B36"
			/>
			<path d="M87.8167 0H83.5391V3.87989H87.8167V0Z" fill="#222B36" />
			<path
				d="M133.833 9.65351L133.703 9.86393C133.401 10.3597 133.081 10.746 132.755 11.0083C132.447 11.2562 132.118 11.3773 131.749 11.3773C131.38 11.3773 131.034 11.2764 130.726 11.0775C130.368 10.8469 129.996 10.6019 129.627 10.3511C129.233 10.0859 128.797 9.83511 128.333 9.60739C127.846 9.36814 127.272 9.24707 126.624 9.24707C125.831 9.24707 125.036 9.47479 124.26 9.92158C123.488 10.3684 122.715 11.1438 121.966 12.2305L121.839 12.4121L124.485 14.3405L124.615 14.1272C124.915 13.6314 125.237 13.248 125.575 12.9828C125.895 12.732 126.22 12.611 126.566 12.611C126.941 12.611 127.278 12.709 127.59 12.9108C127.941 13.1385 128.313 13.3835 128.688 13.6372C129.083 13.9052 129.518 14.1531 129.982 14.3809C130.469 14.6201 131.043 14.7412 131.691 14.7412C132.484 14.7412 133.28 14.5135 134.055 14.0667C134.828 13.6228 135.597 12.8474 136.35 11.7578L136.476 11.5762L133.83 9.64774L133.833 9.65351Z"
				fill="#4B865C"
			/>
		</svg>
	)
}
