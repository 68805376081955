import { InputField, SelectEnumField } from '@app/lib/form'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { Component, HasOne } from '@contember/interface'
import { RichText } from '../rich-text'
import { BlockHeadline } from './blockHeadline'
import { BlockButton } from './blockButton'
import { blockBgType, blockConnectAt } from '@app/labels'
import { ImageField } from '../image-field'

export const CompanyInNumbersForm = Component(() => (
	<>
		<BlockHeadline canHaveLogo={true} />
		<SelectEnumField field="bgType" label="Type of background" options={blockBgType} defaultValue="default" />
		<SelectEnumField field="blockConnectAt" label="Block connected at" options={blockConnectAt} defaultValue="none" />
		<RichText field="primaryText" label="Lead text" />
		<DefaultRepeater field="items" sortableBy="displayOrder" title="Items">
			<ImageField label="Icon" baseField="image" />
			<InputField field="title" label="Title" />
			<RichText field="text" label="Content" />
			<RepeaterItemActions>
				<RepeaterRemoveItemButton />
			</RepeaterItemActions>
		</DefaultRepeater>
		<HasOne field="primaryButton">
			<BlockButton />
		</HasOne>
	</>
))
