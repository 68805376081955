import { InputField } from '@app/lib/form'
import { DefaultRepeater } from '@app/lib/repeater'
import { Component } from '@contember/interface'
import { RichText } from '../rich-text'

export const TimelineForm = Component(() => (
	<>
		<RichText field="primaryText" label="Title" />
		<DefaultRepeater field="items" sortableBy="displayOrder">
			<RichText field="title" label="Year" />
			<DefaultRepeater field="subItems" sortableBy="displayOrder">
				<InputField field="title" label="Title" />
				<RichText field="text" label="Description" />
			</DefaultRepeater>
		</DefaultRepeater>
	</>
))
