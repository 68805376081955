import type { ReactNode } from 'react'
import { Inner } from '../atoms'
import { cn } from '../utils/cn'

export type TimelineProps = {
	title: ReactNode
	items: Array<{
		year: ReactNode
		items: Array<{
			title: ReactNode
			content: ReactNode
		}>
	}>
}

export const Timeline = ({ title, items }: TimelineProps) => {
	return (
		<section className="mt-120 mb-160 md:mt-160 md:mb-200">
			<Inner>
				<div className="flex flex-col xl:flex-row justify-between">
					<div className="w-full xl:w-[30%] flex-grow-0 flex-shrink-0 xl:pt-140 mb-40 xl:mb-0">
						<h2 className="text-h2 md:text-h1">{title}</h2>
					</div>

					<div
						className={cn(
							'h-[900px] w-full xl:w-[60%] relative',
							`before:content-[''] before:pointer-events-none before:absolute before:z-10 before:top-0 before:left-0 before:w-full before:h-[175px] sm:before:h-[320px] before:bg-gradient-to-b before:block before:bg-gradient-to-b before:from-white/100 before:to-white/0`,
							`after:content-[''] after:pointer-events-none after:absolute after:z-10 after:bottom-0 after:left-0 after:w-full after:h-[175px] sm:after:h-[320px] after:bg-gradient-to-t after:block after:bg-gradient-to-t after:from-white/100 after:to-white/0`,
						)}
					>
						<div className={cn('w-full max-h-[900px] overflow-scroll [&::-webkit-scrollbar]:hidden')}>
							{items.map((item, index) => (
								<div
									key={`timeline_${index}`}
									className="flex flex-col sm:flex-row items-start justify-between relative sm:[&:last-child>div:last-child]:pb-80 [&:first-child>h6]:pt-60 sm:[&:first-child>div]:pt-[10.5rem] sm:[&:first-child>h6]:pt-145"
								>
									<h6 className="text-h1 text-cta-default tracking-tighter min-w-[160px] text-left sm:text-right border-l-4 border-cta-default sm:border-l-0 pl-32 sm:pl-0 ">
										{item.year}
									</h6>
									<div className="w-auto sm:w-[calc(100%-186px)] flex-grow-0 flex-shrink-0 flex flex-col pb-16 pt-20 pl-32 sm:pl-44 border-l-4 border-cta-default">
										{item.items.map((yearItem, yearIndex) => (
											<div
												key={`timeline_${index}_${yearIndex}`}
												className={cn(
													'relative [&:first-child]:before:-top-60 sm:[&:first-child]:before:top-5 [&:first-child]:after:-top-[57px] sm:[&:first-child]:after:top-8 pb-40',
													`before:content-[''] before:absolute before:top-5 before:-left-43 sm:before:-left-55 before:w-22 before:h-22 before:bg-white`,
													`after:content-[''] after:absolute after:top-8 after:-left-42 sm:after:-left-54 after:w-16 after:h-16 after:bg-gradient-to-tl after:from-cta-default after:to-cta-default/20 after:rounded-full`,
												)}
											>
												<h6 className="text-h4 mb-8">{yearItem.title}</h6>
												<div className="text-odstavec">{yearItem.content}</div>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</Inner>
		</section>
	)
}
