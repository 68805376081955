import type { ReactNode } from 'react'
import { cn } from '../utils/cn'

type InnerProps = {
	children: ReactNode
	className?: string
}

export const Inner = ({ children, className }: InnerProps) => {
	return <div className={cn(['max-w-[1440px] px-16 xl:px-48 mx-auto ', className])}>{children}</div>
}
