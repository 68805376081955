import { InputField, ImageField, SortableMultiSelectField, FileField, TextareaField } from '@app/lib/form'
import { Component, EntitySubTree, HasOne } from '@contember/interface'
import { SocialLinkForm } from './social-link-form'
import { PrimaryLocale } from '../primary-locale'
import { SocialLinkPreview } from '../previews/social-link-preview'
import { LocaleSideDimension } from '../locale-side-dimension'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@app/lib/ui/accordion'
import { DefaultRepeater, RepeaterItemActions, RepeaterRemoveItemButton } from '@app/lib/repeater'
import { RichText } from '../rich-text'
import { NavigationItemForm } from './navigation-item-form'
import { SelectLinkField } from '@app/lib/plugins/link/select-link-field'
import { FileField as CustomFileField } from '../blocks/articleSignUp'
import { Textarea } from '@app/lib/ui/textarea'

export interface FooterFormProps {
	over?: 'footer' | 'footerLogo' | (string & {})
}

export const FooterForm = Component(() => (
	<>
		<Accordion type="multiple">
			<AccordionItem value="firstColumn">
				<AccordionTrigger>
					<div className="mr-2">First column</div>
				</AccordionTrigger>
				<AccordionContent className="pb-4">
					<ImageField
						label="Logo"
						baseField="logo"
						urlField="url"
						widthField="width"
						heightField="height"
						fileNameField="meta.fileName"
						fileTypeField="meta.fileType"
						fileSizeField="meta.fileSize"
						lastModifiedField="meta.lastModified"
					/>
					<LocaleSideDimension>
						<InputField field="description" label="Description" />
					</LocaleSideDimension>
					<InputField field="ratingValue" label="Customer satisfaction rating" />
					{/* <ImageField
							label="Rating image"
							baseField="image"
							urlField="url"
							widthField="width"
							heightField="height"
							fileNameField="meta.fileName"
							fileTypeField="meta.fileType"
							fileSizeField="meta.fileSize"
							lastModifiedField="meta.lastModified"
						/> */}
					<FileField
						baseField="mediaKit"
						label="Media kit"
						urlField="url"
						fileNameField="meta.fileName"
						fileTypeField="meta.fileType"
						fileSizeField="meta.fileSize"
						lastModifiedField="meta.lastModified"
					/>
					<LocaleSideDimension>
						<SortableMultiSelectField
							field="socialLinks"
							label="Social links"
							createNewForm={<SocialLinkForm />}
							connectAt="socialLink"
							sortableBy="displayOrder"
						>
							<PrimaryLocale>
								<SocialLinkPreview />
							</PrimaryLocale>
						</SortableMultiSelectField>
					</LocaleSideDimension>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="secondColumn">
				<AccordionTrigger>
					<div className="mr-2">Second column</div>
				</AccordionTrigger>
				<AccordionContent className="pb-4">
					<HasOne field="secondColumn">
						<LocaleSideDimension>
							<InputField field="title" label="Title" />
							<DefaultRepeater field="items" title="Items" sortableBy="displayOrder">
								<RepeaterItemActions>
									<RepeaterRemoveItemButton />
								</RepeaterItemActions>
								<InputField field="title" label="Title" />
								<RichText field="text" label="Text" />
							</DefaultRepeater>
						</LocaleSideDimension>
					</HasOne>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="thirdColumn">
				<AccordionTrigger>
					<div className="mr-2">Third column</div>
				</AccordionTrigger>
				<AccordionContent className="pb-4">
					<HasOne field="thirdColumn">
						<LocaleSideDimension>
							<InputField field="title" label="Title" />
							<DefaultRepeater field="items" title="Items" sortableBy="displayOrder">
								<RepeaterItemActions>
									<RepeaterRemoveItemButton />
								</RepeaterItemActions>
								<RichText field="text" label="Text" />
							</DefaultRepeater>
						</LocaleSideDimension>
					</HasOne>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="extraFooter">
				<AccordionTrigger>
					<div className="mr-2">Extra footer Navigation</div>
				</AccordionTrigger>
				<AccordionContent className="pb-4">
					<EntitySubTree entity={'Navigation(unique = FooterBottom)'} setOnCreate="(unique = FooterBottom)">
						<DefaultRepeater field="items" title="Items" sortableBy="order">
							<RepeaterItemActions>
								<RepeaterRemoveItemButton />
							</RepeaterItemActions>
							<NavigationItemForm over="items" enableChildren={false} />
						</DefaultRepeater>
					</EntitySubTree>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="copyright">
				<AccordionTrigger>Copyright</AccordionTrigger>
				<AccordionContent className="pb-4">
					<LocaleSideDimension>
						<div className="mb-8">
							<TextareaField field="disclaimer" label="Disclaimer" />
						</div>
						<InputField field="documentTitle" label="Document title" />
						<SelectLinkField field="documentLink" label="Document link" />
						<div className="my-8 p-4 border rounded-md border-gray-200">
							<CustomFileField baseField="privacyFile" label="Privacy file" />
							<InputField field="privacyFile.title" label="Privacy file title" />
							<span className="py-4">or</span>
							<SelectLinkField field="privacyLink" label="Privacy link" />
						</div>
						<InputField field="copyright" label="Copyright" required />
					</LocaleSideDimension>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	</>
))
